/*  ==========================================================================
    Tile
    ========================================================================== */

.tile {
    $block: &;

    @include box-shadow(1);

    /*  Sizes
        ========================================================================== */

    @include panel--size(md, $tile-padding);
    @include panel--size(xs, $tile-padding-v-xs, $tile-padding-h-xs);
    @include panel--size(sm, $tile-padding-small);
    @include panel--size(lg, $tile-padding-large);

    display: flex;
    flex-direction: column;
    border-radius: $tile-border-radius;
    background: $tile-bg;

    &-header {
        flex: 0 0 auto;
        text-align: left; // override client styling
    }

    &-body {
        flex: 1 0 auto;
    }

    &-footer {
        //display: flex; //I think there should be flex on this element
        flex: 0 0 auto;
        border-top: $border-width-light solid $color-border;

        &.is-borderless {
            padding-top: 0;
            border-top-width: 0;
        }
    }

    &-action {
        & + & {
            margin-left: $tile-action-margin;
        }
    }

    /*  Unstructured
        ========================================================================== */

    &-unstructured {
        display: block;
        padding: $tile-unstructured-padding;

        &#{$block}-sm {
            padding: $tile-unstructured-padding-small;
        }
    }

    /*  Style
        ========================================================================== */

    &-transparent {
        background: transparent;
        box-shadow: none;
    }

    /*  Collapsible
        ========================================================================== */

    &-collapsible {
        @include box-shadow(2);

        #{$block} {
            &-collapsible-icon {
                align-self: flex-start;
                transform: none;
                transform-origin: center;
                transition: transform $animate-duration-short linear;
            }

            &-header {
                @extend %touch-target;

                border-radius: $border-radius;

                &:hover {
                    @include box-shadow(6);
                }

                &:active {
                    @include box-shadow(4);
                }
            }

            &-body,
            &-footer {
                display: none;
            }
        }

        /* expanded */
        &.is-expanded {
            #{$block} {
                &-header {
                    @include box-shadow(0);

                    transition: none;
                }

                &-collapsible-icon {
                    transform: rotate(90deg);
                }

                &-body,
                &-footer {
                    display: block;
                }
            }
        }
    }
}
