/*  ==========================================================================
    Pagination
    ========================================================================== */

.pagination {
    $block: &;

    display: flex;
    flex-wrap: wrap;

    /*  Pagination control
        a list of navigation links for pagination
    ========================================================================== */

    &__list {
        display: flex;
    }

    &__item {
        @include media-breakpoint-down(sm) {
            display: none;

            // Only show previous, next and active link on small screens
            &.is-active,
            &#{$block}__item--previous,
            &#{$block}__item--next {
                display: block;
            }
        }
    }

    &__link {
        transition: none;

        &.is-active {
            /* Override of active `is-menu` button style */
            background-color: $color-blue;
            color: $color-white;
        }
    }

    /*  Pagination aside
    ========================================================================== */

    &__aside {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    /*  Info
        Information on items shown out of total
        ========================================================================== */

    &__info {
        white-space: nowrap;
        color: $color-text-soft;

        strong {
            font-weight: $font-weight-regular;
        }
    }

    /*  Pagination length
        Select the length in items per page
        ========================================================================== */

    &__length {
        margin-bottom: 0;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
    }

    &__length-control {
        width: auto;
    }

    /*  Size
        ========================================================================== */

    &--size-sm {
        gap: $pagination--size-sm_spacing;
        font-size: $font-size-sm;

        #{$block} {
            &__aside {
                gap: $pagination--size-sm_spacing;
            }

            &__list {
                gap: $pagination--size-sm__list_spacing;
            }
        }
    }

    &--size-md {
        gap: $pagination--size-md_spacing;

        #{$block} {
            &__aside {
                gap: $pagination--size-md_spacing;
            }

            &__list {
                gap: $pagination--size-md__list_spacing;
            }
        }
    }

    /*  Theme
        ========================================================================== */

    @include theme('dark-blue') {
        #{$block} {
            &__info {
                color: $color-text-soft-on-dark;
            }
        }
    }
}
