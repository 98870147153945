/*  ==========================================================================
    Form group validation
    ========================================================================== */

.form-control-validation {
    margin: $validation-group-margin-top 0 $validation-group-margin-bottom 0;

    &__item {
        display: flex;
        color: $color-danger;

        & + & {
            margin-top: $validation-group-item-margin-v;
        }
    }

    &__item-icon {
        flex: 0 0 auto;
        margin-right: $validation-group-item-icon-margin-h;
        font-size: $icon-font-size-base;
        line-height: $line-height-computed;
    }

    &__item-message {
        flex: 1 1 auto;
    }
}
