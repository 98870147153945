/*  ==========================================================================
    Content actions
    ========================================================================== */

/*  Sidebar actions

    NB. sass cannot compile @extend in media queries > when supported
        move styling to apply only when element is visible

    TODO: remove this code when appshell refactor has been done
    ========================================================================== */

#content-actions-sidebar {
    position: sticky;
    top: $content-actions-sidebar-offset-top + $header-h;
    display: none;
    min-width: $content-actions-sidebar-width;
    margin: $content-actions-sidebar-margin;
    transition: top $layout-animation-vertical-speed-out ease-in;

    .has-notification-bar & {
        top: $content-actions-sidebar-offset-top + $header-h + $notification-bar-height;
        transition: top $layout-animation-vertical-speed-in ease-out;
    }

    .header-hidden & {
        @include media-breakpoint-down(sm) {
            top: $content-actions-sidebar-offset-top;
            transition: top $layout-animation-vertical-speed-in ease-out;
        }
    }

    .has-notification-bar.header-hidden & {
        @include media-breakpoint-down(sm) {
            top: $content-actions-sidebar-offset-top + $notification-bar-height;
            transition: top $layout-animation-vertical-speed-in ease-out;
        }
    }

    .content-action-menu {
        display: flex;
        flex-flow: column wrap;
    }

    .content-action-item {
        + .content-action-item {
            margin-top: $content-actions-sidebar-item-spacing;
        }
    }

    .content-action {
        &-default {
            .content-action-trigger {
                &:hover {
                    background: $content-actions-sidebar-item-default-hover-bg;
                    color: $content-actions-sidebar-item-default-hover-color;
                }

                &:active {
                    background: $content-actions-sidebar-item-default-active-bg;
                    color: $content-actions-sidebar-item-default-active-color;
                }

                &.disabled {
                    color: color-disabled();
                }
            }
        }

        &-primary {
            .content-action-trigger {
                @extend .btn-primary;
            }
        }
    }

    .content-action-trigger {
        @extend .btn;
        @extend .btn-align-left;

        width: 100%;
        text-align: left;
    }

    .content-action-icon {
        @extend .btn-icon;
        @extend .btn-icon-left;
    }

    @include media-breakpoint-up(md) {
        display: block;
    }
}

/*  Floating actions
    ========================================================================== */

#content-actions-floating {
    position: fixed;
    z-index: $z-index-above;
    top: auto;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    margin: $content-actions-floating-margin;

    .content-action-primary {
        @include box-shadow(3);

        // todo fix focus
        @include focus-visible {
            //box-shadow: 0 2px 4px 0 $color-shadow, inset 0 0 0 1px $color-white;
        }
    }

    .action-menu-toggle {
        @include box-shadow(3);

        margin-left: $content-actions-floating-menu-toggle-offset;
        border-radius: $border-radius;

        // todo fix focus
        @include focus-visible {
            //box-shadow: 0 2px 4px 0 $color-shadow, inset 0 0 0 1px $color-white;
        }
    }

    .action-menu {
        @include box-shadow(4, null, null, 2);

        margin-bottom: $content-actions-floating-menu-offset;
    }

    /* Account for fixed primary navigation */
    @include media-breakpoint-down(sm) {
        .header-hidden & {
            top: auto; // Overwrite more specific css when header is hidden
        }

        /* Push actions up in case there is a primary navigation */
        .has-primary-nav & {
            transform: translateY(-$navigation-primary-fixed-height);
            transition:
                transform $layout-animation-vertical-speed-out ease-in,
                top $layout-animation-vertical-speed-out ease-in,
                box-shadow $layout-animation-vertical-speed-out ease-in;
        }

        /* Bottom fixed primary navigation is currently using .header-hidden class to toggle it's visibility on scroll */
        .has-primary-nav.header-hidden & {
            transform: none;
            transition:
                transform $layout-animation-vertical-speed-in ease-out,
                top $layout-animation-vertical-speed-in ease-out,
                box-shadow $layout-animation-vertical-speed-in ease-out;
        }
    }

    @include media-breakpoint-only(sm) {
        margin-right: $content-actions-floating-offset-horizontal-sm;
        margin-left: $content-actions-floating-offset-horizontal-sm;
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}
