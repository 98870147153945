/*  ==========================================================================
    Font
    ========================================================================== */

/*  Custom font loading
    ========================================================================== */

@mixin fontdef($font-path, $font-name, $font-family, $font-type, $font-style, $font-weight, $font-version) {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src:
        url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-type}.woff2?v=#{$font-version}')
            format('woff2'),
        url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-type}.woff?v=#{$font-version}') format('woff'),
        url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-type}.ttf?v=#{$font-version}')
            format('truetype');
}

@mixin fontdef-icon($font-path, $font-name, $font-type, $font-version) {
    src:
        url('#{$font-path}/#{$font-name}/#{$font-name}.woff2?v=#{$font-version}') format('woff2'),
        url('#{$font-path}/#{$font-name}/#{$font-name}.ttf?v=#{$font-version}') format('truetype');
}
