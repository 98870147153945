/*  ==========================================================================
    Content view
    ========================================================================== */

$content-view-border-w: 1px;
$content-view-h: grid-unit(6) + $content-view-border-w;
$content-view-padding: grid-unit();
$content-view-margin-h: grid-unit(-1);

$content-view-bg: $main-content-inner-bg;
$content-view-border-color: $color-blue-300;
$content-view-border-color-active: $color-selected;

$view-h: grid-unit(4);
$view-border-w: 2px;

/* Affected-styling */
$content-view-bottom-offset: grid-unit(2);
$content-view-bottom-offset-lg: grid-unit(3);

/* Filter */
$content-view-filter-margin-top: -$content-view-padding;
$content-view-filter-margin: $content-view-filter-margin-top $content-view-margin-h (-$content-view-border-w)
    $content-view-margin-h;

/* Results */
$content-view-results-padding: grid-unit(3);
$content-view-results-color: $color-selected;
$content-view-results-font-size: $font-size-xl;
$content-view-results-line-height: $line-height-xl;

/* Switcher */
$content-view-switcher-margin: 0 0 (-$content-view-border-w) 0;
$content-view-switcher-display-control-font-size: $icon-font-size-lg;
$content-view-switcher-display-control-color: $color-text-soft;
$content-view-switcher-display-control-hover-color: $color-hover-text;
$content-view-switcher-display-control-pressed-color: $color-pressed-text;
$content-view-switcher-display-control-active-color: $color-selected;
$content-view-switcher-sort-label-margin: grid-unit();
