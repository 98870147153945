/*  ==========================================================================
    Notification
    ========================================================================== */

.notification {
    position: relative;
    display: flex;
    padding: $notification-padding;
    border: $notification-border-width solid transparent;
    border-radius: $notification-border-radius;
    opacity: 1;
    transition: opacity $animate-duration-medium linear;

    .notification-emphasis {
        + .notification-text {
            &::before {
                content: ' ';
            }
        }
    }

    .notification-emphasis-block {
        display: block;
    }

    .status-icon {
        margin-right: $notification-status-icon-margin;
        font-size: $notification-status-icon-font-size;
        line-height: $notification-status-icon-line-height;
    }

    + .notification {
        margin-top: $notification-margin;
    }

    /*  Content
        ========================================================================== */

    /* Link styling */
    a,
    .notification-link {
        text-decoration: $notification-link-text-decoration;
    }

    /* Improve alignment and spacing of inner content */
    hr {
        @extend .divider;

        @include divider-size($notification-divider-margin);
    }

    p {
        margin: 0;

        + p {
            margin-top: $notification-paragraph-margin;
        }
    }

    /*  Visibility
    ========================================================================== */

    &.notification-hiding {
        opacity: 0;
        transition: opacity $animate-duration linear;
    }
}

/*  Size
    ========================================================================== */

.notification-large {
    padding: $notification-large-padding;
}

/*  Style
    ========================================================================== */

.notification-info {
    @include notification-style(
        $notification-info-bg,
        $notification-info-border,
        $notification-info-text,
        $notification-info-border,
        $notification-info-close-hover-bg,
        $notification-info-close-pressed-bg
    );
}

.notification-success {
    @include notification-style(
        $notification-success-bg,
        $notification-success-border,
        $notification-success-text,
        $notification-success-border,
        $notification-success-close-hover-bg,
        $notification-success-close-pressed-bg
    );
}

.notification-warning {
    @include notification-style(
        $notification-warning-bg,
        $notification-warning-border,
        $notification-warning-text,
        $notification-warning-border,
        $notification-warning-close-hover-bg,
        $notification-warning-close-pressed-bg
    );
}

.notification-danger {
    @include notification-style(
        $notification-danger-bg,
        $notification-danger-border,
        $notification-danger-text,
        $notification-danger-border,
        $notification-danger-close-hover-bg,
        $notification-danger-close-pressed-bg
    );
}

/*  Dismissible
    ========================================================================== */

.notification-dismissible {
    padding-right: $notification-close-width !important;

    .notification-close {
        position: absolute;
        top: -$notification-border-width;
        right: -$notification-border-width;
        width: $notification-close-width;
        height: $notification-close-height;
        padding: $notification-close-padding;
        font-size: $notification-close-font-size;
        line-height: $notification-close-line-height;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        @include icon(extend) {
            &::before {
                display: inline-block;
                width: 100%;
                border-radius: $border-radius-circle;
            }
        }
    }

    &.notification-large {
        .notification-close {
            margin-top: $notification-large-close-offset;
        }
    }
}

/*  Framework specific
    ========================================================================== */

/* Angular sibling support */
priva-notification,
priva-notification-legacy {
    & + & {
        .notification {
            margin-top: $notification-margin;
        }
    }
}
