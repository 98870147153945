/*  ==========================================================================
    No scrollbar

    Hide scrollbar
        > set styling to compensate for layout changes according to measured
          scrollbar width
    ========================================================================== */

@mixin no-scrollbar() {
    overflow: hidden;

    .navigation-primary--bottom,
    #content-header,
    #main-content-inner,
    #notification-bar > .notification,
    appshell-header {
        padding-right: var(--scrollbar-width);
    }

    #sidebar-local-right .sidebar-local {
        margin-right: var(--scrollbar-width);
    }

    #content-notifications {
        margin-right: calc(var(--scrollbar-width) + #{$content-notifications-margin-h});
    }

    #content-view-filter,
    #content-view-switcher {
        margin-right: calc(var(--scrollbar-width) + #{$content-view-margin-h});
    }

    #footer-global {
        padding-right: calc(var(--scrollbar-width) + #{$footer-padding-h});
    }

    /* Add custom styling to mixin */
    @content;
}
