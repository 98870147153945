/*  ==========================================================================
    Image mixins
    ========================================================================== */

/* Responsive image > keep images from scaling beyond the width of their parents. */
@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%;
    height: auto;
}
