/*  ==========================================================================
    Controls Options Legacy
    ========================================================================== */

//TODO: Remove once all apps use Angular component
.form-options {
    margin-top: -$radio-checkbox-spacing;

    &.is-inline {
        display: flex;
        flex-wrap: wrap;

        .radio,
        .checkbox {
            margin-right: $radio-checkbox-inline-spacing;
        }
    }
}
