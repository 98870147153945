/*  ==========================================================================
    File
    ========================================================================== */

.file {
    $block: &;

    display: flex;
    flex-direction: column;

    &__list {
        display: flex;
        flex-direction: column;
        margin-bottom: $file__list_margin-bottom;
    }

    &__item {
        color: $color-text;

        & + & {
            margin-top: $file__list-item_margin-top;
        }
    }

    &__label {
        align-self: flex-start;
    }

    &__input {
        @extend .sr-only;

        @include focus-visible {
            + #{$block}__input-trigger {
                border-color: $color-focus;
                box-shadow: none;
            }
        }
    }

    /*  Size
        ========================================================================== */

    &--size-sm {
        #{$block} {
            &__item {
                font-size: $font-size-sm;
            }
        }
    }

    /*  Multi select
    ========================================================================== */

    &--multi-select {
        #{$block} {
            &__list {
                cursor: default;
            }

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: $file__list_padding;
                border: $border-width-light solid $color-black-100;
                background-color: $color-white;
            }

            &__item-trigger {
                margin-left: $file__item-trigger_margin;
            }
        }
    }
}
