/*  ==========================================================================
    Grid
    ========================================================================== */

/*  Baseline grid
    (https://material.io/guidelines/layout/metrics-keylines.html#metrics-keylines-baseline-grids)
    ========================================================================== */

$grid-unit-value: 8;

/// All components align to an 8dp square baseline grid.
/// @group grid
$grid-unit: $grid-unit-value * 1px;

/// Iconography, typography, and some elements within components can align to a 4dp grid.
/// @group grid
$grid-type-unit: ($grid-unit-value * 0.5) * 1px;

/*  Touch area
    Touch optimized interactive areas (https://material.io/design/layout/spacing-methods.html#touch-click-targets)
    Based on 48px minimum touch area (visual area can be smaller eg 36px + 6px margin)
    ========================================================================== */
$touch-area: grid-unit(6);
$min-touch-area: grid-type-unit(9);
$min-touch-area-spacing: ($touch-area - $min-touch-area) * 0.5;

/*  Responsive grid
    ========================================================================== */

/* Number of columns in the grid */
$grid-columns: 12;

/* Padding between columns. Gets divided in half for the left and right */
$grid-gutter: grid-unit();

/* Grid feature variables */
/// Enable generation of 12 column grid classes (eg. col-xs-6)
/// @group configuration
$enable-grid-classes: false !default;

/*  Container sizes
    Define the maximum width of `.container` for different screen sizes.
    ========================================================================== */
$container-max-modifier: $grid-gutter * 6;

$container-xs: $breakpoint-xs - $container-max-modifier;
$container-xs-mid: $breakpoint-xs-mid - $container-max-modifier;
$container-sm: $breakpoint-sm - $container-max-modifier;
$container-sm-mid: $breakpoint-sm-mid - $container-max-modifier;
$container-md: $breakpoint-md - $container-max-modifier;
$container-md-mid: $breakpoint-md-mid - $container-max-modifier;
$container-lg: $breakpoint-lg - $container-max-modifier;
$container-lg-mid: $breakpoint-lg-mid - $container-max-modifier;
$container-xl: $breakpoint-xl - $container-max-modifier;
$container-xl-mid: $breakpoint-xl-mid - $container-max-modifier;
$container-xxl: $breakpoint-xxl - $container-max-modifier;

$container-max-widths: (
    xs: $container-xs,
    xs-mid: $container-xs-mid,
    sm: $container-sm,
    sm-mid: $container-sm-mid,
    md: $container-md,
    md-mid: $container-md-mid,
    lg: $container-lg,
    lg-mid: $container-lg-mid,
    xl: $container-xl,
    xl-mid: $container-xl-mid,
    xxl: $container-xxl,
);

/* evaluate container-max-widths map */
@include _assert-ascending($container-max-widths, '$container-max-widths');
