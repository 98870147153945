/*  ==========================================================================
    Form
    ========================================================================== */

//Addon sections
$form-input-group__addon_color: $color-text-soft;
$form-input-group__addon_bg-color: $color-blue-100;
$form-input-group__addon_border-color: $form-control-border-color;

//Addon items
$form-input-group__addon-item_margin-v--size-sm: -1px;
$form-input-group__addon-item_margin-h--size-sm: grid-type-unit(1);
$form-input-group__addon-item_margin-v--size-md: -1px;
$form-input-group__addon-item_margin-h--size-md: grid-unit(1);

//Contextual
$form-input-group__form-control_height--size-md: $form-control-height--size-md - ($form-control-border-w * 2);
$form-input-group__form-control_height--size-sm: $form-control-height--size-sm - ($form-control-border-w * 2);
$form-input-group__form-control_padding-v--size-sm: (
        $form-input-group__form-control_height--size-sm - $line-height-base-computed
    ) * 0.5;
$form-input-group__form-control_padding-v--size-md: (
        $form-input-group__form-control_height--size-md - $line-height-base-computed
    ) * 0.5;
$form-input-group__form-control_padding-h: grid-unit(1);
$form-input-group__form-control_padding-density-sm-h: grid-type-unit(1);
$form-input-group__form-control_padding-density-xs-h: grid-type-unit(0.5);

/*  Legacy
    ========================================================================== */
$form-input-group__form-control_height: $form-control-height--size-md - ($form-control-border-w * 2);
$form-input-group__form-control_padding-v: $form-input-group__form-control_padding-v--size-md;
$form-input-group__addon-item_margin-v: $form-input-group__addon-item_margin-v--size-md;
$form-input-group__addon-item_margin-h: $form-input-group__addon-item_margin-h--size-md;
