/*  ==========================================================================
    Views
    ========================================================================== */

#content-view-switcher {
    display: flex;
    justify-content: flex-end;
    margin: $content-view-switcher-margin;

    .view-results {
        align-self: center;
        margin-right: auto;
        padding-right: $content-view-results-padding;
        font-size: $content-view-results-font-size;
        line-height: $content-view-results-line-height;
        color: $content-view-results-color;

        .view-results-count {
            font-weight: $font-weight-bold;
        }
    }

    /*  Switch controls
        ========================================================================== */

    .view-switcher-display-control {
        @extend %trigger-layout;
        @extend %trigger-layout-fixed;

        margin-right: 0;
        margin-left: 0;
        font-size: $content-view-switcher-display-control-font-size;
        color: $content-view-switcher-display-control-color;

        &:hover {
            color: $content-view-switcher-display-control-hover-color;
        }

        &:active {
            color: $content-view-switcher-display-control-pressed-color;
        }

        &.is-active {
            color: $content-view-switcher-display-control-active-color;
            cursor: default;
        }
    }

    .view-switcher-sort-label {
        margin-right: $content-view-switcher-sort-label-margin;
    }

    #view-switcher-sort-control {
        @extend %form-control;
        @extend %form-control-on-blue;
    }

    /*  Affected elements
        ========================================================================== */

    + #content-wrapper {
        margin-top: $content-view-bottom-offset;

        @include media-breakpoint-up(sm) {
            margin-top: $content-view-bottom-offset-lg;
        }
    }
}
