/*  ==========================================================================
    Switch
    ========================================================================== */

@mixin switch-size(
    $switch-height,
    $switch-size-offset,
    $switch-font-size,
    $switch-button-radius,
    $switch-handle-radius,
    $switch-handle-size,
    $switch-spacing,
    $switch-border-width
) {
    &-control {
        height: $switch-height;
        padding: ($switch-size-offset * 0.5) 0;
        line-height: $switch-height;
    }

    &-button {
        width: $switch-button-width - ($switch-border-width * 2);
        height: $switch-height - ($switch-border-width * 2);
        border: $switch-border-width solid transparent;
        border-radius: $switch-button-radius;
        font-size: $switch-font-size;
        line-height: $switch-handle-size;
    }

    &-handle {
        width: $switch-handle-size;
        height: $switch-handle-size;
        margin: $switch-spacing - $switch-border-width;
        border-radius: $switch-handle-radius;
    }

    &-label {
        font-size: $switch-font-size;
    }

    &.switch-label-right {
        .switch-label {
            margin-left: $switch-spacing * 2;
        }
    }

    &.switch-label-left {
        .switch-label {
            margin-right: $switch-spacing * 2;
        }
    }

    input:checked + .switch-control {
        .switch-handle {
            left: calc(100% - #{$switch-handle-size + ($switch-spacing * 2) - ($switch-border-width * 2)});
        }
    }
}
