@use 'sass:math';

/*  ==========================================================================
    Buttons
    ========================================================================== */

/* Layout */
$btn-min-height: $component-size-base;
$btn-min-width: $component-size-base;
$btn-border-size: $border-width-light;
$btn-font-weight: $font-weight-semibold;
$btn-line-height: $font-size-lg;
$btn-padding-vertical: (($btn-min-height - $btn-line-height) * 0.5) - $btn-border-size;
$btn-padding-horizontal--density-sm: grid-type-unit(2);
$btn-padding-horizontal: grid-type-unit(3);
$btn-padding: $btn-padding-vertical $btn-padding-horizontal;
$btn-padding--density-sm: $btn-padding-vertical $btn-padding-horizontal--density-sm;

/* icon */
$btn-icon-size: $icon-font-size-base;
$btn-icon-margin: grid-unit();

/*  Importance
    ========================================================================== */

$btn-primary-bg: $color-cta-primary;
$btn-primary-color: $color-white;
$btn-primary-border-color: transparent;
$btn-primary-hover-bg: $color-purple-600;
$btn-primary-pressed-bg: $color-purple-800;

$btn-secondary-bg: $color-white;
$btn-secondary-color: $color-main;
$btn-secondary-border-color: $color-blue-300;
$btn-secondary-pressed-border-color: $color-blue-300;
$btn-secondary-hover-bg: $color-hover-background;
$btn-secondary-pressed-bg: $color-pressed-background;

$btn-tertiary-color: $color-blue-600;
$btn-tertiary-hover-color: $color-blue-500;
$btn-tertiary-pressed-color: $color-blue-700;

$btn-quaternary-color: $color-text-soft;
$btn-quaternary-hover-color: $color-blue-500;
$btn-quaternary-pressed-color: $color-blue-700;

/*  Menu
    ========================================================================== */

$btn-menu-font-weight: $font-weight-regular;
$btn-menu-bg: transparent;
$btn-menu-hover-bg: $color-hover-background;
$btn-menu-pressed-bg: $color-pressed-background;
$btn-menu-active-bg: $color-active-background;
$btn-menu-color: $color-text-soft;
$btn-menu-hover-color: $color-hover-text;
$btn-menu-pressed-color: $color-pressed-text;

/*  Themes
    ========================================================================== */

$btn-primary-on-dark-blue-color: $color-dark-blue;
$btn-primary-on-dark-blue-bg: $color-white;
$btn-primary-on-dark-blue-hover-bg: $color-blue-100;
$btn-primary-on-dark-blue-pressed-bg: $color-blue-300;

$btn-secondary-on-light-gray-bg: $color-white;
$btn-secondary-on-light-gray-hover-bg: $color-white;
$btn-secondary-on-light-gray-pressed-bg: $color-black-50;
$btn-secondary-on-light-gray-border-color: $color-black-300;
$btn-secondary-on-light-gray-hover-border-color: $color-black-400;
$btn-secondary-on-light-gray-pressed-border-color: $btn-secondary-on-light-gray-hover-border-color;

$btn-secondary-on-dark-blue-border-color: $color-blue-300;
$btn-secondary-on-dark-blue-hover-border-color: $btn-secondary-on-dark-blue-border-color;
$btn-secondary-on-dark-blue-pressed-border-color: $btn-secondary-on-dark-blue-border-color;

$btn-menu-on-light-gray-hover-bg: $color-black-100;

$btn-menu-on-dark-blue-bg: transparent;
$btn-menu-on-dark-blue-hover-bg: $color-blue-600;
$btn-menu-on-dark-blue-pressed-bg: $color-blue-700;
$btn-menu-on-dark-blue-active-bg: $btn-menu-on-dark-blue-pressed-bg;
$btn-menu-on-dark-blue-color: $color-white;
$btn-menu-on-dark-blue-hover-color: $btn-menu-on-dark-blue-color;
$btn-menu-on-dark-blue-pressed-color: $btn-menu-on-dark-blue-color;

$btn-menu-on-header-pressed-bg: $color-blue-800;
$btn-menu-on-header-active-bg: $btn-menu-on-header-pressed-bg;

/*  Sizes
    ========================================================================== */

$btn-small-min-width: $component-size-sm;
$btn-small-min-height: $component-size-sm;
$btn-small-font-size: $font-size-sm;
$btn-small-line-height: $btn-small-font-size + 1px; // fix rendering issue by adding 1px
$btn-small-padding-horizontal: grid-unit(1);
$btn-small-padding-horizontal--density-sm: grid-type-unit(1);

$btn-small-padding-vertical-modifier: 2px;
$btn-small-padding-vertical: math.div($btn-small-min-height - $btn-small-line-height, 2) - $btn-border-size -
    $btn-small-padding-vertical-modifier;
$btn-small-padding: $btn-small-padding-vertical $btn-small-padding-horizontal;
$btn-small-padding--density-sm: $btn-small-padding-vertical $btn-small-padding-horizontal--density-sm;

/* icon */
$btn-small-icon-size: $icon-font-size-sm;
$btn-small-icon-margin: grid-type-unit();
