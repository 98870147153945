/*  ==========================================================================
    Notification bar
    ========================================================================== */

#notification-bar {
    position: fixed;
    z-index: $z-index-notifications;
    top: 0;
    width: 100%;
    min-height: $notification-bar-height;
    transform: translateY(-100%);
    transition: all $layout-animation-vertical-speed-out ease-in;

    /*  Main notification
    ========================================================================== */

    .notification-main {
        display: flex;
        min-height: $notification-bar-height;
        padding: 0 $notification-bar-overflow-padding;
        font-size: $notification-bar-font-size;
        transition: all $animate-duration-short ease-in-out;

        @include media-breakpoint-down(md) {
            padding-right: $notification-bar-overflow-padding-xs;
            padding-left: $notification-bar-overflow-padding-xs;
        }

        .notification-message {
            @include text-overflow();
        }

        /*  Status
            ========================================================================== */

        &.notification-warning,
        &.notification-danger {
            @include notification-style(
                $notification-bar-danger-bg,
                $notification-bar-danger-border,
                $notification-bar-danger-text,
                $notification-bar-danger-icon-color,
                transparent,
                transparent
            );
        }

        &.notification-danger {
            /**
             * Temporary fix for main danger status notification
             * > incorrectly rendering close trigger and receiving 'notification-dismissible' class
             *
             * TODO: remove this styling when logic and associated templating is fixed in @priva/notifications package
             */

            &,
            + .notification-more {
                .notification-close {
                    display: none;
                }
            }
        }

        /*  Actions
        ========================================================================== */

        .status-icon,
        .notification-close {
            flex: 0 1 auto;
        }

        .notification-close {
            @extend .btn;
            @extend .btn-icon;

            position: absolute;
            display: inline-flex;
            width: $notification-bar-height;
            height: $notification-bar-height;
        }
    }

    /*  Notification toggle
        ========================================================================== */

    .notification-toggle {
        align-self: center;
        padding-right: $notification-padding-horizontal;
        padding-left: $notification-padding-horizontal;

        @include media-breakpoint-up(sm) {
            padding-right: $notification-bar-overflow-item-padding-h;
            padding-left: $notification-bar-overflow-item-padding-h;
        }

        &:hover {
            background-color: $color-red-600;
        }

        &__label {
            @include text-overflow();

            line-height: $notification-bar-toggle-label-line-height;
            text-align: left;
        }

        &__icon {
            &--left {
                margin-right: grid-unit(1);
                font-size: $btn-icon-size;
            }

            &--right {
                margin-left: grid-unit(2);
                font-size: $btn-small-icon-size;
            }
        }
    }

    /*  Notification
    ========================================================================== */

    .notification {
        overflow: hidden;
        line-height: $notification-bar-main-line-height;
        user-select: text;

        @include media-breakpoint-up(sm) {
            padding-right: $notification-bar-overflow-item-padding-h;
            padding-left: $notification-bar-overflow-item-padding-h;
        }

        &.notification-hiding {
            opacity: 1;
        }
    }

    /* angular specific */
    priva-notification,
    priva-notification-legacy {
        flex-grow: 1;
        width: 100%;
    }

    .notification-message__content {
        /* angular specific */
        + priva-action-button-group-legacy {
            margin-left: $notification-message-margin;
        }
    }

    //TODO: disable emphasis block in notification bar component, ie by using different interface for bar
    .notification-emphasis-block {
        display: inline-block;
        margin-right: $notification-message-margin;
    }

    /*  Overflow for more notifications
        ========================================================================== */

    .notification-more {
        @include box-shadow(3, null, null, 2);

        position: absolute;
        z-index: $z-index-below;
        top: 100%;
        left: 0;
        overflow: auto;
        width: 100%;
        max-height: $notification-bar-overflow-max-height-fallback;
        max-height: $notification-bar-overflow-max-height;
        border-radius: $border-radius-small;
        font-size: $notification-bar-font-size;
        background: $notification-bar-overflow-bg;
        transform: translateY(calc(-100% + (#{-$notification-bar-overflow-padding} * 2)));
        transition: transform $animate-duration-medium ease-in-out;

        .notification {
            padding: $notification-bar-overflow-item-padding;
            border-bottom: $notification-bar-overflow-item-border-width solid $color-border;
            border-radius: 0;
            line-height: $notification-bar-overflow-item-line-height;
            background-color: $color-white;

            &-emphasis,
            &-emphasis-block {
                color: $color-red-900;
            }

            &-emphasis-block {
                display: block;
            }

            &-close {
                top: $notification-bar-overflow-item-icon-position-top;
                color: $btn-quaternary-color;

                &:hover {
                    &::before {
                        color: $btn-quaternary-hover-color;
                    }
                }

                &:active {
                    &::before {
                        color: $btn-quaternary-pressed-color;
                    }
                }
            }

            .status-icon {
                line-height: $notification-bar-overflow-item-line-height;
                color: $color-red-700;
            }
        }

        @include media-breakpoint-up(sm) {
            max-width: $notification-bar-overflow-max-width;
            max-height: $notification-bar-overflow-max-height-md-fallback;
            max-height: $notification-bar-overflow-max-height-md;
            margin: $notification-bar-overflow-padding;
        }
    }

    /*  States
    ========================================================================== */

    &.showing-more {
        .notification-main {
            max-height: $notification-bar-height + ($line-height-computed * 3);

            .notification-message {
                white-space: normal;
            }
        }

        .notification-more {
            top: calc(100% + #{$header-border-top});
            transform: translateY(0);
            transition: transform $animate-duration-long ease-in-out;
        }
    }

    /*  Animation
        ========================================================================== */

    .has-notification-bar & {
        transform: none;
        transition: all $layout-animation-vertical-speed-in ease-out;
    }

    /*  Only wrap text when main notification is in hover state
        TODO: remove when notification package have been adapted according IXD spec (support for multiline blocking notification) */

    &:hover {
        max-height: $notification-bar-height + ($line-height-computed * 3);

        .notification-message,
        .notification-toggle__label {
            white-space: normal;
        }

        .notification {
            max-height: initial;
        }
    }

    /*  Sibling
    ========================================================================== */

    /* Angular sibling support */
    priva-notification + priva-notification,
    priva-notification-legacy + priva-notification-legacy {
        .notification {
            margin-top: 0;
        }
    }
}

/*  Backdrop
    ========================================================================== */

.notification-backdrop {
    position: fixed;
    z-index: $z-index-global-background;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: $notification-bar-backdrop-bg-color;
    pointer-events: auto;
    cursor: pointer;
    transition: opacity $animate-duration ease-in;
}
