/*  ==========================================================================
    Splash screen background
    ========================================================================== */

.bg-splashscreen {
    background-color: $color-main;
    background-image: url('#{$img-path}/priva-theme.svg'),
        radial-gradient(farthest-corner at 300% 30%, $color-accent-dark, $color-main);
    background-repeat: no-repeat;
    background-position: center grid-unit(-10);

    @include media-breakpoint-up(sm) {
        background-position-y: center;
    }
}
