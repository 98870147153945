/*  ==========================================================================
    Drop Area
    ========================================================================== */

.drop-area {
    $block: &;

    position: relative;
    overflow: hidden;
    border: $drop-area-border-width dashed $drop-area-border-color;
    border-radius: $border-radius;
    color: $drop-area-color;
    cursor: pointer;
    transition:
        background-color $animate-duration $animation-easing,
        border $animate-duration $animation-easing;

    &:hover,
    &.is-dragover {
        border-color: $drop-area-hover_border-color;
        background-color: $drop-area-hover_background-color;
    }

    /*  Contextual
        ========================================================================== */

    .form-control-help {
        margin-top: $drop-area__help_margin-top;
        font-size: $font-size-sm;
        line-height: $line-height-sm-computed;
    }

    .file {
        margin-top: $drop-area__file_margin-top;
    }

    /*  Theme
        ========================================================================== */

    @include theme(light-blue) {
        border-color: $drop-area--theme-on-light-blue_border;
    }

    /*  Size
        ========================================================================== */

    &--size-sm {
        padding: $drop-area--sm_padding;
    }

    &--size-md {
        padding: $drop-area--md_padding;
    }
}
