/*  ==========================================================================
    d3 plugin custom styles
    ========================================================================== */

.d3 {
    &.chart {
        background-color: $color-white;

        .xaxis {
            .domain {
                stroke: $color-main;
            }

            text {
                fill: $color-main;
            }

            line,
            .domain {
                stroke: transparent;
            }
        }

        .yaxis {
            line {
                stroke: $color-blue-300;
            }

            .domain {
                stroke: transparent;
            }
        }

        .xaxislabel,
        .yaxislabel {
            font-size: 10px;
            fill: $color-main;
        }

        .bottomline line {
            stroke: $color-main;
        }

        .line {
            fill: none;
            stroke: $color-accent-dark;
            stroke-width: 4px;
        }

        .xgridlines,
        .ygridlines {
            .tick line {
                fill: none;
                stroke: $color-border;
                stroke-width: 1px;
            }

            .domain {
                stroke: transparent;
            }
        }

        .point {
            fill: $color-white;
            stroke: $color-accent-dark;
            stroke-width: 1px;
        }

        .overlay {
            pointer-events: all;
            fill: none;
        }

        .point-label {
            font-size: 12px;
        }

        .selection {
            fill: $color-selected;
            stroke: $color-selected;
        }
    }
}
