/*  ==========================================================================
    Alert
    ========================================================================== */

/* Alert style */
@mixin notification-style(
    $background,
    $border-color,
    $text-color,
    $icon-color,
    $close-hover-bg,
    $close-pressed-bg
) {
    border-color: $border-color;
    background: $background;
    color: $text-color;

    a,
    .notification-link {
        color: $text-color;
    }

    .status-icon {
        color: $icon-color;
    }

    .notification-close {
        &:hover {
            &::before {
                background: $close-hover-bg;
            }
        }

        &:active {
            &::before {
                background: $close-pressed-bg;
            }
        }
    }
}
