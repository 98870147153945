/*  ==========================================================================
    Dialog stepped type
    ========================================================================== */

.dialog-stepped {
    .dialog-content {
        overflow: hidden;
    }

    .dialog-header {
        z-index: $z-index-above;
        background: $color-white;
    }
}

/*  Stepper
    ========================================================================== */

.dialog-stepper {
    margin: $dialog-stepper-margin;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-current-title {
        @extend %heading-3;
    }

    /*  Stepper indicator
        ========================================================================== */

    &-indicator {
        display: flex;
        margin: $dialog-stepper-indicator-margin;
        padding: $dialog-stepper-indicator-padding;

        &-item {
            display: flex;
            flex: 1 1 0;
            align-items: center;

            &-line {
                flex: 1 1 100%;
                border-top: $dialog-stepper-indicator-line-width solid $dialog-stepper-indicator-line-color;
            }

            &-trigger {
                display: flex;
                flex: 0 0 auto;
                padding: ($min-touch-area - $dialog-stepper-indicator-size) * 0.5;

                &::after {
                    content: '';
                    flex: 0 0 auto;
                    width: $dialog-stepper-indicator-size;
                    height: $dialog-stepper-indicator-size;
                    border: $dialog-stepper-indicator-trigger-border-width
                        $dialog-stepper-indicator-line-style $dialog-stepper-indicator-trigger-border-color;
                    border-radius: $border-radius-circle;
                    background-color: $dialog-stepper-indicator-trigger-bg-color;
                    transition: border $animate-duration-medium ease;
                }
            }

            &:first-child {
                flex: 0 0 0;

                .dialog-stepper-indicator-item-line {
                    display: none;
                }
            }

            &:not(.is-disabled, .is-active) {
                .dialog-stepper-indicator-item-trigger {
                    cursor: pointer;

                    &:hover,
                    &:focus {
                        &::after {
                            border-width: $dialog-stepper-indicator-trigger-hover-border-width;
                        }
                    }

                    &:active {
                        &::after {
                            border-width: $dialog-stepper-indicator-trigger-active-border-width;
                        }
                    }
                }
            }

            &.is-completed {
                .dialog-stepper-indicator-item-trigger {
                    &::after {
                        background-color: $dialog-stepper-indicator-completed-trigger-bg-color;
                    }
                }
            }

            &.is-active {
                .dialog-stepper-indicator-item-trigger {
                    &::after {
                        background-color: $dialog-stepper-indicator-active-trigger-bg-color;
                    }
                }
            }

            &.is-disabled {
                .dialog-stepper-indicator-item-line {
                    border-top-style: $dialog-stepper-indicator-disabled-line-style;
                }
            }
        }
    }
}
