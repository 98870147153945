/*  ==========================================================================
    Date
    ========================================================================== */

@use 'sass:math';

$datepicker-popup-margin: grid-type-unit() 0;
$datepicker-popup-padding: grid-unit(2);

/* navigation */
$datepicker-nav-margin-bottom: grid-unit();
$datepicker-nav-header-margin-h: grid-unit();
$datepicker-nav-header-margin: 0 $datepicker-nav-header-margin-h;
$datepicker-nav-header-width: calc(100% - #{$datepicker-nav-header-margin-h * 2});
$datepicker-nav-header-padding: 0 $min-touch-area;
$datepicker-nav-button-size: $min-touch-area;

/* day picker */
$datepicker-week-number-font-size: 8px; // custom font size - if there are more use-cases introduce $font-size-2xs
$datepicker-weekday-width: $min-touch-area;
$datepicker-weekday-height: $line-height-computed;
$datepicker-weekdays-margin-bottom: grid-type-unit();
$datepicker-weekdays-height: $datepicker-weekday-height + $datepicker-weekdays-margin-bottom;
$datepicker-day-trigger-margin: 2px;
$datepicker-day-trigger-size-outer: $min-touch-area;
$datepicker-day-trigger-size: $datepicker-day-trigger-size-outer - ($datepicker-day-trigger-margin * 2);

/* month picker */
$datepicker-month-trigger-margin: grid-unit();

/* align popup size with day calendar */
// calculate month width according to day column width (7 day columns / 4 month columns)
$datepicker-month-trigger-width: ($datepicker-day-trigger-size-outer * 1.75) -
    $datepicker-month-trigger-margin * 2;
// calculate month height according to day height (5 days rows + weekday rows / 4 rows)
$datepicker-month-trigger-height: ($datepicker-day-trigger-size-outer * 1.25) +
    ($datepicker-weekdays-height * 0.25) - $datepicker-month-trigger-margin * 2;

/* time picker */
$datepicker-time-select-controls-margin: grid-unit(-1) 0;
$datepicker-time-select-separator: grid-unit();
$datepicker-time-select-display-padding: grid-type-unit() 0;
$datepicker-time-select-day-calendar-offset-margin: grid-unit(2);
$datepicker-time-select-day-calendar-offset-padding: grid-unit(3);

/* has today button */
$datepicker-has-today-button-nav-header-width: calc(100% - (3 * #{$component-size-base}));

%datepicker-selected {
    font-weight: $font-weight-bold;
    background: $color-selected;
    color: $color-white;
}
