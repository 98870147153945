/*  ==========================================================================
    Animate
    ========================================================================== */

$animate-duration-short: 0.12s;
$animate-duration-medium: 0.24s;
$animate-duration: 0.36s;
$animate-duration-long: 0.64s;
$animate-duration-spin: 1s;
$animation-easing: ease;
$animation-fill-mode: both;
$animate-fade-distance: grid-unit(2);
