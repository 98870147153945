/*  ==========================================================================
    Popover
    ========================================================================== */

$popover-min-width: $min-touch-area;
$popover-max-width: calc(100vw - #{$touch-area});
$popover-min-height: $min-touch-area;
$popover-max-height-fallback: calc(100vh - #{$touch-area});
$popover-max-height: calc(100dvh - #{$touch-area});
$popover-border-width: $border-width-light;
$popover-border-color: $color-white;
$popover-padding-v--size-md: grid-unit();
$popover-padding-v--size-lg: grid-type-unit(3);
$popover-padding--size-md: ($popover-padding-v--size-md - $popover-border-width) grid-type-unit(3);
$popover-padding--size-lg: ($popover-padding-v--size-lg - $popover-border-width) grid-unit(2);
$popover-arrow-size: grid-unit(1);
$popover-font-size: $font-size-md;
$popover-line-height: $line-height-computed;
