/*  ==========================================================================
    Expander
    ========================================================================== */

/* Trigger */
$expander-trigger-icon-size: $font-size-sm;
$expander-trigger-icon-margin: grid-type-unit(3);

/* Content */
$expander-content-margin: 0;
$expander-content-aligned: $btn-padding-horizontal;
