/*  ==========================================================================
    Maintain aspect ratio
    ========================================================================== */

@use 'sass:math';

@mixin aspect-ratio($width, $height) {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: math.div($height, $width) * 100%;
    }

    > * {
        position: absolute;
        inset: 0;
    }
}
