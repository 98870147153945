/*  ==========================================================================
    Standard header
    ========================================================================== */

/* layout */
$header__main-start_margin-right: grid-unit();
$header__main-end_margin-left: grid-unit(2);

/* elements */
$header__title_color: $color-text;
$header__trigger_margin: grid-type-unit();
$header__trigger_font-size: $icon-font-size-sm;
