/*  ==========================================================================
    Input group
    ========================================================================== */

/*  base styles
    ========================================================================== */

.input-group {
    position: relative; // for action-menus
    display: flex;
    align-items: stretch;
    width: 100%;

    /* undo padding and float of grid classes */
    &[class*='col-'] {
        float: none;
        padding-right: 0;
        padding-left: 0;
    }

    .table & {
        width: 100%;
    }

    .form-control {
        /* Ensure that the input is always above the *appended* addon button for
         proper border colors. */
        position: relative;
        z-index: $z-index-above;
        flex: 1 1 auto;

        /* Add width 1% and flex-basis auto to ensure that button will not wrap out
         the column. Applies to IE Edge+ and Firefox. Chrome does not require this. */
        width: 1%;

        &:focus {
            z-index: $z-index-focus;
        }

        &.attribute {
            flex-grow: 0;
        }
    }
}

// Add flex display
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
    display: flex;
    align-items: center;

    &:not(:first-child, :last-child) {
        border-radius: 0;
    }
}

// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
}

// Text input groups
// -------------------------
.input-group-addon {
    padding: grid-type-unit() grid-unit();
    border: $form-control-border-w solid $form-input-group__addon_border-color;
    border-radius: $border-radius;
    font-size: $font-size-base;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    background-color: $form-input-group__addon_bg-color;

    // Nuke default margins from checkboxes and radios to vertically center within.
    input[type='radio'],
    input[type='checkbox'] {
        margin-top: 0;
    }
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .action-menu-toggle,
.input-group-btn:last-child > .btn:not(:last-child, .action-menu-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    @include border-right-radius(0);
}

.input-group-addon:first-child {
    border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .action-menu-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    @include border-left-radius(0);
}

.input-group-addon:last-child {
    border-left: 0;
}

// Button input groups
// -------------------------
.input-group-btn {
    position: relative;
    align-items: stretch;
    // Jankily prevent input button groups from wrapping with `white-space` and
    // `font-size` in combination with `inline-block` on buttons.
    font-size: 0;
    white-space: nowrap;

    // Negative margin for spacing, position for bringing hovered/focused/actived
    // element above the siblings.
    > .btn {
        position: relative;

        + .btn {
            margin-left: -$form-control-border-w;
        }
        // Bring the "active" button to the front
        &:hover,
        &:focus,
        &:active {
            z-index: $z-index-above;
        }
    }

    // Negative margin to only have a 1px border between the two
    &:first-child {
        > .btn,
        > .btn-group {
            margin-right: -$form-control-border-w;
        }
    }

    &:last-child {
        > .btn,
        > .btn-group {
            z-index: $z-index-above;
            margin-left: -$form-control-border-w;
        }
    }
}

// Custom styling for certain input types in tables
// Input fields on readonly cell look like text
.is-readonly,
.readonly {
    .is-disabled,
    .disabled,
    .input-group-addon {
        border-color: transparent;
        background-color: transparent;
        color: $color-text;
    }

    select:disabled {
        text-indent: 0.01px;
        text-overflow: '';
        appearance: none;
    }

    select::-ms-expand {
        display: none;
    }
}

// Alignment of various input and select control
.editable {
    .input-group {
        width: 100%;
    }
}
