/*  ==========================================================================
    Spinner
    ========================================================================== */

.spinner {
    display: inline-flex;

    &,
    &::before {
        width: $spinner-size;
        height: $spinner-size;
    }

    &::before {
        content: '';
        border: $spinner-line-width solid $color-accent-dark;
        border-bottom-color: transparent;
        border-radius: $border-radius-circle;
        animation: spin $animate-duration-spin linear infinite;
        animation-fill-mode: both;
    }

    /* size */
    &-xs {
        &,
        &::before {
            width: $spinner-xs-size;
            height: $spinner-xs-size;
        }
    }

    &-sm {
        &,
        &::before {
            width: $spinner-sm-size;
            height: $spinner-sm-size;
        }
    }

    /* size */
    &-lg {
        &,
        &::before {
            width: $spinner-lg-size;
            height: $spinner-lg-size;
        }

        &::before {
            border-width: $spinner-lg-line-width;
        }
    }

    &-inverse {
        &::before {
            border-color: $color-white;
            border-bottom-color: transparent;
        }
    }
}
