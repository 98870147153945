/*  ==========================================================================
    Ordered list
    ========================================================================== */

.ordered-list {
    &__item {
        display: flex;
        counter-increment: ordered-list-counter;

        &:not(:last-child) {
            margin-bottom: $ordered-list__item_margin-vertical;
        }

        &::before {
            content: counter(ordered-list-counter);
            display: inline-block;
            min-width: $ordered-list__counter_width;
            height: $ordered-list__counter_height;
            margin-right: $ordered-list__counter_margin;
            padding: 0 $ordered-list__counter_padding-horizontal;
            border-radius: $ordered-list__counter_border-radius;
            font-size: $ordered-list__counter_font-size;
            font-weight: $ordered-list__counter_font-weight;
            line-height: $ordered-list__counter_height;
            text-align: center;
            background: $ordered-list__counter_bg-color;
            color: $ordered-list__counter_color;
        }
    }
}
