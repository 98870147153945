/*  ==========================================================================
    Buttons
    ========================================================================== */

@mixin button-size($min-width, $min-height, $line-height, $font-size: false) {
    min-width: $min-width;
    min-height: $min-height;

    @if $font-size {
        font-size: $font-size;
    }

    line-height: $line-height;
}
