/*  ==========================================================================
    Tooltip
    ========================================================================== */

$tooltip-min-width: $min-touch-area;
$tooltip-max-width: $breakpoint-xs;
$tooltip-max-width-horizontal: $breakpoint-xs * 0.5;
$tooltip-height: $min-touch-area;
$tooltip-border-width: $border-width-light;
$tooltip-padding: (($min-touch-area - $line-height-computed - $tooltip-border-width * 2) * 0.5)
    grid-type-unit(3);
$tooltip-font-size: $font-size-sm;
$tooltip-line-height: $line-height-computed;
$tooltip-color: $color-white;
$tooltip-arrow-width: grid-type-unit();

$tooltip-bg: $color-blue-800;
$tooltip-border-color: $color-white;

$tooltip-position-offset: grid-unit();
$tooltip-position: calc(100% + #{$tooltip-position-offset});

$tooltip-arrow-arguments: ($tooltip-arrow-width, $tooltip-bg, $tooltip-border-width, $tooltip-border-color);

%tooltip-is-visible {
    opacity: 1;
    visibility: visible;

    &.tooltip--left,
    &.tooltip--right {
        transform: translateY(-50%);
    }

    &.tooltip--top,
    &.tooltip--bottom {
        transform: translateX(-50%);
    }
}
