/*  ==========================================================================
    Fontawesome icon font
    ========================================================================== */

/* Basic font variables */

$fa-font-path: '#{$font-path}/fontawesome';
$fa-font-size-base: $font-size-base;
$fa-font-display: block;
$fa-border-color: $color-black-100;
$fa-inverse: $color-white;
