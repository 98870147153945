/*  ==========================================================================
    Content sidebar
    ========================================================================== */

#content-sidebar {
    @include media-breakpoint-down(sm) {
        margin-top: $content-sidebar-spacing;
    }

    @include media-breakpoint-up(md) {
        flex: 1 0 auto;
        max-width: $content-sidebar-right-max-width;
        margin-left: $content-sidebar-spacing;
    }
}
