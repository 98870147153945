/*  ==========================================================================
    Table legacy
    ========================================================================== */

/* background */
$table-legacy-bg: $color-white;
$table-legacy-spacing: grid-unit(3);
$table-legacy-padding: $table-legacy-spacing 0;
$table-legacy-border-width: 1px;
$table-legacy-border-color: $color-divider;

/* caption */
$table-legacy-caption-color: $color-heading;
$table-legacy-caption-padding: 0 $table-legacy-spacing;

/* cell */
$table-legacy-cell-padding: (($touch-area - $line-height-computed) * 0.5) $table-legacy-spacing;

/* heading cell */
$table-legacy-heading-cell-padding: (($touch-area - $line-height-computed) * 0.5) $table-legacy-spacing
    grid-unit() $table-legacy-spacing;

/* dense */
$table-legacy-dense-spacing: grid-unit(2);
$table-legacy-dense-padding: $table-legacy-dense-spacing 0;
$table-legacy-dense-caption-padding: 0 $table-legacy-dense-spacing;
$table-legacy-dense-cell-padding: (($min-touch-area - $line-height-computed) * 0.5)
    $table-legacy-dense-spacing;
$table-legacy-dense-heading-cell-padding: (($min-touch-area - $line-height-computed) * 0.5)
    $table-legacy-dense-spacing grid-unit() $table-legacy-dense-spacing;
