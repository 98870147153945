/*  ==========================================================================
    Drop Area
    ========================================================================== */

$drop-area-border-width: $border-width-light;
$drop-area-border-color: $color-black-200;
$drop-area-color: $color-text-soft;

$drop-area__help_margin-top: grid-type-unit(1);
$drop-area__file_margin-top: grid-unit(2);

/* Padding */
$drop-area--sm_padding: grid-unit(2);
$drop-area--md_padding: grid-unit(3);

/* States */
$drop-area-hover_border-color: $color-blue-400;
$drop-area-hover_background-color: $color-hover-background;

/* Theme */
$drop-area--theme-on-light-blue_border: $color-blue-300;
