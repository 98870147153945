/*  ==========================================================================
    Slider
    ========================================================================== */

@mixin color-mix-percentage($color-from, $color-to) {
    @for $i from 0 through 100 {
        $percentage: $i * 1%;

        &[aria-valuenow^='#{$i}'],
        &.color-mix-#{$i} {
            border-color: mix($color-to, $color-from, $percentage);
        }
    }
}
