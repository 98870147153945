/*  ==========================================================================
    Tabs
    ========================================================================== */

.tab-list {
    $block: &;

    display: flex;

    &--copy {
        position: fixed;
        top: -100%;
        right: -100%;
        visibility: hidden;

        * {
            transition: none;
        }
    }

    &--overflow-scroll {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        max-width: 100%;
    }

    &__item {
        flex: 0 0 auto;
        min-width: $component-size-base;

        & + & {
            margin-left: $tab-list--dense-md__link_offset;
        }

        &--offcanvas {
            position: fixed;
            top: -100%;
            right: -100%;
        }
    }

    &__overflow-menu {
        width: 100%;
    }

    &__overflow-trigger {
        @include property-states(
            'color',
            $tab-list__link_color,
            $tab-list__link-hover_color,
            $tab-list__link-pressed_color,
            color-disabled($tab-list__link_color),
            $tab-list__link-selected_color
        );
        @include property-states(
            'border-color',
            $tab-list__link_border-color,
            $tab-list__link-hover_border-color,
            $tab-list__link-pressed_border-color,
            color-disabled($tab-list__link_border-color),
            $tab-list__link-selected_border-color
        );

        width: 100%;
        border-top: none;
        border-right: none;
        border-bottom-width: $tab-list__link_border-width;
        border-bottom-style: solid;
        border-left: none;
        border-radius: 0;
        font-weight: $tab-list__link_font-weight;
    }

    &__link {
        @include property-states(
            'color',
            $tab-list__link_color,
            $tab-list__link-hover_color,
            $tab-list__link-pressed_color,
            color-disabled($tab-list__link_color),
            $tab-list__link-selected_color
        );
        @include property-states(
            'border-color',
            $tab-list__link_border-color,
            $tab-list__link-hover_border-color,
            $tab-list__link-pressed_border-color,
            color-disabled($tab-list__link_border-color),
            $tab-list__link-selected_border-color
        );

        display: flex;
        justify-content: center;
        border-bottom-width: $tab-list__link_border-width;
        border-bottom-style: solid;
        font-weight: $tab-list__link_font-weight;

        &.is-disabled,
        &.is-active {
            cursor: default;
        }
    }

    /*  Tab content

    Hide tab content panes to start, show them when `.is-active`
    ========================================================================== */

    &__content {
        display: none;

        &.is-active {
            display: block;
        }
    }

    /*  Justified
    ========================================================================== */

    &--justified {
        #{$block}__item {
            flex-grow: 1;
        }
    }

    /*  Size
    ========================================================================== */

    // TODO: remove `&,` when no HTML only tab-list is used anymore
    &,
    &--size-md {
        #{$block} {
            &__link {
                padding: $tab-list--size-md__link_padding;
            }
        }
    }

    &--size-md {
        #{$block} {
            &__overflow-trigger {
                padding-bottom: $tab-list--size-md__overflow-item_padding-bottom;
            }
        }
    }

    &--size-sm {
        #{$block} {
            &__item {
                font-size: $tab-list--size-sm__item_font-size;
                line-height: $tab-list--size-sm__item_line-height;
            }

            &__link {
                padding: $tab-list--size-sm__link_padding;
            }
        }
    }

    /*  Density
    ========================================================================== */

    &--dense-sm {
        #{$block}__item {
            + #{$block}__item {
                margin-left: $tab-list--dense-sm__link_offset;
            }
        }
    }

    /*  Themes
    ========================================================================== */

    @include theme(light-blue) {
        #{$block} {
            &__link,
            &__overflow-trigger {
                @include property-states(
                    'border-color',
                    $tab-list--on-light-blue__link_border-color,
                    $tab-list--on-light-blue__link-hover_border-color,
                    $tab-list--on-light-blue__link-pressed_border-color,
                    color-disabled($tab-list--on-light-blue__link_border-color),
                    $tab-list--on-light-blue__link-selected_border-color
                );
            }
        }
    }

    @include theme(dark-blue) {
        #{$block} {
            &__link,
            &__overflow-trigger {
                @include property-states(
                    'color',
                    $tab-list--on-dark-blue__link_color,
                    $tab-list--on-dark-blue__link-hover_color,
                    $tab-list--on-dark-blue__link-pressed_color,
                    color-disabled($tab-list--on-dark-blue__link_color),
                    $tab-list--on-dark-blue__link-selected_color
                );
                @include property-states(
                    'border-color',
                    $tab-list--on-dark-blue__link_border-color,
                    $tab-list--on-dark-blue__link-hover_border-color,
                    $tab-list--on-dark-blue__link-pressed_border-color,
                    $tab-list--on-dark-blue__link_border-color,
                    $tab-list--on-dark-blue__link-selected_border-color
                );
            }
        }
    }

    /*  Dropdown
    ========================================================================== */

    &-dropdown {
        $block-dropdown: &;

        max-width: $breakpoint-sm;

        &__toggle {
            display: flex;
            justify-content: space-between;
            padding: $tab-list__link_padding;
            border-width: 0 0 $tab-list__link_border-width;
            border-color: $tab-list__link-pressed_border-color;
            border-radius: 0;
            font-weight: $tab-list__link_font-weight;
            color: $tab-list__link-pressed_color;
            cursor: pointer;

            &:hover {
                border-color: $tab-list__link-hover_border-color;
                color: $tab-list__link-hover_color;
            }
        }

        /*  Themes
            ========================================================================== */

        @include theme(light-blue) {
            #{$block-dropdown} {
                &__toggle {
                    &:hover {
                        border-color: $tab-list--on-light-blue-_link-hover_border-color;
                    }
                }
            }
        }

        @include theme(dark-blue) {
            #{$block-dropdown} {
                &__toggle {
                    border-color: $tab-list--on-dark-blue__link-pressed_border-color;
                    color: $tab-list--on-dark-blue__link-pressed_color;

                    &:hover {
                        border-color: $tab-list--on-dark-blue__link-hover_border-color;
                        color: $tab-list--on-dark-blue__link-hover_color;
                    }
                }
            }
        }
    }
}
