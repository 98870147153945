/*  ==========================================================================
    Expander
    ========================================================================== */

/*  Size
    ========================================================================== */

@mixin expander-size($block, $size, $font-size, $line-height, $icon-font-size) {
    &-#{$size} {
        #{$block}__trigger {
            font-size: $font-size;
            line-height: $line-height;

            .btn-icon {
                font-size: $icon-font-size;
            }
        }

        @content;
    }
}
