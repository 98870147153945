/*  ==========================================================================
    Table editable
    ========================================================================== */

.table {
    $block: &;

    &--editable {
        border-spacing: 0;

        #{$block} {
            &__cell {
                border-width: $table__border_width;
                border-style: solid;
                border-color: transparent $table_border-color $table_border-color transparent;

                &:not(#{$block}__cell--editable) {
                    padding-right: $table--editable__cell_padding-horizontal;
                    padding-left: $table--editable__cell_padding-horizontal;
                }

                &--header {
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                }
            }

            &__body #{$block} {
                &__cell {
                    &:first-child {
                        border-left-color: $table_border-color;
                    }

                    &--editable:hover {
                        border-color: $color-selected;
                    }

                    &--editable:focus-within,
                    &.has-focus {
                        border-color: $color-focus;
                    }
                }

                &__row--first #{$block}__cell {
                    border-top-color: $table_border-color;

                    &--editable:hover {
                        border-color: $color-selected;
                    }

                    &--editable:focus-within,
                    &.has-focus {
                        border-color: $color-focus;
                    }
                }
            }
        }

        &#{$block}--has-clickable-rows #{$block}__body #{$block}__row {
            @include focus-visible {
                #{$block}__cell {
                    border-bottom-color: $color-focus;
                }
            }
        }
    }
}
