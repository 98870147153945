/*  ==========================================================================
    Header
    ========================================================================== */

/* Base */
$header-h-inner: $touch-area;
$header-padding-v: 0;
$header-padding-left: grid-unit();
$header-padding-right: grid-unit();
$header-border-top: 0;
$header-border-top-color: $color-main;
$header-border-image: linear-gradient(to right, $color-main, $color-accent-dark 50%) 1;
$header-h: $header-h-inner + ($header-padding-v * 2) + $header-border-top;
$header-bg: $color-dark-blue--emphasis;

/* Initial */
$header-initial-padding-v: grid-unit(3);
$header-initial-h: $header-h-inner + ($header-initial-padding-v * 2) + $header-border-top;

/*  Elements
    ========================================================================== */

/* Icons */
$header-icon-size: $icon-font-size-lg;
$header-icon-color: $color-text-soft;

/* Priva logo */
$header-logo-height: grid-type-unit(5);
$header-logo-margin: grid-unit(1);
$header-logo-color: $color-white;

// custom
$header-logo--custom-height: calc(#{$header-h - $header-logo-margin * 2});

/* App name */
$header-app-name-margin-right: grid-unit(8);
$header-app-name-padding-left: grid-unit();
$header-app-name-line-height: $touch-area;
$header-app-name-font-size: $font-size-lg;
$header-app-name-font-weight: $font-weight-regular;
$header-app-name-color: $color-white;

/* Site switcher */
$header-site-switcher-max-width: grid-unit(70);
$header-site-switcher-margin-left: grid-unit(2);
$header-site-switcher-site-name-font-size: $font-size-sm;
$header-site-switcher-padding: 0 grid-unit(2);
$header-site-switcher-border-radius: $min-touch-area;
$header-site-switcher-icon-margin-right: grid-unit();

/* Notifications */
$header-notification-badge-offset-top: grid-type-unit();
$header-notification-badge-offset-right: grid-type-unit();
$header-notification-badge-font-size: $font-size-xs;
