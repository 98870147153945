/*  ==========================================================================
    Media query
    ========================================================================== */

/*  Minimum screen width
    ========================================================================== */
$screen-xs-min: $breakpoint-xs;
$screen-xs-mid-min: $breakpoint-xs-mid;
$screen-sm-min: $breakpoint-sm;
$screen-sm-mid-min: $breakpoint-sm-mid;
$screen-md-min: $breakpoint-md;
$screen-md-mid-min: $breakpoint-md-mid;
$screen-lg-min: $breakpoint-lg;
$screen-lg-mid-min: $breakpoint-xs-mid;
$screen-xl-min: $breakpoint-xl;
$screen-xl-mid-min: $breakpoint-xl-mid;
$screen-xxl-min: $breakpoint-xxl;

/*  Maximum screen width
    ========================================================================== */

$screen-xs-max: $breakpoint-sm - $breakpoint-max-modifier;
$screen-xs-mid-max: $breakpoint-xs-mid - $breakpoint-max-modifier;
$screen-sm-max: $breakpoint-md - $breakpoint-max-modifier;
$screen-sm-mid-max: $breakpoint-sm-mid - $breakpoint-max-modifier;
$screen-md-max: $breakpoint-lg - $breakpoint-max-modifier;
$screen-md-mid-max: $breakpoint-md-mid - $breakpoint-max-modifier;
$screen-lg-max: $breakpoint-xl - $breakpoint-max-modifier;
$screen-lg-mid-max: $breakpoint-lg-mid - $breakpoint-max-modifier;
$screen-xl-max: $breakpoint-xxl - $breakpoint-max-modifier;
