/*  ==========================================================================
    Switch
    ========================================================================== */

$switch-height: $component-size-sm;
$switch-size-offset: $min-touch-area - $switch-height;
$switch-border-width: $border-width-light;
$switch-font-size: $font-size-base;

/*  Structure */
$switch-button-width: grid-unit(5);
$switch-button-radius: grid-unit(2);

$switch-handle-radius: $switch-button-radius;
$switch-handle-size: grid-unit(2);

$switch-handle-icon-size: $icon-font-size-xxs;

/* Structure dependant */
$switch-spacing: round(($switch-height - $switch-handle-size) * 0.5);

/*  States
    ========================================================================== */

/* off */
$switch-button-bg: $color-blue-100;
$switch-button-color: $color-white;
$switch-handle-bg: $color-white;
$switch-handle-icon-color: $color-blue-300;

/* on */
$switch-on-button-bg: $color-selected;
$switch-on-handle-color: $color-selected;

/* hover */
$switch-button-bg-hover: $color-blue-300;
$switch-on-button-bg-hover: $color-blue-700;
$switch-on-handle-color-hover: $color-blue-700;

/* focus */
$switch-button-border-color-focus: $color-focus;
$switch-on-button-shadow-color-focus: $color-white;
$switch-on-button-border-color-focus: $color-focus;

/* disabled */
$switch-disabled-button-bg: color-disabled($switch-button-bg);
$switch-disabled-handle-color: color-disabled($switch-handle-icon-color);
$switch-disabled-handle-bg: color-disabled($switch-handle-bg);
$switch-disabled-label-color: $color-text-muted;
$switch-disabled-on-button-bg: color-disabled($switch-on-button-bg);
$switch-disabled-on-handle-color: color-disabled($switch-on-handle-color);

/*  On dark blue
    ========================================================================== */

/* off */
$switch-on-dark-blue-button-bg: $color-blue-700;
$switch-on-dark-blue-handle-color: $color-blue-300;
$switch-on-dark-blue-handle-bg: $color-dark-blue--emphasis;
$switch-on-dark-blue-label-color: $color-text-on-dark;

/* on */
$switch-on-dark-blue-on-button-bg: $color-white;
$switch-on-dark-blue-on-handle-color: $color-white;
$switch-on-dark-blue-on-handle-bg: $color-dark-blue--emphasis;

/* hover */
$switch-on-dark-blue-button-bg-hover: $color-blue-500;
$switch-on-dark-blue-on-button-bg-hover: $color-blue-100;
$switch-on-dark-blue-on-handle-color-hover: $switch-on-dark-blue-on-handle-color;

/* focus */
$switch-on-dark-blue-button-shadow-color-focus: $color-white;
$switch-on-dark-blue-button-border-color-focus: $color-blue-700;
$switch-on-dark-blue-on-button-shadow-color-focus: $color-blue-700;
$switch-on-dark-blue-on-button-border-color-focus: $color-white;

/* disabled */
$switch-on-dark-blue-disabled-button-bg: color-disabled($switch-on-dark-blue-button-bg);
$switch-on-dark-blue-disabled-handle-color: color-disabled($switch-on-dark-blue-handle-color);
$switch-on-dark-blue-disabled-label-color: $color-text-muted-on-dark;
$switch-on-dark-blue-disabled-on-button-bg: color-disabled($switch-on-dark-blue-on-button-bg);
$switch-on-dark-blue-disabled-on-handle-color: color-disabled($switch-on-dark-blue-on-handle-color);
