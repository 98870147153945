/*  ==========================================================================
    Standard header
    ========================================================================== */

/* title */
$title__title_color: $color-text;
$title__sub-title_color: $color-text-soft;
$title__sub-title_line-height: $line-height-computed;
$title__icon_background: $color-black-100;
$title__icon_color: $title__title_color;

/* icon */
$title__icon_margin-right: grid-unit();
$title__icon_font-size: $icon-font-size-xl;
$title__icon--circle_size: grid-unit(5);
$title__icon--position-end_margin: 0 0 0 grid-unit();

/*  Sizes
    ========================================================================== */

/* sm */
$title--size-sm__title_font-size: $font-size-md;
$title--size-sm__title_line-height: grid-type-unit(4);
$title--size-sm__sub-title_line-height: grid-type-unit(4);
$title--size-sm__icon_size: grid-type-unit(10);
$title--size-sm__icon_font-size: 16px;
$title--size-sm__icon_offset-v: (
        $title--size-sm__icon_size -
            ($title--size-sm__title_line-height + $title--size-sm__sub-title_line-height)
    ) * 0.5;

/* md */
$title--size-md__icon_size: grid-type-unit(12);
$title--size-md__icon_font-size: 20px;
$title--size-md__title_font-size: $font-size-lg;
$title--size-md__title_line-height: $line-height-computed;
$title--size-md__icon_offset-v: (
        $title--size-md__icon_size - ($title--size-md__title_line-height + $title__sub-title_line-height)
    ) * 0.5;

/* lg */
$title--size-lg__title_font-size: $font-size-xl;
$title--size-lg__title_line-height: $line-height-computed;
$title--size-lg__icon_size: grid-type-unit(13);
$title--size-lg__icon_font-size: 22px;
$title--size-lg__icon_offset-h: grid-type-unit(3);
$title--size-lg__icon_offset-v: (
        $title--size-lg__icon_size - ($title--size-lg__title_line-height + $title__sub-title_line-height)
    ) * 0.5;

/* xl */
$title--size-xl__title_font-size: $font-size-2xl;
$title--size-xl__title_line-height: $line-height-lg-computed;
$title--size-xl__icon_size: grid-type-unit(14);
$title--size-xl__icon_font-size: 24px;
$title--size-xl__icon_offset-h: grid-type-unit(3);
$title--size-xl__icon_offset-v: (
        $title--size-xl__icon_size - ($title--size-xl__title_line-height + $title__sub-title_line-height)
    ) * 0.5;

/* 2xl */
$title--size-2xl__title_font-size: $font-size-3xl;
$title--size-2xl__title_line-height: $line-height-2xl-computed;
$title--size-2xl__sub-title_font-size: $font-size-lg;
$title--size-2xl__sub-title_line-height: $line-height-lg-computed;
$title--size-2xl__icon_size: grid-type-unit(16);
$title--size-2xl__icon_font-size: 28px;
$title--size-2xl__icon_offset-h: grid-unit(2);
$title--size-2xl__icon_offset-v: (
        $title--size-2xl__icon_size -
            ($title--size-2xl__title_line-height + $title--size-2xl__sub-title_line-height)
    ) * 0.5;

/* themes */
$title--theme-on-light-blue__title_color: $color-heading;
$title--theme-on-light-blue__icon_background: $color-blue-200;
$title--theme-on-light-blue__icon_color: $title--theme-on-light-blue__title_color;

$title--theme-on-dark-blue__title_color: $color-heading-on-dark;
$title--theme-on-dark-blue__sub-title_color: $color-text-on-dark;
$title--theme-on-dark-blue__icon_background: $color-dark-blue--emphasis;
$title--theme-on-dark-blue__icon_color: $title--theme-on-dark-blue__title_color;
