/*  ==========================================================================
    Divider
    ========================================================================== */

@mixin divider-size($margin: $divider-md-margin 0, $responsive-margin: false) {
    margin: $margin;

    @if $responsive-margin {
        @each $breakpoint-key, $breakpoint-margin in $responsive-margin {
            @include media-breakpoint-up($breakpoint-key) {
                margin: $breakpoint-margin;
            }
        }
    }
}

@mixin divider-color($color: $divider-color) {
    background-color: $color;
}
