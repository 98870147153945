/*  ==========================================================================
    Table
    ========================================================================== */

@mixin table__cell--editable__form-control($context: null) {
    @include context-styles($context) {
        .form-control {
            @extend %table__cell--editable_control;

            padding-right: $table--editable__cell_padding-horizontal;
            padding-left: $table--editable__cell_padding-horizontal;
            background-color: transparent;
        }
    }
}

@mixin table__cell--alignment($alignment: null) {
    @if ($alignment) {
        .form-control,
        .dp-picker-input {
            text-align: $alignment;
        }
    }
}

@mixin table__cell--editable__form-input-group__form-control($context: null) {
    .form-control {
        height: $form-input-group__form-control_height--size-md;
        padding-right: $form-input-group__form-control_padding-h;
        padding-left: $form-input-group__form-control_padding-h;
    }
}

@mixin table__cell--editable__form-input-group($context: null) {
    @include context-styles($context) {
        .form-input-group {
            @extend %table__cell--editable_control;

            padding-right: 0;
            padding-left: 0;

            &__addon {
                &--prepend {
                    &:first-child {
                        .form-input-group__addon-item {
                            margin-left: 0;
                        }
                    }
                }

                &--append {
                    // TODO: convert action menu to component and add this to host-context
                    .action-menu-wrapper {
                        opacity: 0;
                        transition: opacity $animate-duration-short $animation-easing;
                    }

                    &:last-child {
                        .form-input-group__addon-item {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        &:hover,
        &:focus-within {
            .form-input-group__addon--append {
                // TODO: convert action menu to component and add this to host-context
                .action-menu-wrapper {
                    opacity: 1;
                }
            }
        }
    }
}

@mixin table__cell--editable__datepicker($context: null) {
    @include context-styles($context) {
        .dp-input-container::after {
            display: none;
        }

        .dp-picker-input {
            @extend %table__cell--editable_control;

            min-width: $component-size-base;
            padding-right: $table--editable__cell_padding-horizontal;
            padding-left: $table--editable__cell_padding-horizontal;
        }
    }
}

@mixin table__cell--editable__select($context: null) {
    @include context-styles($context) {
        select.form-control {
            padding-right: $table--editable__cell_padding-horizontal;
            background-image: none;
        }
    }
}

@mixin table__cell--editable__combobox($context: null) {
    @include context-styles($context) {
        .combobox {
            width: 100%;

            .ng-select-container {
                padding-top: $form-control-padding-v--size-md;
                padding-bottom: $form-control-padding-v--size-md;
            }
        }
    }
}

@mixin table__cell--editable__radio($context: null) {
    @include context-styles($context) {
        .radio {
            .form-control-label {
                @extend %table__cell--editable_options;
            }

            .input-box {
                margin-right: $table__radio-checkbox_margin;
            }
        }
    }
}

@mixin table__cell--editable__checkbox($context: null) {
    @include context-styles($context) {
        .checkbox {
            .form-control-label {
                @extend %table__cell--editable_options;
            }

            .input-box {
                margin-right: $table__radio-checkbox_margin;
            }
        }
    }
}

@mixin table__cell--header-editable__checkbox($context: null) {
    @include context-styles($context) {
        .checkbox {
            .form-control-label {
                @extend %table__cell--header-editable_options;
            }
        }
    }
}

@mixin table__row--highlight(
    $context: &,
    $highlight: $table__cell--highlight_color,
    $highlight-selected: $table__cell--highlight_color-selected
) {
    background-color: $highlight;
    transition: all $animate-duration-short ease;

    #{$context}__cell {
        background-color: transparent;
        color: $color-selected;
        transition: all $animate-duration-short ease;

        &--highlight {
            background-color: $highlight-selected;
        }

        &:first-child {
            background-color: $highlight;
        }
    }
}

/*  Density
    ========================================================================== */

@mixin table__cell--editable__form-control-density($padding-h, $padding-v, $context: null) {
    @include context-styles($context) {
        .form-control {
            padding: $padding-v $padding-h;
        }
    }
}

@mixin table__cell--editable__form-input-group__form-control-density(
    $cell-padding-h,
    $cell-padding-v,
    $control-padding-h,
    $context: null
) {
    @include context-styles($context) {
        .form-control {
            padding: $cell-padding-v $control-padding-h $cell-padding-v $control-padding-h;

            &:first-child:not(:last-child) {
                padding-left: $cell-padding-h;
            }

            &:last-child:not(:first-child) {
                padding-right: $cell-padding-h;
            }
        }

        .form-input-group__control {
            &:first-child {
                padding-left: $control-padding-h;
            }

            &:last-child {
                padding-right: $control-padding-h;
            }
        }
    }
}

@mixin table__cell--editable__datepicker-density($padding-h, $padding-v, $min-height, $context: null) {
    @include context-styles($context) {
        .dp-picker-input {
            min-height: $min-height;
            padding: $padding-v $padding-h;
        }
    }
}

@mixin table__cell--editable__radio-checkbox-density($padding-h, $context: null) {
    @include context-styles($context) {
        .radio,
        .checkbox {
            .form-control-label {
                padding-block: $padding-h;
            }
        }
    }
}
