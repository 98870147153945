/*  ==========================================================================
    Muscle number component
    ========================================================================== */

$muscle-number_inner-spacing: grid-unit();

/*  Main
    ========================================================================== */

$muscle-number__main_min-height: grid-unit(5);
$muscle-number__main_inner-spacing-horizontal: grid-unit();

/* Value */
$muscle-number__value_font-weight: $font-weight-bold;
$muscle-number__value_color: $color-accent-dark;
$muscle-number__value--error: $color-danger;

/* Metadata */
$muscle-number__metadata_offset: grid-type-unit();

/* Indicator */
$muscle-number__indicator_offset: grid-type-unit();
$muscle-number__indicator_font-size: $icon-font-size-sm;
$muscle-number__indicator_color: $color-text-soft;

/* Unit */
$muscle-number__unit_font-size: $font-size-sm;

/*  Footer
    ========================================================================== */

$muscle-number__description_font-size: $font-size-sm;
$muscle-number__description_color: $color-text-soft;

/*  Modifiers
    ========================================================================== */

/* Boxed */
$muscle-number--boxed_inner-spacing: grid-unit(2);
$muscle-number--boxed_border-width: $border-width-light;
$muscle-number--boxed_border-radius: $border-radius;
$muscle-number--boxed_border-color: $color-divider;
$muscle-number--boxed_background: $color-white;

/* Empty */
$muscle-number--empty__description_font-weight: $font-weight-bold;
$muscle-number--empty__description_color: $color-danger;
