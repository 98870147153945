/*  ==========================================================================
    Status message
    ========================================================================== */

$status-message-max-width: $breakpoint-xs-mid;
$status-message-padding: grid-unit(2) 0;

/* Footer */
$status-message-cta-margin: grid-unit();

/* Visual */
$status-message-image-margin: grid-unit(2);

/* Icon */
$status-message-icon-offset: grid-unit(1);

/*  Status colors */
$status-message-color-info: $color-info;
$status-message-color-success: $color-success;
$status-message-color-warning: $color-warning;
$status-message-color-danger: $color-danger;

/*  Local
    ========================================================================== */

$status-message-local-main-margin: grid-unit();
$status-message-local-footer-margin: grid-unit(3);

/*  Global
    ========================================================================== */

$status-message-global-padding: grid-unit(3) grid-unit(2);
$status-message-global-main-margin: grid-unit(2);
$status-message-global-footer-margin: grid-unit(4);
$status-message-global-margin-xs-mid: grid-unit(6);
$status-message-global-margin-sm-mid: grid-unit(12);
$status-message-global-margin-md: grid-unit(18);
