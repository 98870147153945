/*  ==========================================================================
    Tabs
    ========================================================================== */

$tab-list__link_border-width: $border-width-regular;
$tab-list__link_border-radius: $border-radius-small * 0.5;
$tab-list__link_color: $color-text-soft;
$tab-list__link_font-weight: $font-weight-semibold;

/* Size */
$tab-list--size-sm__item_font-size: $font-size-sm;
$tab-list--size-sm__item_line-height: $tab-list--size-sm__item_font-size;
$tab-list--size-sm__link_padding-v: (
        $component-size-sm - ($tab-list--size-sm__item_line-height + $tab-list__link_border-width)
    ) * 0.5;
$tab-list--size-sm__link_padding: $tab-list--size-sm__link_padding-v 0 $tab-list--size-sm__link_padding-v;

$tab-list--size-md__link_padding: grid-unit() 0 (grid-unit() - $tab-list__link_border-width);
$tab-list--size-md__overflow-item_padding-bottom: 6px;

/* States */
$tab-list__link-hover_color: $color-blue-700;
$tab-list__link-pressed_color: $color-pressed-text;
$tab-list__link-selected_color: $tab-list__link-pressed_color;

$tab-list__link_border-color: $color-border;
$tab-list__link-hover_border-color: $color-blue-300;
$tab-list__link-pressed_border-color: $color-blue-700;
$tab-list__link-selected_border-color: $tab-list__link-hover_color;

/* Theme States */
$tab-list--on-dark-blue__link_color: $color-text-soft-on-dark;
$tab-list--on-dark-blue__link-hover_color: $color-white;
$tab-list--on-dark-blue__link-pressed_color: $color-white;
$tab-list--on-dark-blue__link-selected_color: $tab-list--on-dark-blue__link-pressed_color;

$tab-list--on-dark-blue__link_border-color: transparent;
$tab-list--on-dark-blue__link-hover_border-color: $color-blue-500;
$tab-list--on-dark-blue__link-pressed_border-color: $color-white;
$tab-list--on-dark-blue__link-selected_border-color: $tab-list--on-dark-blue__link-pressed_border-color;

$tab-list--on-light-blue__link_border-color: $color-blue-300;
$tab-list--on-light-blue__link-hover_border-color: $color-blue-400;
$tab-list--on-light-blue__link-pressed_border-color: $color-blue-700;
$tab-list--on-light-blue__link-selected_border-color: $tab-list--on-light-blue__link-pressed_border-color;

/* Modifiers */
$tab-list--dense-sm__link_offset: grid-unit(2);
$tab-list--dense-md__link_offset: grid-unit(3);

/*  Legacy
    ========================================================================== */
$tab-list__link_padding: $tab-list--size-md__link_padding;
