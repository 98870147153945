/*  ==========================================================================
    Tooltip Container
    ========================================================================== */

.tooltip-container {
    position: relative;
    display: inline-block;

    /*  States
        ========================================================================== */

    &--hover {
        &:hover {
            .tooltip {
                @extend %tooltip-is-visible;
            }
        }
    }

    @include focus-visible() {
        &,
        &:focus,
        &:focus-within {
            .tooltip {
                @extend %tooltip-is-visible;
            }
        }
    }
}
