/*  ==========================================================================
    Form Input group
    ========================================================================== */

.form-input-group {
    $block: &;

    display: flex;
    align-items: center;
    width: 100%;
    border: $form-control-border-w solid $form-control-border-color;
    border-radius: $border-radius;
    background-color: $form-control-bg;

    /*  Layout
        ========================================================================== */

    &__addon {
        z-index: $z-index-above;
        display: flex;
        flex: 0 0 auto;
        white-space: nowrap;
        color: $color-text-soft;

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;

            &--text {
                min-width: auto;
            }

            &--icon {
                margin-right: 0;
                margin-left: 0;
            }

            /*  Addon item size
                ========================================================================== */

            &--size-sm {
                &#{$block}__addon-item {
                    &--text {
                        font-size: $font-size-sm;
                    }

                    &--icon {
                        font-size: $icon-font-size-xs;
                    }
                }
            }

            /*  Contextual
                ========================================================================== */

            &.btn {
                margin-right: 0;
                margin-left: 0;
            }
        }

        &--prepend &-item {
            &:last-child {
                margin-right: 0;
                margin-left: 0;

                &#{$block}__addon-item--icon {
                    margin-right: -$form-input-group__addon-item_margin-h;
                }

                /*  Contextual
                    ========================================================================== */

                &.btn {
                    &:not(.btn-icon) {
                        padding-left: $form-input-group__addon-item_margin-h;
                    }
                }

                &.btn-icon {
                    margin-right: -$form-input-group__addon-item_margin-h;
                }
            }
        }

        &--append &-item {
            &:first-child {
                margin-right: 0;
                margin-left: 0;

                &#{$block}__addon-item--icon {
                    margin-left: -$form-input-group__form-control_padding-h;
                }

                /*  Contextual
                    ========================================================================== */

                &.btn {
                    &:not(.btn-icon) {
                        padding-right: $form-input-group__addon-item_margin-h;
                    }
                }

                &.btn-icon {
                    margin-left: -$form-input-group__form-control_padding-h;
                }
            }
        }
    }

    /*  Contextual
        ========================================================================== */

    .btn {
        @include focus-visible {
            box-shadow: none;
        }
    }

    .form-control {
        flex: 1 1 auto;
        width: 100%;
        min-width: 0; // Fix flexbox overflow bug in edge
        border: 0;
        background-color: transparent;

        /*  Sizes
        ========================================================================== */

        //TODO: to be refactored to '&-size-md' when all apps use angular form components
        &,
        &--size-md {
            height: $form-input-group__form-control_height--size-md;
            padding: $form-input-group__form-control_padding-v $form-input-group__form-control_padding-h;
            line-height: $form-input-group__form-control_height--size-md; // Prevent jumpy inputs on Chrome
        }

        &--size-sm {
            height: $form-input-group__form-control_height--size-sm;
            padding: $form-input-group__form-control_padding-v--size-sm
                $form-input-group__form-control_padding-h;
            line-height: $form-input-group__form-control_height--size-sm; // Prevent jumpy inputs on Chrome
        }

        //TODO: find a way to put these styles this in form control
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px $form-control-bg inset;
            -webkit-text-fill-color: $color-text-soft;

            &:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    /*  Sizes
    ========================================================================== */

    &,
    &--size-md {
        //TODO: to be refactored to '&-size-md' when all apps use angular form components
        #{$block}__addon-item {
            min-width: $form-control-height--size-md;
            min-height: $form-control-height--size-md;
            margin: $form-input-group__addon-item_margin-v--size-md
                $form-input-group__addon-item_margin-h--size-md;
        }

        > .form-control {
            &:first-child {
                padding-left: $form-control-padding-h--size-md;
            }

            &:last-child {
                padding-right: $form-control-padding-h--size-md;
            }
        }
    }

    &--size-sm {
        #{$block}__addon-item {
            min-width: $form-control-height--size-sm;
            min-height: $form-control-height--size-sm;
            margin: $form-input-group__addon-item_margin-v--size-sm
                $form-input-group__addon-item_margin-h--size-sm;
        }

        > .form-control {
            &:first-child {
                padding-left: $form-control-padding-h--size-sm;
            }

            &:last-child {
                padding-right: $form-control-padding-h--size-sm;
            }
        }
    }

    /*  States
        ========================================================================== */

    &.is-disabled {
        border-color: $form-control-disabled-border-color;
        background-color: $form-control-disabled-bg;

        #{$block} {
            &__addon,
            &__addon-item {
                &:not(.btn) {
                    color: color-disabled($form-input-group__addon_color);
                }
            }
        }
    }

    &.is-readonly {
        border-color: $form-control-readonly-border-color;
        background-color: $form-control-readonly-bg-color;
        color: $form-control-readonly-color;

        ::placeholder {
            color: $form-control-readonly-placeholder-color;
        }

        #{$block} {
            &__addon,
            &__addon-item {
                &:not(.btn) {
                    color: $form-input-group__addon_color;
                }
            }
        }
    }

    /*  Theme
        ========================================================================== */

    @include theme(light-blue) {
        &#{$block} {
            @extend %form-control--theme-on-light-blue;

            &.is-disabled {
                border-color: color-disabled($form-control-on-blue-border-color);
            }
        }
    }

    @include theme(dark-blue) {
        &#{$block} {
            @extend %form-control--theme-on-dark-blue;

            &:focus-within {
                border-color: $color-focus-on-dark;
            }

            &.is-disabled {
                border-color: color-disabled($form-control--theme-dark-blue_border-color);
            }

            &.is-readonly {
                background-color: $form-control--theme-dark-blue_readonly-bg-color;
                color: $form-control--theme-dark-blue_readonly-color;

                ::placeholder {
                    color: $form-control--theme-dark-blue_readonly-placeholder-color;
                }

                #{$block} {
                    &__addon,
                    &__addon-item {
                        &:not(.btn) {
                            color: $form-control--theme-dark-blue_readonly-color;
                        }
                    }
                }
            }

            #{$block} {
                &__addon {
                    color: $color-text-soft-on-dark;
                }
            }

            .form-control {
                background-color: transparent;

                //TODO: find a way to put these styles this in form control
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 1000px $form-control--theme-dark-blue_bg inset;
                    -webkit-text-fill-color: $form-control--theme-dark-blue_color;
                }
            }
        }
    }

    /*  States
        ========================================================================== */

    &:hover {
        border-color: $form-control-hover-border-color;
    }

    &:focus-within {
        border-color: $color-focus;
    }

    &--on-interaction {
        #{$block}__addon--on-interaction {
            opacity: 0;
            transition: opacity $animate-duration-short $animate-duration-short linear;
        }

        &:hover,
        &:focus-within {
            #{$block}__addon--on-interaction {
                opacity: 1;
            }
        }
    }
}
