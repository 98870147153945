/*  ==========================================================================
    Muscle number component
    ========================================================================== */

.muscle-number {
    /*  Header
        ========================================================================== */

    .muscle-number__header {
        margin-bottom: $muscle-number_inner-spacing;
    }

    .muscle-number__label {
        @extend %heading-3;
    }

    /*  Main
        ========================================================================== */

    .muscle-number__main {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        min-height: $muscle-number__main_min-height;

        + .muscle-number__footer {
            margin-top: $muscle-number_inner-spacing;
        }
    }

    .muscle-number__value {
        @include word-wrap;

        flex: 0 1 auto;
        margin-right: $muscle-number__main_inner-spacing-horizontal;
        font-size: $muscle-number__main_min-height;
        font-weight: $muscle-number__value_font-weight;
        line-height: 1;
        color: $muscle-number__value_color;
    }

    .muscle-number__metadata {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        margin-bottom: $muscle-number__metadata_offset;
    }

    .muscle-number__indicator {
        @extend .fas;

        flex: 0 0 auto;
        margin-bottom: $muscle-number__indicator_offset;
        font-size: $muscle-number__indicator_font-size;
        color: $muscle-number__indicator_color;

        &--direction-up {
            @extend .fa-arrow-circle-up;
        }

        &--direction-down {
            @extend .fa-arrow-circle-down;
        }
    }

    .muscle-number__unit {
        flex: 0 0 auto;
        font-size: $muscle-number__unit_font-size;
        line-height: 1;
    }

    /*  Footer
        ========================================================================== */

    .muscle-number__description {
        font-size: $muscle-number__description_font-size;
        color: $muscle-number__description_color;

        &--status-error {
            color: $muscle-number__value--error;
        }
    }

    /*  Category
        Modifiers based on color categories
        ========================================================================== */

    &--category-green-blue {
        @include muscle-number-color-category('green-blue');
    }

    &--category-blue {
        @include muscle-number-color-category('blue');
    }

    &--category-orange {
        @include muscle-number-color-category('orange');
    }

    &--category-purple {
        @include muscle-number-color-category('purple');
    }

    &--category-green {
        @include muscle-number-color-category('green');
    }

    &--category-red {
        @include muscle-number-color-category('red');
    }

    &--category-black {
        @include muscle-number-color-category('black');
    }

    /*  Legacy code: Muscle number modifiers
        TODO: wrap in deprecation mixin
        ========================================================================== */

    /* Status
       Styling on error status */

    &__value {
        &--status-error {
            color: $muscle-number__value--error;
        }
    }

    /*  Boxed
        Visual box around the muscle number */

    &--boxed {
        padding: $muscle-number--boxed_inner-spacing;
        border: $muscle-number--boxed_border-width solid $muscle-number--boxed_border-color;
        border-radius: $muscle-number--boxed_border-radius;
        background: $muscle-number--boxed_background;
    }

    /*  Empty
        State for when data cannot be retrieved */

    &--empty {
        .muscle-number__description {
            font-weight: $muscle-number--empty__description_font-weight;
            color: $muscle-number--empty__description_color;
        }
    }
}
