/*  ==========================================================================
    Code
    ========================================================================== */

$code-bg: inherit;
$code-color: $color-source-code;
$kbd-bg: $color-black-900;
$kbd-color: $color-white;
$pre-bg: $color-black-100;
$pre-color: $color-text-soft;
$pre-border-width: 1px;
$pre-border-color: $color-black-100;
$pre-scrollable-max-height: $breakpoint-xs;
