/*  ==========================================================================
    Dialog

    .dialog-backdrop  - class for backdrop element
    .dialog-open      - body class for killing the scroll
    .dialog           - Container
    .dialog-inner     - positioning shell for the actual dialog
    .dialog-content   - actual dialog content with main content styles
    ========================================================================== */

@mixin dialog-footer--stacked {
    .btn {
        /**
        * Reset styling inherited from btn-block class
        */

        &-block {
            display: inherit;
            width: auto;
        }
    }

    @include media-breakpoint-down(xs-mid) {
        .btn {
            width: 100%;

            &:not(:first-child) {
                margin-top: $dialog-footer-button-margin;
            }
        }
    }

    @include media-breakpoint-up(xs-mid) {
        display: flex;
        flex-direction: row-reverse;

        .btn {
            flex: 0 1 auto;

            &:not(:last-child) {
                margin-left: $dialog-footer-button-margin;
            }

            &-justify-left {
                min-width: auto;
                margin-right: auto;
            }
        }
    }
}

.dialog {
    $block: &;

    --dialog-content-spacing: #{$dialog-content-spacing};

    position: fixed;
    z-index: $z-index-dialog;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;

    &-inner {
        display: flex;
        max-width: calc(100% - #{$dialog-content-margin * 2});
        max-height: calc(100% - #{$dialog-content-margin * 2});
        margin: $dialog-content-margin;

        /* Fix for iOS */
        > * {
            @include media-breakpoint-up(xs-mid, height) {
                display: flex;
                width: 100%;
            }
        }
    }

    /*  Content wrapper
        ========================================================================== */

    &-content {
        @include panel(global);

        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
        border-radius: $border-radius-small;
        transition:
            opacity $animate-duration-short ease-in,
            transform $animate-duration-medium ease-in;
    }

    /*  Header
        ========================================================================== */

    &-header {
        flex: 0 0 auto;
        padding: var(--dialog-content-spacing) var(--dialog-content-spacing) 0 var(--dialog-content-spacing);

        @include theme(dark-blue) {
            padding-bottom: var(--dialog-content-spacing);
            background-color: $color-dark-blue;

            @include media-breakpoint-property-combo(sm, min, width, sm, min, height) {
                padding-bottom: var(--dialog-content-spacing);
            }
        }
    }

    /*  Media
        ========================================================================== */

    &-media {
        overflow: hidden;

        &-trigger-close {
            position: absolute;
            top: $dialog-media-trigger-offset-margin;
            right: $dialog-media-trigger-offset-margin;
            background: $color-white;
        }
    }

    /*  Body
        ========================================================================== */

    &-message {
        flex: 1 1 auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin: (calc(var(--dialog-content-spacing) * 0.5)) 0 var(--dialog-content-spacing) 0;
        padding: 0 var(--dialog-content-spacing);
    }

    /*  Footer
        ========================================================================== */

    &-footer {
        display: flex;
        flex: 0 0 auto;
        padding: 0 var(--dialog-content-spacing) var(--dialog-content-spacing) var(--dialog-content-spacing);

        &-start,
        &-end {
            display: flex;
            align-items: center;
        }

        &-start {
            flex: 0 1 auto;
        }

        &-end {
            flex: 1 1 auto;
            justify-content: flex-end;
        }

        /*  Footer layout
        ========================================================================== */

        &--stacked {
            @include media-breakpoint-down(xs-mid) {
                flex-direction: column;
            }

            .dialog-buttons {
                @include dialog-footer--stacked;
            }

            .dialog-footer-start {
                @include media-breakpoint-down(xs-mid) {
                    margin-bottom: $dialog-footer-inner-margin;
                }
            }

            .dialog-footer-start + .dialog-footer-end {
                @include media-breakpoint-up(xs-mid) {
                    margin-left: $dialog-footer-inner-margin;
                }
            }
        }

        &--inline {
            .dialog-buttons {
                display: flex;
                justify-content: flex-end;

                .btn + .btn {
                    margin-left: $dialog-footer-button-margin;
                }
            }
        }
    }

    /*  Overlay
        ========================================================================== */

    &-overlay {
        position: absolute;
        z-index: $z-index-above;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $dialog-overlay-bg;
    }

    /*  Backdrop
        ========================================================================== */

    &-backdrop {
        position: fixed;
        z-index: $z-index-dialog-background;
        background: $dialog-backdrop-bg;
        pointer-events: none;
        transition: opacity $animate-duration-medium $animate-duration-short ease-in;
        inset: 0;

        /* Fade for backdrop */
        &.fade {
            opacity: 0;
        }

        &.in {
            opacity: 1;
            transition: opacity $animate-duration-medium ease-out;
        }
    }

    /*  Enhanced styling
       ========================================================================== */

    @include media-breakpoint-property-combo(sm, min, width, sm, min, height) {
        --dialog-content-spacing: #{$dialog-content-spacing-sm};

        &-inner {
            max-width: calc(100% - #{$dialog-content-margin-lg * 2});
            max-height: calc(100% - #{$dialog-content-margin-lg * 2});
            margin: $dialog-content-margin-lg;
        }

        &-header {
            padding: var(--dialog-content-spacing) var(--dialog-content-spacing) 0
                var(--dialog-content-spacing);
        }

        &-message {
            margin: (calc(var(--dialog-content-spacing) * 0.5)) 0 var(--dialog-content-spacing) 0;
            padding: 0 var(--dialog-content-spacing);
        }

        &-footer {
            padding: 0 var(--dialog-content-spacing) var(--dialog-content-spacing)
                var(--dialog-content-spacing);
        }

        /* Types */
        &-fixed {
            .dialog-inner {
                max-width: calc(100% - #{$dialog-content-margin-lg * 2});
            }
        }

        &-fullscreen {
            #{$block}-inner {
                width: calc(100% - #{$dialog-content-margin-lg * 2});
                height: calc(100% - #{$dialog-content-margin-lg * 2});
                margin: $dialog-content-margin-lg;
            }

            #{$block}-content {
                border-radius: $border-radius-small;
            }
        }
    }

    /*  Animation
        ========================================================================== */

    &.fade {
        #{$block}-content {
            opacity: 0;
            transform: scale(0.8);
        }
    }

    &.in {
        #{$block}-content {
            opacity: 1;
            transform: scale(1);
            transition:
                opacity $animate-duration-short $animate-duration-short ease-out,
                transform $animate-duration-medium $animate-duration-short ease-out;
        }
    }

    /*  State
        ========================================================================== */

    /* Kill the scroll on the body */
    &-open {
        overflow: hidden;

        &.is-ios {
            position: fixed;
        }
    }

    /*  Types
        ========================================================================== */

    &-fixed {
        #{$block}-content {
            width: $dialog-fixed-content-width;
        }
    }

    &-fullscreen {
        width: 100%;

        #{$block} {
            &-inner {
                width: calc(100% - #{$dialog-content-margin * 2});
                height: calc(100% - #{$dialog-content-margin * 2});
            }

            &-content {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }
    }

    &--spacing {
        &-sm {
            @include media-breakpoint-property-combo(sm, min, width, sm, min, height) {
                --dialog-content-spacing: #{$dialog-content-spacing-sm};
            }
        }

        &-md {
            @include media-breakpoint-property-combo(md, min, width, sm, min, height) {
                --dialog-content-spacing: #{$dialog-content-spacing-md};
            }
        }

        &-lg {
            @include media-breakpoint-property-combo(lg, min, width, sm, min, height) {
                --dialog-content-spacing: #{$dialog-content-spacing-lg};
            }
        }
    }

    /*  Alignment
        ========================================================================== */

    &-top {
        justify-content: flex-start;
    }

    &-right {
        align-items: flex-end;
    }

    &-bottom {
        justify-content: flex-end;
    }

    &-left {
        align-items: flex-start;
    }

    /*  Switch overflow from dialog-message to dialog on small height
        ========================================================================== */

    @media screen and (max-height: $dialog-switch-overflow-height) {
        flex-direction: row;
        align-items: flex-start;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &-inner,
        &-content {
            // override any height set inline
            height: inherit !important;
            max-height: inherit;
        }

        &-message {
            flex-shrink: 0;
        }

        /* Overlay */
        &.has-overlay {
            overflow: hidden;

            #{$block}-inner {
                max-height: 100%;
            }
        }
    }

    /*  Custom margin
        ========================================================================== */

    &-no-margin {
        #{$block}-inner {
            max-width: 100%;
            max-height: 100%;
            margin: 0;
        }

        &#{$block}-fullscreen {
            #{$block}-inner {
                width: 100%;
                height: 100%;
            }
        }
    }

    /*  Legacy
        ========================================================================== */

    &-content > &-buttons {
        @include dialog-footer--stacked;

        flex: 0 0 auto;
        padding: 0 var(--dialog-content-spacing) var(--dialog-content-spacing) var(--dialog-content-spacing);

        @include media-breakpoint-property-combo(sm, min, width, sm, min, height) {
            padding: 0 var(--dialog-content-spacing) var(--dialog-content-spacing)
                var(--dialog-content-spacing);
        }

        @include media-breakpoint-down(xs-mid) {
            flex-direction: column;
        }
    }
}
