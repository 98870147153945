/*  ==========================================================================
    Tooltip
    ========================================================================== */

.tooltip {
    $block: &;

    @include panel(local, ondarkblue);

    position: absolute;
    z-index: $z-index-tooltip;
    display: inline-block;
    width: $tooltip-max-width;
    width: max-content;
    min-width: $tooltip-min-width;
    max-width: $tooltip-max-width;
    min-height: $tooltip-height;
    padding: $tooltip-padding;
    font-size: $tooltip-font-size;
    line-height: $tooltip-line-height;
    opacity: 0;
    visibility: hidden;
    transition: all $animate-duration-short $animate-duration-short $animation-easing;

    /*  State
        ========================================================================== */

    &.is-visible {
        @extend %tooltip-is-visible;
    }

    /*  Direction
        ========================================================================== */

    &--top,
    &--bottom {
        left: 50%;
    }

    &--top {
        @include pointer('bottom', $tooltip-arrow-arguments...);

        bottom: $tooltip-position;
        transform: translate(-50%, $tooltip-position-offset);
    }

    &--bottom {
        @include pointer('top', $tooltip-arrow-arguments...);

        top: $tooltip-position;
        transform: translate(-50%, -$tooltip-position-offset);
    }

    &--left,
    &--right {
        top: 50%;
        width: $tooltip-max-width-horizontal;
        width: max-content;
        max-width: $tooltip-max-width-horizontal;
    }

    &--left {
        @include pointer('right', $tooltip-arrow-arguments...);

        right: $tooltip-position;
        transform: translate($tooltip-position-offset, -50%);
    }

    &--right {
        @include pointer('left', $tooltip-arrow-arguments...);

        left: $tooltip-position;
        transform: translate(-$tooltip-position-offset, -50%);
    }
}
