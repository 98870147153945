/*  ==========================================================================
    Icon
    ========================================================================== */

/*  General variables
    ========================================================================== */
$icon-font-path: $font-path;

/* icon size modifier compared to corresponding font size */
$icon-size-modifier-xs: 1px;
$icon-size-modifier-sm: 2px;
$icon-size-modifier-md: grid-type-unit();
$icon-size-modifier-lg: grid-type-unit(2);

/* icon font sizes */
$icon-font-size-xxs: $font-size-xs; // 10px
$icon-font-size-xs: $font-size-xs + $icon-size-modifier-xs; // 12px
$icon-font-size-sm: $font-size-sm + $icon-size-modifier-xs; // 14px
$icon-font-size-md: $font-size-md + $icon-size-modifier-sm; // 16px
$icon-font-size-lg: $font-size-lg + $icon-size-modifier-md; // 20px
$icon-font-size-xl: $font-size-xl + $icon-size-modifier-md; // 24px
$icon-font-size-2xl: $font-size-2xl + $icon-size-modifier-md; // 28px
$icon-font-size-3xl: $font-size-3xl + $icon-size-modifier-lg; // 40px
$icon-font-size-4xl: $font-size-4xl + $icon-size-modifier-lg; // 48px

$icon-font-size-base: $icon-font-size-md; // 16px
