/*  ==========================================================================
    Radio
    ========================================================================== */

.radio {
    @include radio-checkbox-custom;

    input {
        + .input-box {
            border-radius: $border-radius-circle;
        }

        &:checked {
            + .input-box {
                border-style: solid;
            }
        }
    }

    /*  Size
    ========================================================================== */

    //TODO: to be refactored to '&-size-md' when all apps use angular form components
    &,
    &--size-md {
        @include radio-checkbox-custom--size-md;

        input {
            &:checked {
                + .input-box {
                    border-width: $radio-checked-border-width-size-md;
                }
            }
        }
    }

    &--size-sm {
        @include radio-checkbox-custom--size-sm;

        input {
            &:checked {
                + .input-box {
                    border-width: $radio-checked-border-width-size-sm;
                }
            }
        }
    }

    /* Theme
    ========================================================================== */

    &,
    &--theme-on-white,
    .has-theme-white & {
        @include radio-checkbox-custom--theme-on-white;

        input {
            &:checked {
                + .input-box {
                    border-color: $radio-checkbox-hover-border-color;
                }

                @include focus-visible {
                    + .input-box {
                        border-color: $color-focus;
                    }
                }
            }
        }

        &.is-disabled,
        &.disabled {
            input {
                &:checked {
                    + .input-box {
                        border-color: $radio-disabled-checked-border-color;
                    }
                }
            }

            &:hover {
                .input-box {
                    border-color: $radio-checkbox--theme-dark-blue_disabled-border-color;
                }
            }
        }
    }

    @include theme(light-blue) {
        @include radio-checkbox-custom--theme-on-light-blue;
    }

    @include theme(dark-blue) {
        @include radio-checkbox-custom--theme-on-dark-blue;

        input {
            &:checked {
                + .input-box {
                    border-color: $radio-checkbox--theme-dark-blue_checked-bg-color;
                    background-color: $radio-checkbox--theme-dark-blue_checked-color;

                    @at-root {
                        .is-disabled#{&},
                        .disabled#{&} {
                            border-color: $radio-checkbox--theme-dark-blue_disabled-checked-bg-color;
                            background-color: $radio-checkbox--theme-dark-blue_disabled-checked-color;
                        }
                    }
                }

                @include focus-visible {
                    + .input-box {
                        border-color: $color-focus-on-dark;
                    }
                }
            }
        }
    }
}

.form-control-label,
.form-control-help {
    /* first checkbox/radio is not the first html element in a form-group if a control-label is used */
    + .radio {
        @include radio-checkbox-custom__form-group--first;
    }
}
