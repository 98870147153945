/*  ==========================================================================
    Dialog
    ========================================================================== */

/**
 * Target the dialog-content wrapper
 *
 * Angular renders a component wrapper.
 * This changes the direct child, affecting the structure and thus styling.
 */
@mixin target-dialog-content-wrapper($target: both) {
    @warn "This mixin has been deprecated; `.dialog-content` wrapper should be added to your custom dialog implementation(s)";
}
