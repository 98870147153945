/*  ==========================================================================
    Status circle

    Colored circle for indication purposes
    ========================================================================== */

.status-circle {
    $block: &;

    display: inline-block;
    width: $status-circle-size;
    height: $status-circle-size;
    border: $status-circle-border-width solid currentcolor;
    border-radius: $border-radius-circle;
    vertical-align: baseline;
    transform: scale(1);
    transition:
        all $animate-duration ease,
        transform $animate-duration cubic-bezier(0.3, 0.27, 0.07, 1.64);

    /*  Visibility
        ========================================================================== */

    &-invisible {
        transform: scale(0);
        transition:
            all $animate-duration-short ease,
            transform $animate-duration-short ease-in;
    }

    /*  Size
        ========================================================================== */

    &-md {
        width: $status-circle-size-md;
        height: $status-circle-size-md;
        border-width: $status-circle-border-width-md;
    }

    &-lg {
        width: $status-circle-size-lg;
        height: $status-circle-size-lg;
        border-width: $status-circle-border-width-lg;
    }

    /*  Status
        ========================================================================== */

    &-success {
        @include status-circle--state-change(success);

        background-color: $color-success;
        color: $color-success;
    }

    &-info {
        @include status-circle--state-change(info);

        background-color: $color-info;
        color: $color-info;
    }

    &-warning {
        @include status-circle--state-change(warning);

        background-color: transparent;
        color: $color-warning;

        &.status-circle-disabled {
            background-color: inherit;
        }
    }

    &-danger {
        @include status-circle--state-change(danger);

        background-color: $color-danger;
        color: $color-danger;
    }

    &-disabled {
        @include status-circle--state-change(disabled);

        background-color: $color-black-100;
        color: $color-black-100;
    }

    /*  Theme
        ========================================================================== */

    @include theme('white', false) {
        &#{$block} {
            &-warning {
                background-color: $color-white;
            }
        }
    }

    @include theme('light-blue', false) {
        &#{$block} {
            &-warning {
                background-color: $color-blue-100;
            }
        }
    }

    @include theme('dark-blue', false) {
        &#{$block} {
            &-warning {
                background-color: $color-blue-800;
            }
        }
    }
}
