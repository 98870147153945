/*  ==========================================================================
    Plain text

    Styling & formatting of plain text content
    ========================================================================== */

.plain-text {
    @include plain-text-spacing();

    &.text-sm {
        @include plain-text-spacing(sm);
    }

    h1,
    .h1 {
        @extend %heading-1;
    }

    h2,
    .h2 {
        @extend %heading-2;
    }

    h3,
    .h3 {
        @extend %heading-3;
    }

    h4,
    .h4 {
        @extend %heading-4;
    }

    h5,
    .h5 {
        @extend %heading-5;
    }

    h6,
    .h6 {
        @extend %heading-6;
    }

    a {
        @extend %text-link;
    }

    p {
        line-height: $line-height-md-extra;
    }

    ol,
    ul {
        @extend %list;
    }

    li {
        display: list-item;
    }
}
