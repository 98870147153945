/*  ==========================================================================
    Content section
    ========================================================================== */

.content-section {
    $block: &;

    /*  Centered
       ========================================================================== */

    &--centered {
        > #{$block}__inner {
            margin: 0 auto;
        }
    }

    /*  Max width
        ========================================================================== */

    @each $key, $value in $breakpoints {
        &--max-width-#{$key} {
            > #{$block}__inner {
                max-width: $value;
            }
        }
    }

    /*  Full width background
        ========================================================================== */

    &--full-width {
        margin-right: -$content-padding-horizontal;
        margin-left: -$content-padding-horizontal;
        padding-right: $content-padding-horizontal;
        padding-left: $content-padding-horizontal;

        @include media-breakpoint-up(sm) {
            margin-right: -$content-padding-horizontal-sm;
            margin-left: -$content-padding-horizontal-sm;
            padding-right: $content-padding-horizontal-sm;
            padding-left: $content-padding-horizontal-sm;
        }

        @include media-breakpoint-up(md) {
            margin-right: -$content-padding-horizontal-md;
            margin-left: -$content-padding-horizontal-md;
            padding-right: $content-padding-horizontal-md;
            padding-left: $content-padding-horizontal-md;
        }
    }
}
