@mixin card--size($size, $padding-v, $padding-h: $padding-v) {
    $block: &;

    @include panel--size($size, $padding-v, $padding-h) {
        &-image {
            + #{$block} {
                &-body {
                    padding-top: $padding-v;
                }
            }
        }
    }
}
