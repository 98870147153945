/*  Normalize form elements

    While most of our form styles require extra classes, some basic normalization
    is required to ensure optimum display with or without those classes to better
    address browser inconsistencies.
    ========================================================================== */

fieldset {
    /* Chrome and Firefox set a `min-width: min-content;` on fieldsets,
     * so we reset that to ensure it behaves more like a standard block element.
     * See https://github.com/twbs/bootstrap/issues/12359. */
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;

    &.last-child,
    &:last-child {
        border-bottom: 0;
    }
}

legend {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: inherit;
}

// Position radios and checkboxes better
input[type='radio'],
input[type='checkbox'] {
    margin: grid-type-unit() 0 0;
    line-height: normal;
}

input[type='file'] {
    display: block;
    max-width: 100%;
}

// Remove webkit number spinners
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

// Make range inputs behave like textual form controls
input[type='range'] {
    display: block;
    width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
    height: auto;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    display: block;
    appearance: textfield;
}

@include media-breakpoint-down(xs) {
    // Remove Input Shadows on iPad
    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='password'] {
        appearance: none;
    }
}

// Prevent iOS devices from zooming in.
// Occurs when font-size is less than 16px
input,
textarea,
select {
    @include target-ios {
        font-size: $font-size-lg;
        -webkit-text-fill-color: $color-text; // override IOS native form input color
    }
}

// Adjust output element
output {
    display: block;
    padding-top: grid-type-unit() + 1;
    font-size: $font-size-base;
    line-height: $line-height-base;
}

/*  Search inputs in iOS

    This overrides the extra rounded corners on search inputs in iOS so that our
    `.form-control` class can properly style them. Note that this cannot simply
    be added to `.form-control` as it's not specific enough. For details, see
    https://github.com/twbs/bootstrap/issues/11586.
    ========================================================================== */

input[type='search'] {
    -webkit-appearance: none;
}
