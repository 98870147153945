/*  ==========================================================================
    Form
    ========================================================================== */

.form {
    .form-control {
        width: $form-control-width;
    }
}

.form-title {
    @extend %heading-1;

    margin: 0 0 $form-title-margin;
}

.form-intro {
    margin: 0;
}
