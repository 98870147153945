/*  ==========================================================================
    Loader
    ========================================================================== */

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $loader_padding;

    &__footer {
        margin-top: $loader__footer_margin;
        color: $color-text-soft;
    }

    &--absolute-center {
        @extend %absolute-center;
    }
}
