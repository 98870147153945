/*  ==========================================================================
    Description list
    ========================================================================== */

$description-list-item-label-color: $color-text-soft;
$description-list-item-value-font-weight: $font-weight-semibold;

/*  Layout
    ========================================================================== */
$description-list-inline-item-children-offset-density-md: grid-unit();
$description-list-inline-item-children-offset-density-sm: grid-type-unit();

/*  Direction
    ========================================================================== */

/* vertical */
$description-list-vertical-stacked-item-spacing-density-md: grid-unit(2);
$description-list-vertical-inline-item-spacing-density-md: grid-unit(2);
$description-list-vertical-stacked-item-spacing-density-sm: grid-unit(1);
$description-list-vertical-inline-item-spacing-density-sm: grid-unit(1);

/* horizontal */
$description-list-horizontal-stacked-item-spacing-density-md: grid-unit(4);
$description-list-horizontal-inline-item-spacing-density-md: grid-unit(6);
$description-list-horizontal-stacked-item-spacing-density-sm: grid-unit(2);
$description-list-horizontal-inline-item-spacing-density-sm: grid-unit(3);

/* grid */
$description-list-grid-stacked-item-column-spacing-density-md: grid-unit(4);
$description-list-grid-stacked-item-row-spacing-density-md: grid-unit(2);
$description-list-grid-inline-item-column-spacing-density-md: grid-unit(3);
$description-list-grid-inline-item-row-spacing-density-md: grid-unit(2);
$description-list-grid-stacked-item-column-spacing-density-sm: grid-unit(2);
$description-list-grid-stacked-item-row-spacing-density-sm: grid-unit(1);
$description-list-grid-inline-item-column-spacing-density-sm: grid-type-unit(3);
$description-list-grid-inline-item-row-spacing-density-sm: grid-unit(1);

/*  Theme - dark blue
    ========================================================================== */
$description-list-on-dark-blue-text-color: $color-white;
$description-list-on-dark-blue-label-text-color: $color-text-soft-on-dark;

$description-list-modifiers: (
    'vertical': 'vertical',
    'horizontal': 'horizontal',
    'stacked': 'stacked',
    'inline': 'inline',
    'grid': 'grid',
    'density-sm': 'density-sm',
    'density-md': 'density-md',
);
