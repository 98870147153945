/*  ==========================================================================
    Triggers

    Styling for interaction areas
    ========================================================================== */

.trigger {
    @extend %trigger;
    @extend %trigger-layout-fixed;
}

.touch-target {
    @extend %touch-target;
}
