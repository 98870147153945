/*  ==========================================================================
    Breakpoint viewport sizes and media queries.
    ========================================================================== */

/*  Utility functions

    Prepare breakpoint logic for mixins
    Global variable $breakpoints is used to define the actual breakpoints
    ========================================================================== */

/*  Return previous breakpoint name
    Returns 'null' for the smallest (first) breakpoint */
@function breakpoint-previous(
    $name,
    $breakpoints-map: $breakpoints,
    $breakpoint-names: map-keys($breakpoints)
) {
    $n: index($breakpoint-names, $name);

    @return if($n <= length($breakpoint-names) and $n > 2, nth($breakpoint-names, $n - 1), null);
}

/*  Return next breakpoint name
    Returns 'null' for the smallest (first) breakpoint */
@function breakpoint-next($name, $breakpoints-map: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);

    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

/*  Return minimum breakpoint width,
    Returns 'null' for the smallest (first) breakpoint */
@function breakpoint-min($name, $breakpoints-map: $breakpoints, $use-next-breakpoint-for-max: true) {
    $min: null;

    @if ($use-next-breakpoint-for-max) {
        $min: map-get($breakpoints-map, $name);
    } @else {
        $previous: breakpoint-previous($name, $breakpoints);
        $min: map-get($breakpoints-map, $previous);
    }

    @return if($min != 0, $min, null);
}

/*  Return maximum breakpoint width,
    Returns 'null' for the largest (last) breakpoint */
@function breakpoint-max($name, $breakpoints-map: $breakpoints, $use-next-breakpoint-for-max: true) {
    $max: null;

    // mid breakpoints should not be used as next breakpoint
    // if $next name contains 'mid' skip to next breakpoint
    // eg: if $name is 'xs', @next should be 'sm'
    @if ($use-next-breakpoint-for-max) {
        $next: breakpoint-next($name, $breakpoints-map);

        /*  Mid breakpoints, should only be used if explicitly defined, they are optional extra breakpoints.
            If $next name contains 'mid' skip to next breakpoint
            eg: if $name is 'xs', @next should be 'sm'.
            */
        @if (string-contains($next, '-mid')) {
            $next: breakpoint-next($next, $breakpoints-map);
        }

        $max: $next;
    } @else {
        @if (map-get($breakpoints-map, $name) != 0) {
            $max: $name;
        }
    }

    @return if($max, breakpoint-min($max, $breakpoints-map) - $breakpoint-max-modifier, null);
}

/*  Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
    Useful for making responsive utilities. */
@function breakpoint-infix($name, $breakpoints-map: $breakpoints) {
    @return if(breakpoint-min($name, $breakpoints-map) == null, '', '-#{$name}');
}

/*  Mixins
    ========================================================================== */

/// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
/// @group media-queries
/// @param {string} $name - breakpoint name (eg. xs)
/// @param {string} $property [width] - @media property to use (eg. width)
/// @param {map} $breakpoints [$breakpoints] - breakpoint map to use
/// @output
/// @content Makes the @content apply to the given breakpoint and wider.
/// @example
///     @include media-breakpoint-up(xs) {
///         display: none;
///     }
@mixin media-breakpoint-up($name, $property: width, $breakpoints-map: $breakpoints) {
    $min: breakpoint-min($name, $breakpoints-map);

    @if $min {
        @media only screen and (min-#{$property}: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

/// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
/// @group media-queries
/// @param {string} $name - breakpoint name (eg. xs)
/// @param {string} $property [width] - @media property to use (eg. width)
/// @param {map} $breakpoints [$breakpoints] - breakpoint map to use
/// @output
/// @content Makes the @content apply to the given breakpoint and narrower.
/// @example
///     @include media-breakpoint-down(xs) {
///         display: none;
///     }
@mixin media-breakpoint-down($name, $property: width, $breakpoints-map: $breakpoints) {
    /* TODO: refactor this logic into breakpoint-max mixin */
    $use-next-breakpoint-for-max: true;

    @if (string-contains($name, '-mid')) {
        $use-next-breakpoint-for-max: false;
    }

    $max: breakpoint-max($name, $breakpoints-map, $use-next-breakpoint-for-max);

    @if $max {
        @media only screen and (max-#{$property}: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

/// Media that spans multiple breakpoint widths.
/// @group media-queries
/// @param {string} $lower - from breakpoint name (eg. xs)
/// @param {string} $upper - to breakpoint name (eg. sm)
/// @param {string} $property [width] - @media property to use (eg. width)
/// @param {map} $breakpoints [$breakpoints] - breakpoint map to use
/// @output
/// @content Makes the @content apply between the min and max breakpoints
/// @example
///     @include media-breakpoint-between(xs, sm) {
///         display: none;
///     }
@mixin media-breakpoint-between($lower, $upper, $property: width, $breakpoints-map: $breakpoints) {
    $min: breakpoint-min($lower, $breakpoints-map);

    /* TODO: refactor this logic into breakpoint-max mixin */
    $use-next-breakpoint-for-max: true;

    @if (string-contains($upper, '-mid')) {
        $use-next-breakpoint-for-max: false;
    }
    $max: breakpoint-max($upper, $breakpoints-map, $use-next-breakpoint-for-max);

    @if $min != null and $max != null {
        @media only screen and (min-#{$property}: $min) and (max-#{$property}: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($lower, $breakpoints-map) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($upper, $breakpoints-map) {
            @content;
        }
    }
}

/// Media between the breakpoint's minimum and maximum widths.
/// No minimum for the smallest breakpoint, and no maximum for the largest one.
/// @group media-queries
/// @param {string} $name - breakpoint name (eg. xs)
/// @param {string} $property [width] - @media property to use (eg. width)
/// @param {boolean} $use-next-breakpoint-for-max [true] - @media property to use (eg. width)
/// @param {map} $breakpoints [$breakpoints] - breakpoint map to use
/// @output
/// @content Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
/// @example
///     // Applies to xs-mid to sm
///     @include media-breakpoint-only(xs-mid) {
///         display: none;
///     }
/// @example
///     // Applies to xs-mid to xs
///     @include media-breakpoint-only(xs-mid, width, false) {
///         display: none;
///     }
@mixin media-breakpoint-only(
    $name,
    $property: width,
    $use-next-breakpoint-for-max: true,
    $breakpoints-map: $breakpoints
) {
    $min: breakpoint-min($name, $breakpoints-map, $use-next-breakpoint-for-max);
    $max: breakpoint-max($name, $breakpoints-map, $use-next-breakpoint-for-max);

    @if $min != null and $max != null {
        @media only screen and (min-#{$property}: $min) and (max-#{$property}: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($name, $breakpoints-map) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($name, $breakpoints-map) {
            @content;
        }
    }
}

/// Media that sets a combination of width and height in combination with min and/or max properties.
/// @group media-queries
/// @param {string} $first-name - first breakpoint name (eg. xs)
/// @param {string} $first-direction - @media direction to use (min or max)
/// @param {string} $first-property - @media property to use (eg. width)
/// @param {string} $second-name - first breakpoint name (eg. xs)
/// @param {string} $second-direction - @media direction to use (min or max)
/// @param {string} $second-property - @media property to use (width or height)
/// @param {map} $breakpoints [$breakpoints] - breakpoint map to use
/// @output
/// @content Makes the @content apply for min/max height/width combination.
/// @example
///     @include media-breakpoint-property-combo(xs, min, width, xs, max, height) {
///         display: none;
///     }
@mixin media-breakpoint-property-combo(
    $first-name,
    $first-direction,
    $first-property,
    $second-name,
    $second-direction,
    $second-property,
    $breakpoints-map: $breakpoints
) {
    $first-value: null;

    @if ($first-direction == min) {
        $first-value: breakpoint-min($first-name, $breakpoints-map);
    } @else {
        /* TODO: refactor this logic into breakpoint-max mixin */
        $use-next-breakpoint-for-max: true;

        @if (string-contains($first-name, '-mid')) {
            $use-next-breakpoint-for-max: false;
        }
        $first-value: breakpoint-max($first-name, $breakpoints-map, $use-next-breakpoint-for-max);
    }

    $second-value: null;

    @if ($second-direction == min) {
        $second-value: breakpoint-min($second-name, $breakpoints-map);
    } @else {
        /* TODO: refactor this logic into breakpoint-max mixin */
        $use-next-breakpoint-for-max: true;

        @if (string-contains($second-name, '-mid')) {
            $use-next-breakpoint-for-max: false;
        }
        $second-value: breakpoint-max($second-name, $breakpoints-map, $use-next-breakpoint-for-max);
    }

    @if $first-value != null and $second-value != null {
        @media only screen and (#{$first-direction}-#{$first-property}: $first-value) and (#{$second-direction}-#{$second-property}: $second-value) {
            @content;
        }
    } @else if $first-value == null {
        @warn "#{$first-name} is not a valid breakpoint";
    } @else if $second-value == null {
        @warn "#{$second-name} is not a valid breakpoint";
    } @else {
        @warn "#{$first-name} and #{$second-name} are not a valid breakpoints";
    }
}
