/*  ==========================================================================
    Breadcrumbs
    ========================================================================== */

.breadcrumbs {
    $block: &;

    &__list {
        display: flex;
        align-items: baseline;
    }

    &__item {
        display: inline-flex;
        align-items: baseline;
        white-space: nowrap;

        &:not(:last-child) {
            margin-right: $breadcrumbs__item_margin-horizontal;
        }

        &--offcanvas {
            position: fixed;
            top: -100%;
            right: -100%;
        }
    }

    &__separator {
        display: inline-block;
        margin-left: $breadcrumbs__item_margin-horizontal;
        line-height: $btn-line-height;
        color: $color-black-300;

        &--overflow {
            margin-left: 0;
        }
    }

    /*  Contextual
        ========================================================================== */

    &__link {
        min-width: auto;
        text-align: left;

        &.is-readonly {
            padding-right: 0;
            padding-left: 0;
            color: $color-text-soft;
            cursor: default;
        }
    }

    .breadcrumbs__overflow-menu {
        display: inline-flex;
    }

    /*  Themes
        ========================================================================== */

    @include theme('dark-blue') {
        #{$block} {
            &__separator {
                color: $color-blue-500;
            }

            &__link {
                &.btn {
                    color: $color-text-soft-on-dark;
                }

                &.is-readonly {
                    color: $color-heading-on-dark;
                }
            }
        }
    }
}
