/*  ==========================================================================
    Pagination
    ========================================================================== */

$pagination--size-sm_spacing: grid-unit(1);
$pagination--size-md_spacing: grid-unit(2);

/*  List
    ========================================================================== */

$pagination--size-sm__list_spacing: grid-type-unit(1);
$pagination--size-md__list_spacing: grid-type-unit(2);
