/*  ==========================================================================
    Status message
    ========================================================================== */

/* Status */
@mixin status-message-status-style($text-color) {
    .status-message-title-wrapper {
        color: $text-color;
    }
}
