/*  ==========================================================================
    Content view
    ========================================================================== */

.content-view {
    .view-group {
        display: flex;
        align-items: center;

        + .view-group {
            margin-left: grid-unit(2);
        }
    }
}
