/*  ==========================================================================
    Layout

    #root
        #notification-bar
        #notification-toasters
        #layout-wrapper
            #header-global
            #navigation-primary
            #main-content-wrapper
                #main-content
                    #content-header                 [CONDITIONAL]
                        #sidebar-local-left-toggle  [CONDITIONAL] [XS-DOWN]
                        #page-title
                        #content-header-actions     [CONDITIONAL] [SM-DOWN]
                        #sidebar-local-right-toggle [CONDITIONAL] [XS-DOWN]
                    #content-notifications
                    #content-view-filter            [CONDITIONAL]
                    #content-view-switcher          [CONDITIONAL]
                    #content-wrapper
                        #content
                            #content-tabs           [CONDITIONAL]
                            [APP CONTENT]
                        #content-sidebar            [CONDITIONAL]
                            #content-header-actions [CONDITIONAL] [MD-UP]
                #sidebar-local-left                 [CONDITIONAL]
                    #navigation-secondary
                #sidebar-local-right                [CONDITIONAL]
                    .toolbar                        [CONDITIONAL]
                    .inner
            #footer-global                          [CONDITIONAL]
        #sidebar-global-left                [CONDITIONAL]
            .sidebar-global
        #sidebar-global-right               [CONDITIONAL]
            .sidebar-global
    ========================================================================== */

/* base */
@import 'base/layout-base';

/* content */
@import 'content/layout-content';
@import 'content/layout-content-header';
@import 'content/layout-content-notifications';
@import 'content/layout-content-sidebar';
@import 'content/layout-content-view';
@import 'content/layout-content-view-filter';
@import 'content/layout-content-view-switcher';
@import 'content/layout-content-tabs';
@import 'content/layout-content-actions';
@import 'content/layout-content-section';

/* footer */
@import 'footer/layout-footer';

/* notifications */
@import 'notifications/layout-notification-bar';
