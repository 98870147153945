/*  ==========================================================================
    Layout
    ========================================================================== */

/*  Cluster
    ========================================================================== */

.cluster {
    @extend %cluster;
}

/*  Positioning
    ========================================================================== */

.position-top-left {
    inset: 0 auto auto 0;
}

.position-top-right {
    inset: 0 0 auto auto;
}

.position-bottom-right {
    inset: auto 0 0 auto;
}

.position-bottom-left {
    inset: auto auto 0 0;
}

/*  Absolute positioned
    ========================================================================== */

.absolute-container {
    position: relative;
}

.absolute {
    &-center {
        @extend %absolute-center;
    }

    &-top {
        @extend %absolute-top;
    }

    &-top-half {
        @extend %absolute-top-half;
    }

    &-right {
        @extend %absolute-right;
    }

    &-right-half {
        @extend %absolute-right-half;
    }

    &-bottom {
        @extend %absolute-bottom;
    }

    &-bottom-half {
        @extend %absolute-bottom-half;
    }

    &-left {
        @extend %absolute-left;
    }

    &-left-half {
        @extend %absolute-left-half;
    }

    &-top-right {
        @extend %absolute-top-right;
    }

    &-top-left {
        @extend %absolute-top-left;
    }

    &-bottom-right {
        @extend %absolute-bottom-right;
    }

    &-bottom-left {
        @extend %absolute-bottom-left;
    }
}

/*  Aspect ratios
    ========================================================================== */

.ratio-1-1 {
    @include aspect-ratio(1, 1); /* 1:1 */
}

.ratio-2-1 {
    @include aspect-ratio(2, 1); /* 1:2 */
}

.ratio-1-2 {
    @include aspect-ratio(1, 2); /* 1:2 */
}

.ratio-5-7 {
    @include aspect-ratio(5, 7); /* 5:7 */
}

.ratio-3-4 {
    @include aspect-ratio(3, 4); /* 3:4 */
}

.ratio-16-9 {
    @include aspect-ratio(16, 9); /* 16:9 */
}

/*  Center block
    ========================================================================== */

.center-block {
    @extend %center-block;
}

/*  Float
    ========================================================================== */

.clearfix {
    @include clearfix;
}
