/*  ==========================================================================
    Content header
    ========================================================================== */

.content-header {
    $block: &;

    flex: 0 0 auto;
    margin: $content-header-margin;
    padding: $content-header-padding;

    @include media-breakpoint-up(sm) {
        padding: $content-header-padding-sm;
    }

    @include media-breakpoint-up(md) {
        padding: $content-header-padding-md;
    }

    &-inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include media-breakpoint-down(xs) {
            padding-right: $content-padding-horizontal;
            padding-left: $content-padding-horizontal;
        }
    }

    /*  Sections
        ========================================================================== */

    &-mid {
        margin-top: $content-header-mid-spacing-top;

        @include media-breakpoint-up(lg) {
            margin-top: $content-header-mid-spacing-top-lg;
        }
    }

    &-end {
        margin-top: $content-header-end-spacing-top;
        margin-bottom: $content-header-end-spacing-bottom;

        @include media-breakpoint-up(lg) {
            margin-top: $content-header-end-spacing-top-lg;
            margin-bottom: $content-header-end-spacing-bottom-lg;
        }
    }

    /*  Context
        ========================================================================== */

    .page-overview-trigger {
        margin-top: grid-type-unit(-1);
    }

    /*  Action menu toolbar
      ========================================================================== */

    &-toolbar {
        &-mobile {
            position: fixed;
            z-index: $z-index-fab;
            top: auto;
            right: 0;
            bottom: 0;
            margin: $content-actions-floating-offset-vertical $content-actions-floating-offset-horizontal-sm;

            .header-hidden & {
                top: auto; // Overwrite more specific css when header is hidden
            }

            /* Push actions up in case there is a primary navigation */
            .has-primary-nav & {
                transform: translateY(-$navigation-primary-fixed-height);
                transition:
                    transform $layout-animation-vertical-speed-out ease-in,
                    top $layout-animation-vertical-speed-out ease-in,
                    box-shadow $layout-animation-vertical-speed-out ease-in;
            }

            /* Bottom fixed primary navigation is currently using .header-hidden class to toggle it's visibility on scroll */
            .has-primary-nav.header-hidden & {
                transform: none;
                transition:
                    transform $layout-animation-vertical-speed-in ease-out,
                    top $layout-animation-vertical-speed-in ease-out,
                    box-shadow $layout-animation-vertical-speed-in ease-out;
            }

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &-desktop {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
                margin-left: $content-header-start-spacing-vertical-between;
            }
        }

        &-action {
            margin-right: $content-header-toolbar-action-spacing;
        }
    }

    /*  Hero modifier
        ========================================================================== */

    &--hero {
        @extend %hero-header-base-styling;

        margin-bottom: $content-header--hero_margin-bottom;

        .page-overview-trigger {
            @extend .btn-theme-on-dark-blue;
        }
    }

    /*  Hero offset
        ========================================================================== */

    &--hero-offset {
        margin-bottom: $content-header--hero-offset_margin-bottom;
        padding-bottom: $content-header--hero-offset_padding-bottom;
    }
}
