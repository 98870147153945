/*  ==========================================================================
    Navigation primary
    ========================================================================== */

/*  Top version
    ========================================================================== */

$navigation-primary-top-padding-top: 0;
$navigation-primary-top-padding-bottom: grid-unit();

/* Use top version as base height */
$navigation-primary-h: $min-touch-area + $navigation-primary-top-padding-top +
    $navigation-primary-top-padding-bottom;

/*  Fixed bottom version
    ========================================================================== */

$navigation-primary-fixed-height: grid-unit(6);

/* Item */
$navigation-primary-fixed-item-border-width: 4px;
