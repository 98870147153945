/*  ==========================================================================
    Spinner
    ========================================================================== */

$spinner-size: grid-unit(3);
$spinner-line-width: 2px;

$spinner-xs-size: grid-type-unit(3);
$spinner-sm-size: grid-unit(2);
$spinner-lg-size: grid-unit(5);
$spinner-lg-line-width: 3px;
