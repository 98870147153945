/*  ==========================================================================
    Action Menu
    ========================================================================== */

/*  Wrapper
    ========================================================================== */

.action-menu-wrapper {
    position: relative;
    display: inline-block;

    /* open state for action-menu */
    &.open > .action-menu {
        display: block;
    }

    /*  Width
        ========================================================================== */

    &-full-width {
        width: 100%;

        .action-menu {
            width: 100%;
        }
    }
}

/*  Menu
    ========================================================================== */

.action-menu {
    $block: &;

    @include panel();

    position: absolute;
    z-index: $z-index-action-menu;
    right: auto;
    left: 0;
    display: none;
    width: max-content;
    min-width: $action-menu-min-width;
    max-width: $action-menu-max-width;
    padding: $action-menu-padding;

    /* Actions > override button styles */
    #{$block}-link {
        display: flex;
        align-items: baseline;
        justify-content: left;
        width: 100%;
        font-size: $font-size-base;
        text-align: left;
    }

    /* Dividers */
    &-divider {
        overflow: hidden;
        height: $divider-height;
        margin: $action-menu-divider-margin;
        background-color: $color-black-50;
    }

    /*  Themes
    ========================================================================== */

    &--theme-on-dark-blue {
        background-color: $color-bg-dark;

        #{$block} {
            &-divider {
                background-color: $color-blue-700;
            }
        }
    }

    /* Should override normal dark blue theme styles */
    &--theme-header {
        background-color: $color-dark-blue--emphasis;
    }

    /*  Direction
    ========================================================================== */

    /* Down */
    &-top {
        #{$block} {
            bottom: 100%;
            margin-bottom: $action-menu-offset;
        }
    }

    /* Up */
    &-bottom {
        #{$block} {
            top: 100%;
            margin-top: $action-menu-offset;
        }
    }

    /*  Alignment
        ========================================================================== */

    /* Left */
    &-left {
        right: auto;
        left: 0;
    }

    /* Right */
    &-right {
        right: 0;
        left: auto;
    }

    /*  Legacy
        ========================================================================== */

    &-toggle {
        &:not(.btn) {
            @extend %trigger;
            @extend %trigger-layout-fixed;

            margin: 0;
            font-size: $icon-font-size-lg;
        }
    }

    &-item-icon {
        &#{$block} {
            width: $font-size-base;
            margin-right: $action-menu-link-icon-margin;
        }
    }

    &-link-legacy {
        /* Less specificity to keep button styles (legacy action-menu component supports all button importances) */
        border-radius: $border-radius;
        color: $color-text;

        &:hover {
            background: $action-menu-link-hover-bg;
            color: $action-menu-link-hover-color;
        }

        &:active {
            background: $action-menu-link-pressed-bg;
            color: $action-menu-link-pressed-color;
        }

        &[disabled],
        &.disabled {
            background: $action-menu-bg;
            color: color-disabled($color-text);
            cursor: default;
        }

        /* More specificity to override button styles */
        &#{$block} {
            padding: $action-menu-link-padding;
        }
    }
}
