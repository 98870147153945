/*  ==========================================================================
    Form Control Help
    ========================================================================== */

/*
    Placement immediately above a form control.
    Use for general help, formatting, or instructional text.
    ========================================================================== */

.form-control-help {
    display: block; // account for any element using help-block
    margin-top: $form-control-help-margin-top;
    margin-bottom: $form-control-help-margin-bottom;
    color: $form-control-help-color;
}
