/*  ==========================================================================
    Color utilities
    ========================================================================== */

/// Color properties used to generate utility classes
/// @group configuration
$color-utility-classes-properties: background-color, color !default;

/// Colors used to generate utility classes
/// @group configuration
$color-utility-classes-color-map: (
    'primary': $color-main,
    'success': $color-success,
    'info': $color-info,
    'warning': $color-warning,
    'danger': $color-danger,
    'white': $color-white,
) !default;

@each $prop in $color-utility-classes-properties {
    @each $color-type, $color-value in $color-utility-classes-color-map {
        .#{$prop}-#{$color-type} {
            #{$prop}: $color-value !important;
        }
    }
}

/* Set category color for property
   ========================================================================== */

/// Color properties used to generate color category utility classes
/// @group configuration
$color-category-classes-properties: (
    'background': background-color,
    'color': color,
    'fill': fill,
) !default;

@each $prop-key, $prop-value in $color-category-classes-properties {
    @each $category-key, $category-value in $color-categories {
        @each $type-key, $type-value in map-get($color-categories, $category-key) {
            .#{$prop-key}-color-category-#{$category-key}-#{$type-key} {
                #{$prop-value}: get-category-color($category-key, $type-key) !important;
            }
        }
    }
}
