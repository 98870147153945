/*  ==========================================================================
    Dialog
    ========================================================================== */

/* Structure */
$dialog-switch-overflow-height: grid-unit(55) - $breakpoint-max-modifier;

/* Content */
$dialog-content-bg: $color-white;
$dialog-content-spacing: grid-unit(3);
$dialog-content-spacing-sm: grid-unit(4);
$dialog-content-spacing-md: grid-unit(5);
$dialog-content-spacing-lg: grid-unit(7);
$dialog-content-margin: grid-unit();
$dialog-content-margin-lg: grid-unit(5);

/* Media */
$dialog-media-trigger-offset-margin: grid-unit();

/* Footer */
$dialog-footer-button-margin: grid-unit();
$dialog-footer-inner-margin: grid-unit();

/* Backdrop */
$dialog-backdrop-bg: rgba($color-overlay, $color-opacity-low);

/* Overlay */
$dialog-overlay-bg: rgba($color-white, $color-opacity-max);

/*  Types
    ========================================================================== */

/* Fixed */
$dialog-fixed-content-width: $breakpoint-xs-mid;
