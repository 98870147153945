/*  ==========================================================================
    Status badge
    ========================================================================== */

$badge-font-size: $font-size-sm;
$badge-line-height: $badge-font-size;
$badge-padding-h: ($line-height-computed - $badge-line-height - ($border-width-light * 2)) * 0.5;
$badge-padding-v: grid-type-unit(2);
$badge-padding: $badge-padding-h $badge-padding-v;
$badge-font-weight: $font-weight-regular;
