/*  ==========================================================================
    Forms
    ========================================================================== */

/*  Legacy form controls

    Replaced by new theming classes
    ========================================================================== */

.form-control {
    &.on-blue {
        @extend %form-control--theme-on-light-blue;
    }

    @at-root {
        .form {
            &.on-blue {
                .form-control {
                    @extend %form-control-on-blue;
                }
            }
        }
    }
}

.radio,
.checkbox {
    &.on-blue {
        @include radio-checkbox-custom--theme-on-light-blue;
    }

    @at-root {
        .form {
            &.on-blue {
                .radio,
                .checkbox {
                    @include radio-checkbox-custom--theme-on-light-blue;
                }
            }
        }
    }
}
