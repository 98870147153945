/*  ==========================================================================
    Grid
    ========================================================================== */

.template-grid {
    @include grid-base;

    @include media-breakpoint-up(xs-mid) {
        @include grid($grid-item-min-w);

        @supports (display: grid) {
            .grid-item {
                grid-column: auto;

                /* 2 x 1 */
                &.ratio-2x1 {
                    grid-column: span 2;
                }

                /* 1 x 2 */
                &.ratio-1x2 {
                    grid-row: span 2;
                    grid-column: span 1;
                }

                /* 2 x 2 */
                &.ratio-2x2 {
                    grid-row: span 2;
                    grid-column: span 2;
                }

                /* always full width */
                &.full-width {
                    grid-column: 1 / -1; // start at column 1 / span to the end of the grid (-1)
                }
            }
        }

        &.has-small-cards {
            @include grid($grid-item-small-min-w);
        }

        &.has-large-cards {
            @include grid($grid-item-large-min-w);
        }
    }
}
