/*  ==========================================================================
    Grid system

    Generate semantic grid columns in order to use as includes in css
    ========================================================================== */

/*  Baseline grid functions
    ========================================================================== */
/// Convert the amount of given grid units to unitless value
/// @group grid
/// @param {Number} $multiplier [1] - Amount of grid units
/// @require {variable} $grid-unit used as default variable
/// @return {String} Grid units converted to pixels
/// @example
///     margin-top: grid-unit(2);
@use 'sass:math';

@function grid-unit-value($multiplier: 1) {
    @return $grid-unit-value * $multiplier;
}

/// Convert the amount of given grid units to pixels
/// @group grid
/// @param {Number} $multiplier [1] - Amount of grid units
/// @require {variable} $grid-unit used as default variable
/// @return {String} Grid units converted to pixels
/// @example
///     margin-top: grid-unit(2);
@function grid-unit($multiplier: 1) {
    @if $multiplier == 0 {
        @return 0;
    } @else {
        @return $grid-unit * $multiplier;
    }
}

/// Convert the amount of given grid type units to pixels
/// @group grid
/// @param {Number} $multiplier [1] - Amount of grid units
/// @require {variable} $grid-type-unit used as default variable
/// @return {String} Grid type units converted to pixels
/// @example
///     margin-top: grid-type-unit(3);
@function grid-type-unit($multiplier: 1) {
    @if $multiplier == 0 {
        @return 0;
    } @else {
        @return $grid-type-unit * $multiplier;
    }
}

/*  Container mixin
    ========================================================================== */

@mixin make-container() {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: ($grid-gutter * 0.5);
    padding-left: ($grid-gutter * 0.5);
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

/*  Row mixin

    Make a wrapper for a series of columns
    ========================================================================== */
@mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: math.div($grid-gutter, -2);
    margin-left: math.div($grid-gutter, -2);
}

/*  Column mixins
    ========================================================================== */
@mixin make-col-ready() {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing
    padding-right: ($grid-gutter * 0.5);
    padding-left: ($grid-gutter * 0.5);
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage(math.div($size, $columns));
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: math.div($size, $columns);

    margin-left: if($num == 0, 0, percentage($num));
}
