/*  ==========================================================================
    Form Group Validation legacy
    ========================================================================== */

.validation-form-control {
    @extend .form-control-validation;

    .validation-item {
        @extend .form-control-validation__item;
    }

    .status-icon {
        @extend .form-control-validation__item-icon;
    }

    .validation-message {
        @extend .form-control-validation__item-message;
    }
}
