/*  ==========================================================================
    Box shadow
    ========================================================================== */

/* prettier-ignore */
$shadow-elevation-umbra-map: (
    0: (0 0 0 0),
    1: (0 2 1 -1),
    2: (0 3 1 -1),
    3: (0 3 3 -1),
    4: (0 2 4 0),
    5: (0 3 5 0),
    6: (0 3 5 0),
    7: (0 4 5 -2),
    8: (0 5 5 -2),
    9: (0 5 6 -2),
    10: (0 6 6 -2),
    11: (0 6 7 -3),
    12: (0 7 8 -3),
    13: (0 7 8 -3),
    14: (0 7 9 -3),
    15: (0 8 9 -4),
    16: (0 8 10 -4),
    17: (0 8 11 -4),
    18: (0 9 11 -4),
    19: (0 9 12 -5),
    20: (0 10 13 -5),
    21: (0 10 13 -5),
    22: (0 10 14 -5),
    23: (0 11 14 -6),
    24: (0 11 15 -6)
);

/* prettier-ignore */
$shadow-elevation-penumbra-map: (
    0: (0 0 0 0),
    1: (0 1 1 0),
    2: (0 2 2 1),
    3: (0 3 4 1),
    4: (0 4 5 1),
    5: (0 5 8 1),
    6: (0 6 10 1),
    7: (0 7 10 2),
    8: (0 8 10 2),
    9: (0 9 12 2),
    10: (0 10 14 2),
    11: (0 11 15 2),
    12: (0 12 17 3),
    13: (0 13 19 3),
    14: (0 14 21 3),
    15: (0 15 22 3),
    16: (0 16 24 3),
    17: (0 17 26 3),
    18: (0 18 28 3),
    19: (0 19 29 3),
    20: (0 20 31 4),
    21: (0 21 33 4),
    22: (0 22 35 4),
    23: (0 23 36 4),
    24: (0 24 38 4)
);

/* prettier-ignore */
$shadow-elevation-ambient-map: (
    0: (0 0 0 0),
    1: (0 1 3 0),
    2: (0 1 5 1),
    3: (0 1 8 1),
    4: (0 1 10 1),
    5: (0 1 14 1),
    6: (0 1 18 1),
    7: (0 2 16 2),
    8: (0 3 14 3),
    9: (0 3 16 3),
    10: (0 4 18 4),
    11: (0 4 20 4),
    12: (0 5 22 5),
    13: (0 5 24 5),
    14: (0 5 26 5),
    15: (0 6 28 6),
    16: (0 6 30 6),
    17: (0 6 32 6),
    18: (0 7 34 7),
    19: (0 7 36 7),
    20: (0 8 38 8),
    21: (0 8 40 8),
    22: (0 8 42 8),
    23: (0 9 44 9),
    24: (0 9 46 9)
);

$shadow-elevation-umbra-opacity: 0.09;
$shadow-elevation-penumbra-opacity: 0.06;
$shadow-elevation-ambient-opacity: 0.03;
$opacity-boost: 0;

/*  Elevation shadows
    ========================================================================== */

@function convert-elevation($level, $direction: bottom) {
    $cross-offset-raw: nth($level, 1);
    $main-offset-raw: nth($level, 2);
    $blur-raw: nth($level, 3);
    $spread-raw: nth($level, 4);

    $h-offset-raw: $cross-offset-raw;
    $v-offset-raw: $main-offset-raw;

    @if $direction == top {
        $h-offset-raw: $cross-offset-raw;
        $v-offset-raw: $main-offset-raw * -1;
    }

    @if $direction == right {
        $h-offset-raw: $main-offset-raw;
        $v-offset-raw: $cross-offset-raw;
    }

    @if $direction == left {
        $h-offset-raw: $main-offset-raw * -1;
        $v-offset-raw: $cross-offset-raw;
    }

    $h-offset: if($h-offset-raw == 0, $h-offset-raw, $h-offset-raw + px);
    $v-offset: if($v-offset-raw == 0, $v-offset-raw, $v-offset-raw + px);
    $blur: if($blur-raw == 0, $blur-raw, $blur-raw + px);
    $spread: if($spread-raw == 0, $spread-raw, $spread-raw + px);

    @return #{$h-offset $v-offset $blur $spread};
}

@mixin box-shadow(
    $level,
    $direction: bottom,
    $focus: false,
    $opacity-boost: 1,
    $shadow-color: $color-shadow,
    $important: null,
    $custom-border: false,
    $custom-border-color: $color-selected
) {
    @if type-of($level) != number or not unitless($level) {
        @error "$z-value must be a unitless number, but received "#{$level}"";
    }

    @if $level < 0 or $level > 24 {
        @error "$z-value must be between 0 and 24, but received "#{$level}"";
    }

    @if $important {
        $important: !important;
    }

    $umbra-level: convert-elevation(map-get($shadow-elevation-umbra-map, $level), $direction);
    $penumbra-level: convert-elevation(map-get($shadow-elevation-penumbra-map, $level), $direction);
    $ambient-level: convert-elevation(map-get($shadow-elevation-ambient-map, $level), $direction);

    $umbra-color: rgba($shadow-color, $shadow-elevation-umbra-opacity * $opacity-boost);
    $penumbra-color: rgba($shadow-color, $shadow-elevation-penumbra-opacity * $opacity-boost);
    $ambient-color: rgba($shadow-color, $shadow-elevation-ambient-opacity * $opacity-boost);

    @if ($focus == inset or $focus == 'inset') {
        box-shadow:
            inset 0 0 0 1px $color-focus,
            $umbra-level $umbra-color,
            $penumbra-level $penumbra-color,
            $ambient-level $ambient-color $important;
    } @else if ($focus == true) {
        box-shadow:
            0 0 0 1px $color-focus,
            $umbra-level $umbra-color,
            $penumbra-level $penumbra-color,
            $ambient-level $ambient-color $important;
    } @else if ($custom-border == inset or $custom-border == 'inset') {
        box-shadow:
            inset 0 0 0 1px $custom-border-color,
            $umbra-level $umbra-color,
            $penumbra-level $penumbra-color,
            $ambient-level $ambient-color $important;
    } @else if ($custom-border == true) {
        box-shadow:
            0 0 0 1px $custom-border-color,
            $umbra-level $umbra-color,
            $penumbra-level $penumbra-color,
            $ambient-level $ambient-color $important;
    } @else {
        box-shadow:
            $umbra-level $umbra-color,
            $penumbra-level $penumbra-color,
            $ambient-level $ambient-color $important;
    }
}
