/*  ==========================================================================
    Text
    ========================================================================== */

/*  Headings
    ========================================================================== */

.h1 {
    @extend %heading-1;
}

.h2 {
    @extend %heading-2;
}

.h3 {
    @extend %heading-3;
}

.h4 {
    @extend %heading-4;
}

.h5 {
    @extend %heading-5;
}

.h6 {
    @extend %heading-6;
}

/*  Links
    ========================================================================== */

.text-link {
    @extend %text-link;
}

/*  Lists
    ========================================================================== */

.is-list {
    @extend %list;

    > .is-list {
        @extend %list-nested;
    }

    > * {
        display: list-item;
    }
}
