/*  ==========================================================================
    Controls
    ========================================================================== */

.form-controls {
    display: flex;
    flex-direction: column;
    gap: $form-controls-spacing;
    width: 100%;

    &.is-inline {
        flex-direction: row;

        .form-control {
            width: auto;
        }
    }

    &.is-wrapped {
        flex-wrap: wrap;
    }

    &.has-options {
        gap: 0 ($form-controls-spacing * 2);
        margin-top: -$form-controls-options-stacked-spacing;
    }
}
