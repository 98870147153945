/*  ==========================================================================
    App link
    ========================================================================== */

$rich-link_padding: grid-type-unit(3);

$rich-link__icon-wrapper_offset-v: grid-type-unit(1);
$rich-link__icon-wrapper_spacing-h: grid-type-unit(3);
$rich-link__icon_width: grid-unit(6);
$rich-link__icon_font-size: $icon-font-size-2xl;
$rich-link__icon-color: $color-blue;
$rich-link__icon_frame-offet: 2.5px;
$rich-link__icon-app_max-width: grid-type-unit(9);

$rich-link__title_font-weight: $font-weight-bold;
$rich-link__title_line-height: $line-height-computed;
$rich-link__title_color: $color-text;
$rich-link__label-wrapper_margin: grid-type-unit(1);
$rich-link__label-spacing: grid-type-unit(1);
