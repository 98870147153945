/*  ==========================================================================
    Slider
    ========================================================================== */

$slider-size: grid-unit(3);
$slider-border-width: 3px;

/* structure */
$slider-base-size: 6px;
$slider-base-border-radius: 4px;
$slider-handle-size: 16px;
$slider-tooltip-border-width: 2px;
$slider-icon-size: 16px;
$slider-icon-gutter: 5px;

/* stepped slider */
$slider-stepped-border-width: 1px;
$slider-stepped-handle-draglines-size: grid-unit();
$slider-stepped-handle-draglines-width: 1px;
