/*  ==========================================================================
    Description list
    ========================================================================== */

@use 'sass:math';

.description-list {
    $block: &;

    &-item {
        @include custom-property(min-width, --description-list__item_min-width, auto);

        display: flex;

        &-label,
        &-value {
            flex: 0 0 auto;
        }

        &-label {
            color: $description-list-item-label-color;
        }

        &-value {
            font-weight: $description-list-item-value-font-weight;
        }
    }

    /*  Layout
        ========================================================================== */

    &-stacked #{$block}-item {
        flex-direction: column;
    }

    &-inline {
        #{$block}-item {
            &-label,
            &-value {
                width: 100% * 0.5;
                min-width: min-content;
            }
        }

        &-1-2 #{$block}-item {
            &-label {
                width: math.div(100% * 1, 3);
            }

            &-value {
                width: math.div(100% * 2, 3);
            }
        }

        &-2-1 #{$block}-item {
            &-label {
                width: math.div(100% * 2, 3);
            }

            &-value {
                width: math.div(100% * 1, 3);
            }
        }
    }

    /*  Direction
        ========================================================================== */

    &-horizontal {
        display: flex;
    }

    /*  Grid
        ========================================================================== */

    &-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(var(--description-list__item_min-width, auto), 1fr));
    }

    /*  Spacing
        ========================================================================== */

    &-density-md {
        @include description-list-set-label-spacing(
            $description-list-inline-item-children-offset-density-md,
            $description-list-inline-item-children-offset-density-md,
            $block
        );

        /* Direction */
        @include description-list-set-item-spacing(
            $description-list-vertical-stacked-item-spacing-density-md,
            $description-list-vertical-inline-item-spacing-density-md,
            $description-list-horizontal-stacked-item-spacing-density-md,
            $description-list-horizontal-inline-item-spacing-density-md,
            $block
        );

        /* Grid */
        @include description-list-set-grid-spacing(
            $description-list-grid-stacked-item-row-spacing-density-md,
            $description-list-grid-stacked-item-column-spacing-density-md,
            $description-list-grid-inline-item-row-spacing-density-md,
            $description-list-grid-inline-item-column-spacing-density-md,
            $block
        );
    }

    &-density-sm {
        @include description-list-set-label-spacing(
            $description-list-inline-item-children-offset-density-sm,
            $description-list-inline-item-children-offset-density-sm,
            $block
        );

        /* Direction */
        @include description-list-set-item-spacing(
            $description-list-vertical-stacked-item-spacing-density-sm,
            $description-list-vertical-inline-item-spacing-density-sm,
            $description-list-horizontal-stacked-item-spacing-density-sm,
            $description-list-horizontal-inline-item-spacing-density-sm,
            $block
        );

        /* Grid */
        @include description-list-set-grid-spacing(
            $description-list-grid-stacked-item-row-spacing-density-sm,
            $description-list-grid-stacked-item-column-spacing-density-sm,
            $description-list-grid-inline-item-row-spacing-density-sm,
            $description-list-grid-inline-item-column-spacing-density-sm,
            $block
        );
    }

    /*  Themes
        ========================================================================== */

    @include theme(dark-blue, false) {
        #{$block}-item {
            &-label {
                color: $description-list-on-dark-blue-label-text-color;
            }

            &-value {
                color: $description-list-on-dark-blue-text-color;
            }
        }
    }
}
