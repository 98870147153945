/*  ==========================================================================
    Color
    ========================================================================== */

/*  Get HSL value from color map
    ========================================================================== */
@function get-hsl($color-values, $scale) {
    $hue: map-get($color-values, hue);
    $saturation: map-get($color-values, saturation);
    $lightness: map-deep-get($color-values, lightness, $scale);
    $hsl: hsl($hue, $saturation, $lightness);

    @return $hsl;
}

/*  Disabled color
    set opacity variant of used color
    ========================================================================== */
@function color-disabled($color: $color-text) {
    @if type-of($color) != 'color' or ($color == transparent) {
        @error '#{$color} is not a valid color argument';
    } @else {
        @return rgba($color, $color-opacity-low);
    }
}

/*  Convert color to URL encoded string
    ========================================================================== */
@function encodecolor($string) {
    @if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote('#{$hex}');
    }
    $string: '%23' + $string;

    @return $string;
}

/*  Get Category Color from $color-categories map
    ========================================================================== */
@function get-category-color($category, $prop) {
    @if (map-has-key($color-categories, $category)) {
        @return map-deep-get($color-categories, $category, $prop);
    } @else {
        @error ""#{$category}" is not a valid color category";
    }
}

/*  Chart color
    ========================================================================== */

@mixin chart-color($name, $color) {
    .chart-color-#{$name} {
        background-color: $color;
        color: $color;
        fill: $color;
        stroke: $color;
    }
}
