/*  ==========================================================================
    App link
    ========================================================================== */

.rich-link {
    $block: &;

    @extend %trigger-style;

    display: flex;
    padding: $rich-link_padding;

    &__start {
        display: flex;
        flex: 0 0 auto;
        align-items: baseline;
    }

    &__icon {
        position: relative;
        display: flex;
        margin-top: $rich-link__icon-wrapper_offset-v;
        margin-right: $rich-link__icon-wrapper_spacing-h;

        &--type-svg {
            fill: $rich-link__icon-color;
        }

        &--type-font {
            font-size: $rich-link__icon_font-size;
            color: $rich-link__icon-color;
        }

        &--framed {
            margin-top: 0;
        }

        &-frame {
            display: flex;
            flex: 0 0 auto;
            width: $rich-link__icon_width;
        }

        &-app {
            &--framed {
                position: absolute;
                top: calc(50% - #{$rich-link__icon_frame-offet});
                left: 50%;
                max-width: $rich-link__icon-app_max-width;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__middle {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
    }

    &__title {
        font-weight: $rich-link__title_font-weight;
        line-height: $rich-link__title_line-height;
        color: $rich-link__title_color;
        transition: $animate-duration;
    }

    &__label {
        display: inline-block;
        margin-right: $rich-link__label-spacing;
        margin-bottom: $rich-link__label-spacing;

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-top: $rich-link__label-wrapper_margin;
            margin-bottom: -$rich-link__label-spacing;
        }
    }

    &:hover {
        #{$block} {
            &__title {
                color: $color-hover-text;
            }
        }
    }

    &:active,
    &.is-active {
        #{$block} {
            &__title {
                color: $color-pressed-text;
            }
        }
    }

    &.is-disabled {
        #{$block} {
            &__title {
                color: color-disabled($rich-link__title_color);
            }
        }
    }
}
