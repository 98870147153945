/*  ==========================================================================
    Table header
    ========================================================================== */

.table {
    &__header {
        color: $table__header_color;
    }

    &--has-dark-header .table__cell--header {
        background-color: $color-bg-dark;
        color: $color-white;
    }
}
