/*  ==========================================================================
    Plain text

    Styling & formatting of plain text content
    ========================================================================== */

@mixin plain-text-spacing($text-size: base) {
    $margin-h1: $plain-text-margin-h1;
    $margin-h2: $plain-text-margin-h2;
    $margin-h3: $plain-text-margin-h3;
    $margin-h4: $plain-text-margin-h4;
    $margin-h5: $plain-text-margin-h5;
    $margin-h6: $plain-text-margin-h6;
    $margin-bottom-heading: $plain-text-margin-bottom-heading;
    $margin-paragraph: $plain-text-margin-paragraph;
    $margin-list: $plain-text-margin-list;
    $margin-divider: $plain-text-margin-divider;

    @if $text-size == sm {
        $margin-h1: $plain-text-small-margin-h1;
        $margin-h2: $plain-text-small-margin-h2;
        $margin-h3: $plain-text-small-margin-h3;
        $margin-h4: $plain-text-small-margin-h4;
        $margin-h5: $plain-text-small-margin-h5;
        $margin-h6: $plain-text-small-margin-h6;
        $margin-bottom-heading: $plain-text-small-margin-bottom-heading;
        $margin-paragraph: $plain-text-small-margin-paragraph;
        $margin-list: $plain-text-small-margin-list;
        $margin-divider: $plain-text-small-margin-divider;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-bottom: $margin-bottom-heading;
    }

    h1,
    .h1 {
        margin-top: $margin-h1;
    }

    h2,
    .h2 {
        margin-top: $margin-h2;
    }

    h3,
    .h3 {
        margin-top: $margin-h3;
    }

    h4,
    .h4 {
        margin-top: $margin-h4;
    }

    h5,
    .h5 {
        margin-top: $margin-h5;
    }

    h6,
    .h6 {
        margin-top: $margin-h6;
    }

    p {
        margin: 0 0 $margin-paragraph;
    }

    ol,
    ul {
        margin: $margin-list 0 $margin-list 2px;

        ol,
        ul {
            @extend %list-nested;
        }
    }

    hr {
        margin: $margin-divider 0;
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}
