/*  ==========================================================================
    Sizes helper classes
    ========================================================================== */

/*  Icon dimensions
    ========================================================================== */

.icon-dimension-xs {
    width: $icon-font-size-xs !important;
    height: $icon-font-size-xs !important;
}

.icon-dimension-sm {
    width: $icon-font-size-sm !important;
    height: $icon-font-size-sm !important;
}

.icon-dimension-md {
    width: $icon-font-size-md !important;
    height: $icon-font-size-md !important;
}

.icon-dimension-lg {
    width: $icon-font-size-lg !important;
    height: $icon-font-size-lg !important;
}

.icon-dimension-xl {
    width: $icon-font-size-xl !important;
    height: $icon-font-size-xl !important;
}

.icon-dimension-2xl {
    width: $icon-font-size-2xl !important;
    height: $icon-font-size-2xl !important;
}

.icon-dimension-3xl {
    width: $icon-font-size-3xl !important;
    height: $icon-font-size-3xl !important;
}

.icon-dimension-4xl {
    width: $icon-font-size-4xl !important;
    height: $icon-font-size-4xl !important;
}
