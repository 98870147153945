/*  ==========================================================================
    Group
    ========================================================================== */

/*  Form groups
    ========================================================================== */

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    + .form-group {
        margin-top: $form-group-margin;
    }

    > .form-control-label {
        width: 100%;
    }

    /*  Theme
        ========================================================================== */

    @include theme(dark-blue) {
        .form-control-label {
            color: $color-text-on-dark;
        }

        .form-control-help {
            color: $color-text-soft-on-dark;
        }
    }
}
