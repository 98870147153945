/*  ==========================================================================
    Switch
    ========================================================================== */

.switch {
    $block: &;
    $input-sibling: + #{$block}-control;

    @include switch-size(
        $switch-height,
        $switch-size-offset,
        $switch-font-size,
        $switch-button-radius,
        $switch-handle-radius,
        $switch-handle-size,
        $switch-spacing,
        $switch-border-width
    );

    position: relative;

    /* Let developer control the width */
    display: inline-block;

    /*  Input
        ========================================================================== */

    /* off:hover */
    &:hover {
        #{$block}-button {
            background: $switch-button-bg-hover;
        }

        /* on:hover */
        input:checked {
            #{$input-sibling} {
                #{$block} {
                    &-button {
                        background: $switch-on-button-bg-hover;
                    }

                    &-handle {
                        color: $switch-on-handle-color-hover;
                    }
                }
            }
        }
    }

    /* off:pressed */
    &:active {
        #{$block} {
            &-button {
                background: $switch-on-button-bg-hover;
            }

            &-handle {
                color: $switch-on-handle-color-hover;
            }
        }

        /* on:pressed */
        input:checked {
            #{$input-sibling} {
                #{$block} {
                    &-button {
                        background: $switch-button-bg-hover;
                    }

                    &-handle {
                        color: $switch-handle-icon-color;
                    }
                }
            }
        }
    }

    input {
        @extend .sr-only;

        /*  State: on and off with hover, active, disabled, focus (10 states per theme)
            ========================================================================== */

        /* on */
        &:checked {
            #{$input-sibling} {
                #{$block} {
                    &-button {
                        background: $switch-on-button-bg;
                    }

                    &-handle {
                        @extend .fa-check;

                        color: $switch-on-handle-color;
                        transition:
                            left $animate-duration-short ease-out,
                            color $animate-duration-medium linear;
                    }

                    &-on-label {
                        visibility: visible;
                    }

                    &-off-label {
                        visibility: hidden;
                    }
                }
            }

            /* on:focus */
            @include focus-visible {
                #{$input-sibling} {
                    #{$block} {
                        &-button {
                            border-color: $switch-on-button-border-color-focus;
                            box-shadow: focus($color: $switch-on-button-shadow-color-focus);
                        }
                    }
                }
            }
        }

        /* off:disabled */
        &:disabled {
            #{$input-sibling} {
                cursor: $cursor-disabled;

                #{$block} {
                    &-button {
                        background: $switch-disabled-button-bg;
                    }

                    &-handle {
                        color: $switch-disabled-handle-color;
                    }

                    &-on-label,
                    &-off-label {
                        color: $switch-disabled-label-color;
                    }
                }
            }

            /* on:disabled */
            &:checked {
                #{$input-sibling} {
                    #{$block} {
                        &-button {
                            background: $switch-disabled-on-button-bg;
                        }

                        &-handle {
                            color: $switch-disabled-on-handle-color;
                        }
                    }
                }
            }
        }

        /* off:focus */
        @include focus-visible {
            #{$input-sibling} {
                #{$block}-button {
                    border-color: $switch-button-border-color-focus;
                }
            }
        }
    }

    /*  Control
        ========================================================================== */

    &-control {
        display: flex;
        box-sizing: content-box;
        font-weight: inherit;
        cursor: pointer;
        user-select: none;
    }

    /*  Button
        ========================================================================== */

    &-button {
        position: relative;
        flex: 0 0 auto;
        order: 1;
        overflow: hidden;
        margin-left: auto; // aligns to the right of it's wrapper
        text-align: center;
        background: $switch-button-bg;
        color: $switch-button-color;
        transition: $animate-duration-short ease-out;
    }

    /*  Handle
        ========================================================================== */

    &-handle {
        @extend .fas;
        @extend .fa-times;

        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        line-height: $switch-handle-size;
        background: $switch-handle-bg;
        color: $switch-handle-icon-color;
        user-select: none;
        transition:
            left $animate-duration-short ease-in,
            color $animate-duration-short linear;

        &::before {
            font-size: $switch-handle-icon-size;
        }
    }

    /*  Label: Labels outside switch > default state: off
        ========================================================================== */

    &-label {
        flex: 1 0 auto;
        overflow: hidden;
        height: inherit;
    }

    &-on-label,
    &-off-label {
        display: block;
    }

    &-on-label {
        visibility: hidden;
        transform: translateY(-100%);
    }

    &-off-label {
        visibility: visible;
    }

    /*  position */
    &-label-left {
        #{$block}-label {
            text-align: right;
        }
    }

    &-label-right {
        #{$block}-label {
            order: 2;
            text-align: left;
        }
    }

    /*  Themes
        ========================================================================== */

    @include theme(dark-blue, false) {
        /* off:hover */
        &:hover {
            #{$block}-button {
                background: $switch-on-dark-blue-button-bg-hover;
            }

            /* on:hover */
            input:checked {
                #{$input-sibling} {
                    #{$block} {
                        &-button {
                            background: $switch-on-dark-blue-on-button-bg-hover;
                        }

                        &-handle {
                            color: $switch-on-dark-blue-on-handle-color-hover;
                        }
                    }
                }
            }
        }

        /* off:pressed */
        &:active {
            #{$block} {
                &-button {
                    background: $switch-on-dark-blue-on-button-bg-hover;
                }

                &-handle {
                    color: $switch-on-dark-blue-on-handle-color;
                }
            }

            /* on:pressed */
            input:checked {
                #{$input-sibling} {
                    #{$block} {
                        &-button {
                            background: $switch-on-dark-blue-button-bg-hover;
                        }

                        &-handle {
                            color: $switch-on-dark-blue-handle-color;
                        }
                    }
                }
            }
        }

        input {
            /* on */
            &:checked {
                #{$input-sibling} {
                    #{$block} {
                        &-button {
                            background: $switch-on-dark-blue-on-button-bg;
                        }

                        &-handle {
                            background: $switch-on-dark-blue-on-handle-bg;
                            color: $switch-on-dark-blue-on-handle-color;
                        }
                    }
                }

                /* on:focus */
                @include focus-visible {
                    #{$input-sibling} {
                        #{$block}-button {
                            border-color: $switch-on-dark-blue-on-button-border-color-focus;
                            box-shadow: focus($color: $switch-on-dark-blue-on-button-shadow-color-focus);
                        }
                    }
                }
            }

            /* off:disabled */
            &:disabled {
                #{$input-sibling} {
                    #{$block} {
                        &-button {
                            background: $switch-on-dark-blue-disabled-button-bg;
                        }

                        &-handle {
                            color: $switch-on-dark-blue-disabled-handle-color;
                        }

                        &-on-label,
                        &-off-label {
                            color: $switch-on-dark-blue-disabled-label-color;
                        }
                    }
                }

                /* on:disabled */
                &:checked {
                    #{$input-sibling} {
                        #{$block} {
                            &-button {
                                background: $switch-on-dark-blue-disabled-on-button-bg;
                            }

                            &-handle {
                                color: $switch-on-dark-blue-disabled-on-handle-color;
                            }
                        }
                    }
                }
            }

            /* off:focus */
            @include focus-visible {
                #{$input-sibling} {
                    #{$block}-button {
                        border-color: $switch-on-dark-blue-button-border-color-focus;
                        box-shadow: focus($color: $switch-on-dark-blue-button-shadow-color-focus);
                    }
                }
            }
        }

        #{$block} {
            &-button {
                background: $switch-on-dark-blue-button-bg;
            }

            &-handle {
                background: $switch-on-dark-blue-handle-bg;
                color: $switch-on-dark-blue-handle-color;
            }

            &-on-label,
            &-off-label {
                color: $switch-on-dark-blue-label-color;
            }
        }
    }
}
