/*  ==========================================================================
    Button group

    TODO: deprecate the legacy button-group html and remove these styles
    ========================================================================== */

.btn-group {
    $block: &;

    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .btn {
        flex: 0 0 auto;
    }
}

/*  Horizontal
    ========================================================================== */

.btn-group-horizontal {
    flex-direction: row;

    .btn-group {
        flex-direction: row;

        + .btn-group {
            margin-left: $btn-group-margin-h;
        }
    }

    .btn,
    .btn-wrapper {
        + .btn,
        + .btn-wrapper {
            margin-left: $btn-group-inner-margin-h;
        }
    }

    &.btn-group-wrap {
        flex-wrap: wrap;
        overflow: visible;

        .btn,
        .btn-group {
            margin: 0 $btn-group-inner-margin-h $btn-group-inner-margin-v 0;
        }
    }

    /*  Size Large
    ========================================================================== */

    &.btn-group-size-lg {
        .btn-group {
            + .btn-group {
                margin-left: $btn-group-margin-h-size-lg;
            }
        }

        .btn,
        .btn-wrapper {
            + .btn,
            + .btn-wrapper {
                margin-left: $btn-group-inner-margin-h-size-lg;
            }
        }

        &.btn-group-wrap {
            .btn,
            .btn-group {
                margin: 0 $btn-group-inner-margin-h-size-lg $btn-group-inner-margin-v-size-lg 0;
            }
        }
    }
}

/*  Vertical
    ========================================================================== */

.btn-group-vertical {
    flex-direction: column;

    .btn-group {
        flex-direction: column;

        + .btn-group {
            margin-top: $btn-group-margin-v;
        }
    }

    .btn,
    .btn-wrapper {
        + .btn,
        + .btn-wrapper {
            margin-top: $btn-group-inner-margin-v;
        }
    }

    /*  Size Large
    ========================================================================== */

    &.btn-group-size-lg {
        .btn-group {
            + .btn-group {
                margin-top: $btn-group-margin-v-size-lg;
            }
        }

        .btn,
        .btn-wrapper {
            + .btn,
            + .btn-wrapper {
                margin-top: $btn-group-inner-margin-v-size-lg;
            }
        }
    }
}

/*  Justify
    ========================================================================== */

.btn-group-justify {
    .btn {
        flex: 1 0 auto;
    }
}
