/*  ==========================================================================
    Layout
    ========================================================================== */

@import 'base/layout-base';

/* header */
@import 'header/layout-header';

/* navigation primary */
@import 'navigation-primary/layout-navigation-primary';

/* notifications */
@import 'notifications/layout-notification-bar';

/* content */
@import 'content/layout-content';
@import 'content/layout-content-actions';
@import 'content/layout-content-header';
@import 'content/layout-content-notifications';
@import 'content/layout-content-sidebar';
@import 'content/layout-content-view';
@import 'content/layout-content-tabs';

/* sidebar local */
@import 'sidebar-local/layout-sidebar-local';
@import 'sidebar-local/layout-sidebar-local-left';
@import 'sidebar-local/layout-sidebar-local-right';
@import 'sidebar-local/layout-sidebar-local-navigation-secondary';

/* footer */
@import 'footer/layout-footer';
