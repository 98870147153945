/*  ==========================================================================
    Combobox

    NB! usage of !important is to override combobox package style specificity
        without adding arbitrary classes in this file
    ========================================================================== */

@mixin ng-dropdown-panel() {
    @include panel;

    width: auto;
    min-width: 100%;
    margin: $combobox__dropdown_margin;
    color: $color-text;

    .ng {
        &-select-top {
            bottom: 100%;
        }

        &-dropdown-panel-items {
            max-height: $combobox__dropdown_max-height;
            padding: $combobox__dropdown_padding;
        }

        /* option */
        &-option {
            min-height: $line-height-computed;
            padding: $combobox__option_padding;
            border: $border-width-light solid transparent;
            border-radius: $border-radius;
            transition: all $animate-duration-short ease;

            + .ng-optgroup,
            + .ng-option {
                margin-top: $combobox__dropdown-option-group_spacing-between;
            }

            &:hover,
            &:focus,
            &.ng-option-marked {
                background-color: $color-hover-background;

                &,
                & * {
                    color: $color-hover-text;
                }
            }

            &:active,
            &.ng-option-selected {
                border-color: $color-selected;
                background-color: $color-pressed-background;

                &,
                & * {
                    color: $color-pressed-text;
                }
            }

            &.ng-option-disabled {
                background-color: transparent;
                color: $color-text-soft;
            }
        }

        /* group */
        &-optgroup {
            margin-bottom: $combobox__dropdown-option-group-label_spacing;

            .ng-option-label {
                font-size: $font-size-sm;
                font-weight: $font-weight-semibold;
                color: $color-text-soft;
            }
        }
    }
}

.combobox {
    $block: &;

    width: 100%;

    /*  Theme ng-select component
        ========================================================================== */

    &.ng-select {
        /*  Elements
            ========================================================================== */

        .ng {
            /* select */
            &-select-container {
                @include form-control($block, '.ng-select-container');
                @include form-control-select($block, '.ng-select-container');

                display: flex;

                &:focus-within {
                    border-color: $form-control-hover-border-color;
                }
            }

            &-value-container {
                gap: $combobox--multiselect__value_spacing;
                align-items: center;
                min-height: $line-height-computed;
            }

            /* value */
            &-value {
                display: flex;
                align-items: center;
                height: 100%;
            }

            /* placeholder */
            &-placeholder {
                color: $form-control-placeholder-color;
            }

            &-has-value {
                .ng-placeholder {
                    display: none;
                }
            }

            /* input */
            &-input {
                display: flex;
                align-items: center;

                &::before {
                    @extend .far;

                    content: fa-content($fa-var-search);
                    width: $combobox__input-search-icon_width;
                    text-align: center;
                    color: $color-text-muted;
                }

                &:hover,
                &:focus-within {
                    &::before {
                        color: $color-text-soft;
                    }
                }

                input {
                    display: none;
                    box-sizing: border-box !important;
                    min-width: $combobox__input_min-width;
                    cursor: text !important;
                }
            }

            /* clear */
            &-clear {
                display: none !important;

                &-wrapper {
                    @extend .far;
                    @extend .fa-times;

                    position: relative;
                    z-index: $z-index-above;
                    display: flex;
                    align-items: center;
                    align-self: center;
                    justify-content: center;
                    width: $combobox__clear_width !important;
                    margin: $combobox__clear_offset 0;
                    color: $color-text-soft;

                    &:hover,
                    &:focus {
                        color: $color-hover-text;
                    }

                    &:active {
                        color: $color-pressed-text;
                    }
                }
            }

            /* pointer */
            &-pointer {
                &-wrapper {
                    display: none;
                }
            }

            /* arrow - only used as touch target */
            &-arrow {
                &-wrapper {
                    position: absolute;
                    top: -$form-control-border-w;
                    right: -$form-control-border-w;
                }
            }

            &-spinner-loader {
                all: initial;
                box-sizing: inherit;
                font-size: inherit;
            }

            /* dropdown */
            &-dropdown-panel {
                @include ng-dropdown-panel;
            }
        }

        &.ng-select-searchable {
            &.ng-select-single {
                .ng-value-container {
                    margin-left: $combobox__input-search-icon_width +
                        $combobox__input-control_padding-h--size-md;
                }
            }

            .ng-input {
                input {
                    display: initial;
                }
            }
        }

        &.ng-select-single {
            .ng {
                &-input {
                    top: 0;

                    input {
                        padding-top: $combobox__input-control_padding-v--size-md;
                        padding-bottom: $combobox__input-control_padding-v--size-md;
                    }
                }
            }
        }

        &.ng-select-multiple {
            width: 100%;

            .ng-select-container {
                height: auto;
            }

            .ng-input {
                min-height: initial;

                input {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            .ng-value {
                height: auto;
                white-space: normal !important;
            }

            .ng-has-value {
                .ng-value-container {
                    .ng-input {
                        z-index: $z-index-above;
                        margin-left: $combobox--multiselect__input-margin-left;
                        opacity: 1;

                        &::before {
                            @extend .far;

                            content: fa-content($fa-var-plus);
                        }

                        &:hover,
                        &:focus-within {
                            &::before {
                                color: $color-hover-text;
                            }
                        }

                        &:active {
                            &::before {
                                color: $color-pressed-text;
                            }
                        }
                    }
                }
            }
        }
    }

    /*  Size
       ========================================================================== */

    &--size-md {
        line-height: $line-height-computed;

        .ng {
            &-select-container {
                min-height: $form-control-height--size-md;
                padding: $combobox__select_padding--size-md;
            }

            &-input {
                min-height: $combobox__input_min-height--size-md;
            }
        }

        &.ng-select {
            .ng {
                &-clear-wrapper {
                    height: $combobox__clear_height;
                    font-size: $font-size-sm;
                }

                &-arrow-wrapper {
                    width: $combobox__arrow_size--size-md;
                    height: $combobox__arrow_size--size-md;
                }

                &-spinner-loader {
                    margin-right: $combobox__label-icon_spacing--size-md;
                }
            }

            &.ng-select-single {
                .ng-select-container {
                    .ng-input {
                        padding-right: $combobox__input-wrapper_padding-right--size-md;
                        padding-left: $combobox__input-wrapper_padding-left--size-md;

                        &::before {
                            margin-right: $combobox__label-icon_spacing--size-md;
                            line-height: inherit;
                        }

                        input {
                            padding: $combobox__input-control_padding--size-md;
                        }
                    }
                }

                &.ng-select-searchable {
                    .ng-value-container {
                        margin-left: $combobox__input-search-icon_width--size-md +
                            $combobox__input-control_padding-h--size-md;
                    }
                }
            }

            &.ng-select-searchable {
                .ng-placeholder {
                    left: $combobox__input-search-icon_width--size-md +
                        $combobox__select_padding-left--size-md + $combobox__label-icon_spacing--size-md;
                }
            }
        }
    }

    &--size-sm {
        line-height: $line-height-sm-computed;

        .ng {
            &-select-container {
                min-height: $form-control-height--size-sm;
                padding: $combobox__select_padding--size-sm;
                font-size: $form-control-font-size--size-sm;
            }

            &-input {
                min-height: $combobox__input_min-height--size-sm;
                line-height: inherit;
            }
        }

        &.ng-select {
            .ng {
                &-clear-wrapper {
                    height: $combobox__clear_height--size-sm;
                    font-size: $font-size-xs;
                }

                &-arrow-wrapper {
                    width: $combobox__arrow_size--size-sm;
                    height: $combobox__arrow_size--size-sm;
                }

                &-spinner-loader {
                    margin-right: $combobox__label-icon_spacing--size-sm;
                }
            }

            &.ng-select-single {
                .ng-select-container {
                    .ng-input {
                        padding-right: $combobox__input-wrapper_padding-right--size-sm;
                        padding-left: $combobox__input-wrapper_padding-left--size-sm;

                        &::before {
                            margin-right: $combobox__label-icon_spacing--size-sm;
                        }

                        input {
                            padding: $combobox__input-control_padding--size-sm;
                        }
                    }
                }

                &.ng-select-searchable {
                    .ng-value-container {
                        margin-left: $combobox__input-search-icon_width--size-sm +
                            $combobox__input-control_padding-h--size-sm;
                    }
                }
            }

            &.ng-select-searchable {
                .ng-placeholder {
                    left: $combobox__input-search-icon_width--size-sm +
                        $combobox__select_padding-left--size-sm + $combobox__label-icon_spacing--size-sm;
                }
            }
        }
    }

    /*  Themes
    ========================================================================== */

    @include theme(light-blue) {
        &.ng-select {
            .ng {
                &-select-container {
                    @extend %form-control--theme-on-light-blue;
                }
            }
        }
    }

    @include theme(dark-blue) {
        &.ng-select {
            .ng {
                &-select-container {
                    @extend %form-control--theme-on-dark-blue;
                    @extend %form-control-select--theme-on-dark-blue;

                    &:focus-within {
                        border-color: $color-white;
                    }
                }

                &-input {
                    &::before {
                        color: $color-text-muted-on-dark;
                    }

                    &:hover,
                    &:focus-within {
                        &::before {
                            color: $color-text-soft-on-dark;
                        }
                    }

                    input {
                        color: $color-text-on-dark;
                    }
                }

                &-placeholder {
                    color: $color-text-muted-on-dark;
                }
            }

            .ng-clear-wrapper {
                color: $color-text-muted-on-dark;

                &:hover,
                &:focus {
                    color: $color-hover-text-on-dark-blue;
                }

                &:active {
                    color: $color-pressed-text-on-dark-blue;
                }
            }
        }
    }

    /* dropdown */
    &.ng-dropdown-panel {
        @include ng-dropdown-panel;
    }

    /*  Custom templates
        ========================================================================== */

    /* selected option label */
    &__label-icon {
        margin-right: $combobox__label-icon_spacing;
        font-size: $font-size-sm;
    }

    /* dropdown option */
    &__option {
        .title__icon {
            .ng-option:hover &,
            .ng-option:focus &,
            .ng-option.ng-option-marked & {
                background-color: $color-blue-50;
            }
        }
    }
}
