/*  ==========================================================================
    Draggable list
    ========================================================================== */

$draggable-list--md_padding: grid-type-unit(2);
$draggable-list--lg_padding: grid-type-unit(3);

$draggable-list__item_spacing: grid-unit(1);
$draggable-list__item_border-width: $border-width-light;
$draggable-list__item_background-color: $color-blue-50;
$draggable-list__item-hover_background-color: $color-blue-100;
$draggable-list__item-dragging_background-color: $draggable-list__item-hover_background-color;
$draggable-list__item-active_border-color: $color-blue-700;

$draggable-list__item-handle_color: $color-blue-400;

/* Light gray */

$draggable-list--theme-light-gray__item_background-color: $color-black-100;
$draggable-list--theme-light-gray__item-hover_background-color: $color-black-200;
$draggable-list--theme-light-gray__item-dragging_background-color: $draggable-list__item-hover_background-color;
$draggable-list--theme-light-gray__item-active_border-color: $color-black-700;

$draggable-list--theme-light-gray__item-handle_color: $color-black-500;
