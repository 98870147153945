/*  ==========================================================================
    Content
    ========================================================================== */

/*  Wrapper
    ========================================================================== */

$main-content-wrapper-spacing: grid-unit(4);
$main-content-wrapper-constrained-max-width: $breakpoint-lg-mid;

$main-content-w-min: $root-min-w;
$main-content-w-max: 100%;

/*  Header
    ========================================================================== */

$main-content-header-padding-top: grid-unit();
$main-content-header-margin-bottom: grid-unit(-1);
$main-content-header-bg: $color-bg-light;

$main-content-header-sidebar-toggle-font-size: $icon-font-size-base;
$main-content-header-sidebar-toggle-margin: 0;
$main-content-header-sidebar-toggle-bg: $color-white;
$main-content-header-sidebar-toggle-title-offset: grid-unit();

$main-content-header-overview-trigger-title-offset: grid-unit(2);

$main-content-header-title-padding: grid-unit() 0;
$main-content-header-title-color: $color-heading;
$main-content-header-title-font-weight: $font-weight-bold;
$main-content-header-title-font-size: $font-size-lg;
$main-content-header-title-line-height: $line-height-computed;

/*  Inner
    ========================================================================== */

$main-content-inner-extra-spacing-bottom: $main-content-wrapper-spacing;
$main-content-inner-padding: 0;
$main-content-inner-bg: $app-bg;

/*  Content
    ========================================================================== */

$content-padding-horizontal: grid-unit();
$content-padding-horizontal-sm: grid-unit(2);
$content-padding-horizontal-md: grid-unit(3);

$content-padding-top: 0;
$content-padding-top-sm: $content-padding-top;
$content-padding-top-md: $content-padding-top;

$content-padding-bottom: $content-padding-horizontal;
$content-padding-bottom-sm: $content-padding-horizontal-sm;
$content-padding-bottom-md: $content-padding-horizontal-md;

$content-padding: $content-padding-top $content-padding-horizontal $content-padding-bottom
    $content-padding-horizontal;
$content-padding-sm: $content-padding-top-sm $content-padding-horizontal-sm $content-padding-bottom-sm
    $content-padding-horizontal-sm;
$content-padding-md: $content-padding-top-md $content-padding-horizontal-md $content-padding-bottom-md
    $content-padding-horizontal-md;

/*  Legacy
    ========================================================================== */

$main-content-header-children-spacing: grid-unit(2);
