/*  ==========================================================================
    Priva icon font
    ========================================================================== */

/* Basic font variables */
$priva-icon-font-version: 'v1.37';
$priva-icon-font-name: 'priva-icons';
$priva-icon-font-family: 'priva-icons';
$priva-icon-font-svg-id: 'priva-icons';
$priva-icon-font-type: 'regular';
$priva-icon-font-weight: normal;
$priva-icon-font-style: normal;

/* Icon UTF-8 variables */
$icon-linegraph-lg: \e9f5;
$icon-portal2: \e9f3;
$icon-logout: \e9f4;
$icon-sun-lg: \e9f2;
$icon-air-outlet-lg: \e9f1;
$icon-manifold-lg: \e9d7;
$icon-gasmotor-lg: \e9d8;
$icon-heatexchanger-lg: \e9db;
$icon-well-hot-cold-lg: \e9dc;
$icon-well-cold-lg: \e9dd;
$icon-well-hot-lg: \e9de;
$icon-irrigation-valve-lg: \e9df;
$icon-waterpipe-lg: \e9e5;
$icon-par-lg: \e9e6;
$icon-co2transport-lg: \e9e7;
$icon-airhandling-lg: \e9e8;
$icon-cropmonitor-lg: \e9e9;
$icon-airinlet-lg: \e9ea;
$icon-pad-and-fan-lg: \e9eb;
$icon-drain-lg: \e9ec;
$icon-airmixing-lg: \e9ed;
$icon-radiation-sum-lg: \e9ee;
$icon-ground-humidity-lg: \e9ef;
$icon-managementinformation: \e9f0;
$icon-tcmanager-path1: \e9d4;
$icon-tcmanager-path2: \e9d5;
$icon-license-lg: \e9d6;
$icon-manualoverride: \e9c5;
$icon-zone: \e9ca;
$icon-help-outline: \e9cb;
$icon-flame2: \e9ce;
$icon-photosynthesis: \e9cf;
$icon-weather: \e9d0;
$icon-waterdrop: \e9d1;
$icon-bell: \e9d2;
$icon-home: \e9d3;
$icon-portal: \e9c6;
$icon-alarmservice: \e9c7;
$icon-labourinsight-path1: \e9c8;
$icon-labourinsight-path2: \e9c9;
$icon-cropinsight-path1: \e9cc;
$icon-cropinsight-path2: \e9cd;
$icon-operator-path1: \e9d9;
$icon-operator-path2: \e9da;
$icon-masterdata: \e9e0;
$icon-provisioning: \e9e1;
$icon-mapmaker: \e9e2;
$icon-gatewaysetup: \e9e3;
$icon-accessmanagement: \e9e4;
$icon-sitemanager-path1: \e9c3;
$icon-sitemanager-path2: \e9c4;
$icon-questionmark-xl: \e999;
$icon-red-cross-xl: \e99b;
$icon-exclamationmark-xl: \e99e;
$icon-infomark-xl: \e99f;
$icon-sensor: \e9c2;
$icon-triangle-open-top: \e9bd;
$icon-triangle-open-bottom: \e9be;
$icon-box-open: \e9bf;
$icon-door: \e9c0;
$icon-window: \e9c1;
$icon-arrow-correction-left: \e9bb;
$icon-arrow-correction-right: \e9bc;
$icon-arrow-turn-left: \e9b9;
$icon-arrow-turn-right: \e9ba;
$icon-sunblinds: \e9ab;
$icon-open-closed: \e9ac;
$icon-airquality2: \e9ad;
$icon-cooling: \e9ae;
$icon-light: \e9af;
$icon-electricity2: \e9b0;
$icon-fan: \e9b1;
$icon-settings-outline: \e9b2;
$icon-waterdrops: \e9b3;
$icon-flame: \e9b4;
$icon-leaf3: \e9b5;
$icon-leaf: \e9b6;
$icon-temperature: \e9b7;
$icon-waterdrop-lg: \e9b8;
$icon-calendar: \e956;
$icon-check-dropdown: \e9a9;
$icon-cloud-download-lg: \e9a1;
$icon-cloud-upload-lg: \e9a2;
$icon-appswitcher-arrowdown-lg: \e9a3;
$icon-appswitcher-arrowup-lg: \e9a7;
$icon-energy-lg: \e993;
$icon-curtains-lg: \e98c;
$icon-ventilation-lg: \e98d;
$icon-managementinformation-filled: \e913;
$icon-managementinformation-lg: \e93f;
$icon-clock: \e975;
$icon-timer-lg: \e917;
$icon-clock-lg: \e916;
$icon-tabel: \e977;
$icon-print: \e97a;
$icon-trash: \e97b;
$icon-extensiontimer: \e97c;
$icon-heating-lg: \e97d;
$icon-building: \e920;
$icon-humidity-lg: \e924;
$icon-airquality-lg: \e976;
$icon-leaf-lg: \e9a4;
$icon-co2-lg: \e921;
$icon-bug-lg: \e902;
$icon-light-lg: \e922;
$icon-recipe2-lg: \e9a6;
$icon-fan-lg: \e91d;
$icon-recipe1-lg: \e923;
$icon-watertank-lg: \e925;
$icon-book-open-xl: \e900;
$icon-book-open-lg: \e96f;
$icon-book-open-bold: \e97e;
$icon-bubbles-lg: \e901;
$icon-cloud-lg: \e905;
$icon-cloud-bold: \e97f;
$icon-desktop: \e906;
$icon-filter: \e907;
$icon-hourglass: \e908;
$icon-lego-lg: \e909;
$icon-phone: \e90a;
$icon-pin-lg: \e90b;
$icon-share-lg: \e90c;
$icon-sunrise: \e90d;
$icon-sunset: \e90e;
$icon-tablet: \e90f;
$icon-user-female-lg: \e910;
$icon-user-lg: \e911;
$icon-windspeed-lg: \e912;
$icon-ice-lg: \e914;
$icon-weather-sunny: \e915;
$icon-waterdrop-xl: \e918;
$icon-waterdrops-lg: \e919;
$icon-weather-lg: \e91a;
$icon-calculator-lg: \e91b;
$icon-equalizer-lg: \e91c;
$icon-flame-lg: \e91e;
$icon-temperature-lg: \e91f;
$icon-greenhouse-badge-heart-lg: \e98f;
$icon-greenhouse-lg: \e99a;
$icon-greenhouse-badge-thermometer-lg: \e943;
$icon-shed-badge-waterdrop-lg: \e990;
$icon-scenemacros-lg: \e994;
$icon-home-lg: \e942;
$icon-smileybad-xl: \e96b;
$icon-smileyneutral-xl: \e96c;
$icon-checkmark-lg: \e96e;
$icon-calendar-lg: \e903;
$icon-check-circle: \e904;
$icon-log-out: \e929;
$icon-search: \e92a;
$icon-bell-buzzing-lg: \e936;
$icon-help: \e92b;
$icon-help-outline-lg: \e92c;
$icon-warning: \e92f;
$icon-warning-outline: \e92d;
$icon-info: \e086;
$icon-info-outline: \e92e;
$icon-plus: \2b;
$icon-plus-circle: \e081;
$icon-plus-light: \e930;
$icon-cross: \e931;
$icon-cross-circle: \e937;
$icon-cross-light: \e932;
$icon-check-bold: \e013;
$icon-check-light: \e938;
$icon-loop: \e933;
$icon-reset: \e939;
$icon-ban: \e93b;
$icon-unlink: \e941;
$icon-link: \e144;
$icon-language: \e93c;
$icon-equalizer-alt: \e93d;
$icon-linegraph: \e93e;
$icon-compass-lg: \e944;
$icon-layers: \e945;
$icon-maintenance-lg: \e991;
$icon-energy: \e946;
$icon-network: \e992;
$icon-electricity: \e947;
$icon-temperature-lg2: \e948;
$icon-below-freezingpoint-lg: \e949;
$icon-transfer-vertical: \e179;
$icon-transfer-horizontal: \e178;
$icon-previous: \e94a;
$icon-next: \e94b;
$icon-double-chevron-down: \e94c;
$icon-double-chevron-up: \e94d;
$icon-double-chevron-right: \e94e;
$icon-double-chevron-left: \e94f;
$icon-chevron-left: \e950;
$icon-chevron-right: \e951;
$icon-menu-left: \e952;
$icon-menu-right: \e953;
$icon-circle-left: \e954;
$icon-circle-up: \e955;
$icon-circle-down: \f91c;
$icon-circle-right: \f91b;
$icon-sunny-weather: \e98a;
$icon-arrow-light-up-left: \e957;
$icon-arrow-light-up: \e958;
$icon-arrow-light-up-right: \e959;
$icon-arrow-light-right: \e95a;
$icon-arrow-light-down-right: \e95b;
$icon-arrow-light-down: \e95c;
$icon-arrow-light-down-left: \e95d;
$icon-arrow-left: \e95e;
$icon-arrow-up-left: \e95f;
$icon-arrow-up: \e960;
$icon-arrow-up-right: \e961;
$icon-arrow-right: \e962;
$icon-arrow-down-right: \f90e;
$icon-arrow-down: \e963;
$icon-arrow-down-left: \e964;
$icon-arrow-light-left: \e9aa;
$icon-weather-cloudy-lg: \e965;
$icon-weather-night-clear-lg: \e966;
$icon-weather-night-cloudy-lg: \e986;
$icon-weather-rainy-lg: \e987;
$icon-weather-snowy-lg: \e989;
$icon-weather-stormy-lg: \e988;
$icon-module-path1: \e967;
$icon-module-path2: \e968;
$icon-module-path3: \e969;
$icon-module-path4: \e978;
$icon-module-path5: \e979;
$icon-lock: \e033;
$icon-comment: \e111;
$icon-option-vertical: \e235;
$icon-option-horizontal: \e234;
$icon-minus: \2212;
$icon-minus-sign: \e082;
$icon-ok-circle: \e089;
$icon-remove-circle: \e088;
$icon-exclamation-sign: \e101;
$icon-copyright-mark: \e194;
$icon-registration-mark: \e195;
$icon-heart-empty: \e143;
$icon-heart: \e005;
$icon-star-lg: \e006;
$icon-star-empty-lg: \e007;
$icon-wrench: \e136;
$icon-envelope: \2709;
$icon-paperclip: \e142;
$icon-th-large: \e010;
$icon-grid-lg: \e940;
$icon-menu-lg: \e980;
$icon-th-list: \e012;
$icon-list-alt: \e032;
$icon-zoom-in: \e015;
$icon-zoom-out: \e016;
$icon-off: \e017;
$icon-dashboard: \e141;
$icon-qrcode-lg: \e039;
$icon-barcode-lg: \e040;
$icon-tag: \e041;
$icon-tags: \e042;
$icon-bookmark: \e044;
$icon-camera: \e046;
$icon-facetime-video: \e059;
$icon-picture: \e060;
$icon-flag: \e034;
$icon-map-marker: \e062;
$icon-record: \e165;
$icon-screenshot-lg: \e087;
$icon-contrast: \e063;
$icon-music: \e002;
$icon-film: \e009;
$icon-fast-backward: \e070;
$icon-step-backward: \e069;
$icon-backward: \e071;
$icon-play: \e072;
$icon-pause: \e073;
$icon-stop: \e074;
$icon-forward: \e075;
$icon-step-forward: \e077;
$icon-fast-forward: \e076;
$icon-volume-off: \e036;
$icon-volume-down: \e037;
$icon-volume-up: \e038;
$icon-repeat: \e030;
$icon-refresh: \e031;
$icon-move: \e068;
$icon-resize-full: \e096;
$icon-resize-small: \e097;
$icon-fullscreen: \e140;
$icon-resize-vertical: \e119;
$icon-resize-horizontal: \e120;
$icon-retweet: \e115;
$icon-shuffle: \e110;
$icon-sort: \e150;
$icon-eye-open: \e105;
$icon-eye-close: \e106;
$icon-folder-close: \e117;
$icon-folder-open: \e118;
$icon-file: \e022;
$icon-thumbs-up: \e125;
$icon-thumbs-down: \e126;
$icon-hand-right: \e127;
$icon-hand-left: \e128;
$icon-hand-up: \e129;
$icon-hand-down: \e130;
$icon-sort-by-alphabet: \e151;
$icon-sort-by-alphabet-alt: \e152;
$icon-sort-by-order: \e153;
$icon-sort-by-order-alt: \e154;
$icon-sort-by-attributes: \e155;
$icon-sort-by-attributes-alt: \e156;
$icon-checked: \e067;
$icon-unchecked: \e157;
$icon-expand: \e158;
$icon-collapse-down: \e159;
$icon-collapse-up: \e160;
$icon-edit: \e065;
$icon-log-in: \e161;
$icon-new-window: \e164;
$icon-save: \e166;
$icon-open: \e167;
$icon-saved: \e168;
$icon-import: \e169;
$icon-export: \e170;
$icon-inbox: \e028;
$icon-floppy-disk: \e172;
$icon-floppy-saved: \e173;
$icon-floppy-remove: \e174;
$icon-floppy-save: \e175;
$icon-floppy-open: \e176;
$icon-download-alt: \e025;
$icon-erase: \e221;
$icon-tasks: \e137;
$icon-stats: \e185;
$icon-play-circle: \e029;
$icon-cloud-download: \e197;
$icon-cloud-upload: \e198;
$icon-duplicate: \e224;
$icon-flash: \e162;
$icon-cutlery: \e17a;
$icon-grain-lg: \e239;
$icon-plane: \e108;
$icon-globe: \e135;
$icon-credit-card: \e177;
$icon-hdd: \e121;
$icon-briefcase: \e139;
$icon-tree-deciduous: \e200;
$icon-cd: \e201;
$icon-blackboard: \e218;
$icon-bed: \e219;
$icon-lamp: \e223;
$icon-shopping-cart: \e116;
$icon-scissors: \e226;
$icon-scale: \e230;
$icon-modal-window: \e237;
$icon-education-lg: \e233;
$icon-send: \e171;
$icon-earphone: \e182;
$icon-save-file2: \e202;
$icon-open-file2: \e203;
$icon-copy2: \e205;
$icon-paste2: \e206;
$icon-level-up-lg: \e204;
$icon-console: \e254;
$icon-compressed: \e181;
$icon-triangle-right: \e250;
$icon-triangle-left: \e251;
$icon-triangle-bottom: \e252;
$icon-triangle-top: \e253;
$icon-menu-down: \e259;
$icon-menu-up: \e260;
$icon-share-alt: \e095;
$icon-circle-arrow-right: \e131;
$icon-circle-arrow-left: \e132;
$icon-circle-arrow-up: \e133;
$icon-circle-arrow-down: \e134;
$icon-circle-arrow-down-outline: \e026;
$icon-circle-arrow-up-outline: \e027;
$icon-chevron-up: \e113;
$icon-chevron-down: \e114;
$icon-alert-outline-lg: \e934;
$icon-dashboard-lg: \e96a;
$icon-manualoverride-lg: \e9a5;
$icon-shed-badge-flame-lg: \e98e;
$icon-settings: \e019;
$icon-settings-outline-lg: \e971;
$icon-alert2-lg: \e927;
$icon-edit2: \2710;
$icon-edit-lg: \e96d;
$icon-bell-lg: \e93a;
$icon-envelope-xl: \e995;
$icon-cloud-download-xl: \e996;
$icon-cloud-upload-xl: \e997;
$icon-leafs-lg: \e99c;
$icon-smileygood-xl: \e9a8;
$icon-alert: \e926;
$icon-stars1-xl: \e972;
$icon-stars2-xl: \e973;
$icon-stars3-xl: \e974;
$icon-alert-outline2-lg: \e935;
$icon-download-lg: \e928;
$icon-configure-lg: \e998;
$icon-edit-xl: \270f;
$icon-arrow-updown: \e99d;
$icon-formule: \e9a0;
$icon-clock-fill: \e981;
$icon-sort-arrow-down: \e982;
$icon-sort-arrow-up: \e983;
$icon-sort-down-bold: \e984;
$icon-sort-up-bold: \e985;
$icon-copy: \e970;
$icon-transpose: \e98b;
