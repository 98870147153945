/*  ==========================================================================
    Table sticky
    ========================================================================== */

.table {
    $block: &;

    &--sticky-header {
        #{$block}__cell--header {
            position: sticky;
            z-index: $table--sticky-header__cell--header_z-index;
            top: 0;
            border-bottom-color: $table_border-color;
        }

        &#{$block}--separate {
            #{$block}__cell--header {
                background-color: $color-bg-light;
            }

            &#{$block}--has-dark-header #{$block}__cell--header {
                background-color: $color-bg-dark;
            }
        }
    }

    &--sticky-first-column {
        #{$block}__cell:first-child {
            position: sticky;
            z-index: $table--sticky-column__cell_z-index;
            left: 0;

            &#{$block}__cell--header {
                z-index: $table--sticky-column__cell--header-first_z-index;
            }
        }

        &#{$block}--separate {
            #{$block}__cell--header:first-child {
                background-color: $color-bg-light;
            }

            &#{$block}--has-dark-header #{$block}__cell--header {
                background-color: $color-bg-dark;
            }
        }
    }
}
