// Do not edit directly.
// This file is generated using Style Dictionary.

$color-white: hsl(360, 100%, 100%);
$color-grayscale-50: hsl(0, 0%, 96.1%);
$color-grayscale-100: hsl(0, 0%, 91.4%);
$color-grayscale-200: hsl(0, 0%, 86.7%);
$color-grayscale-300: hsl(0, 0%, 80.8%);
$color-grayscale-400: hsl(0, 0%, 64.7%);
$color-grayscale-500: hsl(0, 0%, 53.3%);
$color-grayscale-600: hsl(0, 0%, 44.3%);
$color-grayscale-700: hsl(0, 0%, 36.1%);
$color-grayscale-800: hsl(0, 0%, 27.5%);
$color-grayscale-900: hsl(0, 0%, 22.4%);
$color-grayscale-1000: hsl(0, 0%, 15.3%);
$color-black-50: hsl(220, 4.8%, 96.18%);
$color-black-100: hsl(220, 4.8%, 91.47%);
$color-black-200: hsl(220, 4.8%, 86.88%);
$color-black-300: hsl(220, 4.8%, 81.13%);
$color-black-400: hsl(220, 4.8%, 65.26%);
$color-black-500: hsl(220, 4.8%, 54.03%);
$color-black-600: hsl(220, 4.8%, 45.11%);
$color-black-700: hsl(220, 4.8%, 36.66%);
$color-black-800: hsl(220, 4.8%, 27.93%);
$color-black-900: hsl(220, 4.8%, 22.71%);
$color-black-1000: hsl(220, 4.8%, 15.58%);
$color-blue-50: hsl(200, 59.1%, 95.69%);
$color-blue-100: hsl(200, 57.1%, 90.79%);
$color-blue-200: hsl(200, 55.1%, 85.61%);
$color-blue-300: hsl(200, 53.1%, 79.49%);
$color-blue-400: hsl(200, 51.1%, 61.27%);
$color-blue-500: hsl(200, 49.1%, 48.55%);
$color-blue-600: hsl(200, 47.1%, 40.69%);
$color-blue-700: hsl(200, 45.1%, 33.04%);
$color-blue-800: hsl(200, 43.1%, 25.4%);
$color-blue-900: hsl(200, 45.1%, 20.48%);
$color-blue-1000: hsl(200, 47.1%, 14.21%);
$color-green-blue-50: hsl(187, 80%, 94.03%);
$color-green-blue-100: hsl(187, 85%, 85.35%);
$color-green-blue-200: hsl(187, 90%, 75.92%);
$color-green-blue-300: hsl(187, 95%, 58.19%);
$color-green-blue-400: hsl(187, 100%, 40.18%);
$color-green-blue-500: hsl(187, 95%, 34.03%);
$color-green-blue-600: hsl(187, 90%, 28.99%);
$color-green-blue-700: hsl(187, 85%, 23.98%);
$color-green-blue-800: hsl(187, 80%, 18.72%);
$color-green-blue-900: hsl(187, 75%, 15.69%);
$color-green-blue-1000: hsl(187, 70%, 10.98%);
$color-teal-50: hsl(173, 67%, 93.94%);
$color-teal-100: hsl(173, 67%, 85.69%);
$color-teal-200: hsl(173, 67%, 76.28%);
$color-teal-300: hsl(173, 67%, 65.5%);
$color-teal-400: hsl(173, 67%, 43.21%);
$color-teal-500: hsl(173, 67%, 35.7%);
$color-teal-600: hsl(173, 67%, 29.82%);
$color-teal-700: hsl(173, 67%, 24.18%);
$color-teal-800: hsl(173, 67%, 18.32%);
$color-teal-900: hsl(173, 67%, 15.03%);
$color-teal-1000: hsl(173, 67%, 10.34%);
$color-purple-50: hsl(286, 39%, 96.61%);
$color-purple-100: hsl(286, 39%, 92.66%);
$color-purple-200: hsl(286, 39%, 88.69%);
$color-purple-300: hsl(286, 39%, 83.96%);
$color-purple-400: hsl(286, 39%, 70.02%);
$color-purple-500: hsl(286, 39%, 59.94%);
$color-purple-600: hsl(286, 39%, 51.45%);
$color-purple-700: hsl(286, 39%, 41.82%);
$color-purple-800: hsl(286, 39%, 31.94%);
$color-purple-900: hsl(286, 39%, 26.31%);
$color-purple-1000: hsl(286, 39%, 18.09%);
$color-green-50: hsl(137, 72.8%, 94.13%);
$color-green-100: hsl(137, 72.8%, 85.66%);
$color-green-200: hsl(137, 72.8%, 77.01%);
$color-green-300: hsl(137, 72.8%, 65.44%);
$color-green-400: hsl(137, 72.8%, 43.12%);
$color-green-500: hsl(137, 72.8%, 35.63%);
$color-green-600: hsl(137, 72.8%, 29.73%);
$color-green-700: hsl(137, 72.8%, 24.06%);
$color-green-800: hsl(137, 72.8%, 18.38%);
$color-green-900: hsl(137, 72.8%, 14.97%);
$color-green-1000: hsl(137, 72.8%, 10.43%);
$color-orange-50: hsl(38, 100%, 94.12%);
$color-orange-100: hsl(38, 100%, 86.64%);
$color-orange-200: hsl(38, 100%, 78.61%);
$color-orange-300: hsl(38, 100%, 68.99%);
$color-orange-400: hsl(38, 100%, 45.24%);
$color-orange-500: hsl(38, 100%, 37.46%);
$color-orange-600: hsl(38, 100%, 30.97%);
$color-orange-700: hsl(38, 100%, 25.35%);
$color-orange-800: hsl(38, 100%, 19.2%);
$color-orange-900: hsl(38, 100%, 15.74%);
$color-orange-1000: hsl(38, 100%, 10.81%);
$color-red-50: hsl(352, 84.2%, 97.25%);
$color-red-100: hsl(352, 84.2%, 94.08%);
$color-red-200: hsl(352, 84.2%, 90.61%);
$color-red-300: hsl(352, 84.2%, 86.63%);
$color-red-400: hsl(352, 84.2%, 73.55%);
$color-red-500: hsl(352, 84.2%, 61.97%);
$color-red-600: hsl(352, 84.2%, 47.68%);
$color-red-700: hsl(352, 84.2%, 38.98%);
$color-red-800: hsl(352, 84.2%, 30.22%);
$color-red-900: hsl(352, 84.2%, 24.9%);
$color-red-1000: hsl(352, 84.2%, 17.66%);
