/*  Inline forms

    Make forms appear inline(-block) by adding the `.form-inline` class. Inline
    forms begin stacked on extra small (mobile) devices and then go inline when
    viewports reach <768px.

    Requires wrapping inputs and labels with `.form-group` for proper display of
    default HTML form controls and our custom form controls (e.g., input groups).

    Heads up! This is mixin-ed into `.navbar-form` in the navbars component.
    ========================================================================== */

.form-inline {
    @include media-breakpoint-up(sm) {
        /* Form group */
        .form-group {
            display: inline-block;
            margin: $form-inline-group-margin--size-md;
            vertical-align: middle;
        }

        /* Form label */
        .form-control-label {
            width: auto;
            margin-right: 0;
            margin-bottom: 0;
            padding-right: $form-inline-control-label-padding-right--size-md;
        }

        /* Form control */
        .form-control,
        .form-control-static {
            display: inline-block;
            width: auto;
            min-width: auto;
            margin: auto;
        }

        /* Radios and checkboxes */
        .radio,
        .checkbox {
            margin: 0;

            .input-box {
                margin-right: $form-inline-control-radio-checkbox-label-spacing--size-md;
            }
        }

        /* Make input group behave inline todo: implement and refactor */
        .input-group {
            display: inline-table;
            vertical-align: middle;

            .input-group-addon,
            .input-group-btn,
            .form-control {
                width: auto;
            }

            > .form-control {
                width: 100%; // input groups need that 100% width though
            }
        }
    }
}
