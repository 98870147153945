/*  ==========================================================================
    Grid
    ========================================================================== */

$grid-item-gap: grid-unit(2);
$grid-item-gap-lg: grid-unit(3);

$grid-item-min-h: grid-unit(8);

/*  Sizes
    ========================================================================== */

/// Set global minimum width for grid items
/// @group configuration
$grid-item-min-w: grid-unit(32) !default;

/// Set global minimum width for small grid items (in grid with class "has-small-cards")
/// @group configuration
$grid-item-small-min-w: grid-unit(24) !default;

/// Set global minimum width for large grid items (in grid with class "has-large-cards")
/// @group configuration
$grid-item-large-min-w: grid-unit(40) !default;
