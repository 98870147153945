/*  ==========================================================================
    Visibility helper classes
    ========================================================================== */

/*  Display
    ========================================================================== */

.inline-block {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.inline {
    display: inline !important;
}

.hidden {
    display: none !important;
}

/* Hide element offscreen but keep it"s dimensions */
.hidden-offscreen {
    position: absolute !important;
    top: grid-unit(-1250) !important;
    left: grid-unit(-1250) !important;
}

/* Displays hidden content on hover. Add this class to the parent element.
Markup
    <div class="hover-visible">
        <div class="hidden h-display-inline"></div>
    </div>
*/

.visible-on-hover {
    &:hover {
        .hidden {
            display: block !important;

            &.inline {
                display: inline !important;
            }

            &.inline-block {
                display: inline-block !important;
            }
        }
    }
}

/* Only display content to screen readers, see: http://a11yproject.com/posts/how-to-hide-content/ */
.sr-only {
    position: absolute !important;
    clip: rect(0, 0, 0, 0) !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
}

/* Use in conjunction with .sr-only to only display content when it"s focused.
    Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
    Credit: HTML5 Boilerplate */

.sr-only-focusable {
    &:active,
    &:focus {
        position: static !important;
        clip: auto !important;
        overflow: visible !important;
        width: auto !important;
        height: auto !important;
        margin: 0 !important;
    }
}

/*  Responsive visibility
    ========================================================================== */

@each $breakpoint in map-keys($breakpoints-utility-classes) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        .display-none#{$infix} {
            display: none !important;
        }
        .display-inline#{$infix} {
            display: inline !important;
        }
        .display-inline-block#{$infix} {
            display: inline-block !important;
        }
        .display-block#{$infix} {
            display: block !important;
        }
        .display-table#{$infix} {
            display: table !important;
        }
        .display-table-row#{$infix} {
            display: table-row !important;
        }
        .display-table-cell#{$infix} {
            display: table-cell !important;
        }
        .display-flex#{$infix} {
            display: flex !important;
        }
        .display-inline-flex#{$infix} {
            display: inline-flex !important;
        }
    }
}

/*  Responsive hidden classes
    ========================================================================== */

@each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-down($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);
        .hidden#{$infix}-down {
            display: none !important;
        }
    }

    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);
        .hidden#{$infix}-up {
            display: none !important;
        }
    }
}

/* Print utilities
    Media queries are placed on the inside to be mixin-friendly.
   ========================================================================== */

@media print {
    .display-print-none {
        display: none !important;
    }

    .display-print-inline {
        display: inline !important;
    }

    .display-print-inline-block {
        display: inline-block !important;
    }

    .display-print-block {
        display: block !important;
    }

    .display-print-table {
        display: table !important;
    }

    .display-print-table-row {
        display: table-row !important;
    }

    .display-print-table-cell {
        display: table-cell !important;
    }

    .display-print-flex {
        display: flex !important;
    }

    .display-print-inline-flex {
        display: inline-flex !important;
    }
}
