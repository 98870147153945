/*  ==========================================================================
    Card
    ========================================================================== */

$card-bg: $color-white;
$card-border-width: $border-width-light;
$card-padding-v-xs: grid-unit(1);
$card-padding-h-xs: grid-type-unit(3);
$card-padding-sm: grid-unit(2);
$card-padding: grid-unit(3);
$card-padding-lg: grid-unit(4);

/* Image */
$card-image-height: grid-unit(20);
$card-image-bg: $color-black-100;

/* Header */
$card-header-indicator-color: $color-info;
$card-header-indicator-size: $icon-font-size-lg;
$card-header-indicator-margin: grid-unit(2);

/* Overflow menu */
$card-overflow-trigger-size: $icon-font-size-lg;
$card-overflow-trigger-radius: $border-radius-circle;

/* Footer */
$card-footer-action-margin: grid-unit();
$card-footer-action-offset: grid-unit(-1);
$card-footer-border-color: $color-border;
