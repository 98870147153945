/*  ==========================================================================
    Font
    ========================================================================== */

/*  Lato
    ========================================================================== */

/* Light */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-light-name,
        normal,
        $lato-light-weight,
        $lato-version
    );
}

/* Light italic */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-light-italic-name,
        italic,
        $lato-light-weight,
        $lato-version
    );
}

/* Regular */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-regular-name,
        normal,
        $lato-regular-weight,
        $lato-version
    );
}

/* Regular italic */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-regular-italic-name,
        italic,
        $lato-regular-weight,
        $lato-version
    );
}

/* Semi Bold */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-semibold-name,
        normal,
        $lato-semibold-weight,
        $lato-version
    );
}

/* Semi Bold italic */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-semibold-italic-name,
        italic,
        $lato-semibold-weight,
        $lato-version
    );
}

/* Bold */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-bold-name,
        normal,
        $lato-bold-weight,
        $lato-version
    );
}

/* Bold italic */
@font-face {
    @include fontdef(
        $lato-path,
        $lato-name,
        $lato-family,
        $lato-bold-italic-name,
        italic,
        $lato-bold-weight,
        $lato-version
    );
}

/*  Noto
    ========================================================================== */

/* Bold */
@font-face {
    @include fontdef(
        $noto-path,
        $noto-name,
        $noto-family,
        $noto-bold-name,
        normal,
        $noto-bold-weight,
        $noto-version
    );
}

/* Bold italic */
@font-face {
    @include fontdef(
        $noto-path,
        $noto-name,
        $noto-family,
        $noto-bold-italic-name,
        italic,
        $noto-bold-weight,
        $noto-version
    );
}
