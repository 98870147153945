/*  ==========================================================================
    Themes
    ========================================================================== */

$themes: (
    'white': 'white',
    'light-gray': 'light-gray',
    'light-blue': 'light-blue',
    'dark-blue': 'dark-blue',
);
