/*  ==========================================================================
    Button
    ========================================================================== */

.btn {
    $block: &;

    @include button-size($btn-min-width, $btn-min-height, $btn-line-height);

    position: relative;
    display: inline-flex;
    border: $btn-border-size solid transparent;
    border-radius: $border-radius;
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    transition: all $animate-duration-short;
    place-content: center center;
    place-items: center center;
    appearance: none;

    &[disabled],
    &:disabled,
    &.disabled,
    fieldset[disabled] & {
        pointer-events: none;
        cursor: $cursor-disabled;
    }

    &,
    &-density-md {
        padding: $btn-padding;
    }

    &-density-sm {
        padding: $btn-padding--density-sm;
    }

    @include focus-visible {
        z-index: $z-index-focus;
    }

    /*  Icon
    ========================================================================== */

    &-icon {
        flex: none;

        &-only {
            padding: 0;
            font-size: $icon-font-size-md;
        }

        &--size-equalized {
            font-size: $icon-font-size-sm;
        }

        &-left {
            margin-right: $btn-icon-margin;
        }

        &-right {
            margin-left: $btn-icon-margin;
        }
    }

    /*  Importance
    ========================================================================== */

    &-primary {
        @include property-states(
            'color',
            $btn-primary-color,
            $disabled-value: color-disabled($btn-primary-color)
        );
        @include property-states(
            'background-color',
            $btn-primary-bg,
            $btn-primary-hover-bg,
            $btn-primary-pressed-bg,
            color-disabled($btn-primary-bg),
            $btn-primary-pressed-bg
        );

        border-color: $btn-primary-border-color;

        @include focus-visible {
            box-shadow: focus($color: $color-white);
        }
    }

    &-secondary {
        @include property-states(
            'color',
            $btn-secondary-color,
            $disabled-value: color-disabled($btn-secondary-color)
        );
        @include property-states(
            'border-color',
            $btn-secondary-border-color,
            $btn-secondary-border-color,
            $btn-secondary-pressed-border-color,
            color-disabled($btn-secondary-border-color),
            $btn-secondary-pressed-border-color
        );
        @include property-states(
            'background-color',
            $btn-secondary-bg,
            $btn-secondary-hover-bg,
            $btn-secondary-pressed-bg,
            color-disabled($btn-secondary-bg),
            $btn-secondary-pressed-bg
        );

        @include focus-visible {
            border-color: $color-focus;
            box-shadow: none; // reset default focus style
        }
    }

    &-tertiary,
    &-quaternary {
        &,
        &#{$block}-small {
            padding-right: 0;
            padding-left: 0;
        }

        @include focus-visible {
            box-shadow: focus();
        }
    }

    &-tertiary {
        @include property-states(
            'color',
            $btn-tertiary-color,
            $btn-tertiary-hover-color,
            $btn-tertiary-pressed-color,
            color-disabled($btn-tertiary-color),
            $btn-tertiary-pressed-color
        );
    }

    &-quaternary {
        @include property-states(
            'color',
            $btn-quaternary-color,
            $btn-quaternary-hover-color,
            $btn-quaternary-pressed-color,
            color-disabled($btn-quaternary-color),
            $btn-quaternary-pressed-color
        );
    }

    /*  Menu
    ========================================================================== */

    &-menu {
        @include property-states(
            'color',
            $btn-menu-color,
            $btn-menu-hover-color,
            $btn-menu-pressed-color,
            color-disabled($btn-menu-color),
            $btn-menu-pressed-color
        );

        @include property-states(
            'background-color',
            $btn-menu-bg,
            $btn-menu-hover-bg,
            $btn-menu-pressed-bg,
            $btn-menu-bg,
            $btn-menu-active-bg
        );

        font-weight: $btn-menu-font-weight;

        &.is-active {
            &.is-disabled {
                background-color: color-disabled($btn-menu-pressed-bg);
                color: color-disabled($btn-menu-pressed-color);
            }
        }
    }

    /*  Split
    ========================================================================== */

    &-split {
        &-start,
        &-both {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &-end,
        &-both {
            margin-right: -1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &#{$block} {
                &-primary {
                    &:has(+ #{$block}-primary) {
                        margin-right: 1px;
                        border-right-color: transparent;

                        &::after {
                            content: '';
                            position: absolute;
                            top: -1px;
                            right: -2px;
                            display: inline-block;
                            width: 1px;
                            height: calc(100% + 2px);
                            background-color: $color-purple-200;
                        }
                    }
                }

                &-secondary {
                    // negate specificity when focus-visible is used
                    &:not(.focus-visible) {
                        border-right-color: $color-blue-100;
                    }
                }
            }
        }
    }

    /*  Themes
    ========================================================================== */

    @include theme(light-gray, false) {
        &#{$block} {
            &-secondary {
                @include property-states(
                    'background-color',
                    $btn-secondary-on-light-gray-bg,
                    $btn-secondary-on-light-gray-hover-bg,
                    $btn-secondary-on-light-gray-pressed-bg,
                    color-disabled($btn-secondary-on-light-gray-bg),
                    $btn-secondary-on-light-gray-pressed-bg
                );

                @include property-states(
                    'border-color',
                    $btn-secondary-on-light-gray-border-color,
                    $btn-secondary-on-light-gray-hover-border-color,
                    $btn-secondary-on-light-gray-pressed-border-color,
                    color-disabled($btn-secondary-on-light-gray-border-color),
                    $btn-secondary-on-light-gray-pressed-border-color
                );

                @include focus-visible {
                    border-color: $color-focus;
                }
            }

            &-menu {
                &:not(.is-active, .is-disabled, :active) {
                    &:hover,
                    &:focus {
                        background-color: $btn-menu-on-light-gray-hover-bg;
                    }
                }
            }
        }
    }

    @include theme(dark-blue, false) {
        &#{$block} {
            &-primary {
                @include property-states(
                    'color',
                    $btn-primary-on-dark-blue-color,
                    $disabled-value: color-disabled($btn-primary-on-dark-blue-color)
                );
                @include property-states(
                    'background-color',
                    $btn-primary-on-dark-blue-bg,
                    $btn-primary-on-dark-blue-hover-bg,
                    $btn-primary-on-dark-blue-pressed-bg,
                    color-disabled($btn-primary-on-dark-blue-bg),
                    $btn-primary-on-dark-blue-pressed-bg
                );

                @include focus-visible {
                    border-color: $color-focus-on-dark;
                    box-shadow: inset 0 0 0 1px $color-dark-blue;
                }
            }

            &-secondary {
                @include property-states(
                    'color',
                    $color-text-on-dark,
                    $color-hover-text-on-dark-blue,
                    $color-pressed-text-on-dark-blue,
                    color-disabled($color-text-on-dark),
                    $color-pressed-text-on-dark-blue
                );
                @include property-states(
                    'border-color',
                    $btn-secondary-on-dark-blue-border-color,
                    $btn-secondary-on-dark-blue-hover-border-color,
                    $btn-secondary-on-dark-blue-pressed-border-color,
                    color-disabled($btn-secondary-on-dark-blue-border-color),
                    $btn-secondary-on-dark-blue-pressed-border-color
                );

                background-color: transparent;

                @include focus-visible {
                    border-color: $color-focus-on-dark;
                }
            }

            &-tertiary,
            &-quaternary {
                @include property-states(
                    'color',
                    $color-text-on-dark,
                    $color-hover-text-on-dark-blue,
                    $color-pressed-text-on-dark-blue,
                    color-disabled($color-text-on-dark),
                    $color-pressed-text-on-dark-blue
                );

                @include focus-visible {
                    box-shadow: focus($color: $color-focus-on-dark);
                }
            }

            &-menu {
                @include property-states(
                    'color',
                    $btn-menu-on-dark-blue-color,
                    $btn-menu-on-dark-blue-hover-color,
                    $btn-menu-on-dark-blue-pressed-color,
                    color-disabled($btn-menu-on-dark-blue-color),
                    $btn-menu-on-dark-blue-pressed-color
                );

                @include property-states(
                    'background-color',
                    $btn-menu-on-dark-blue-bg,
                    $btn-menu-on-dark-blue-hover-bg,
                    $btn-menu-on-dark-blue-pressed-bg,
                    $btn-menu-on-dark-blue-bg,
                    $btn-menu-on-dark-blue-active-bg
                );

                @include focus-visible {
                    border-color: $color-focus-on-dark;
                    box-shadow: focus($color: $color-dark-blue);
                }

                &.is-active {
                    &.is-disabled {
                        background-color: color-disabled($btn-menu-on-dark-blue-pressed-bg);
                        color: color-disabled($btn-menu-on-dark-blue-pressed-color);
                    }
                }

                &:active,
                &.is-active {
                    &.btn-theme-on-header {
                        background-color: $btn-menu-on-header-pressed-bg;

                        &.is-disabled {
                            background-color: color-disabled($btn-menu-on-header-pressed-bg);
                        }
                    }
                }
            }
        }
    }

    /*  Size
        ========================================================================== */

    &-small {
        @include button-size(
            $btn-small-min-width,
            $btn-small-min-height,
            $btn-small-line-height,
            $btn-small-font-size
        );

        &,
        &#{$block}-density-md {
            padding: $btn-small-padding;
        }

        &#{$block}-density-sm {
            padding: $btn-small-padding--density-sm;
        }

        /*  Icon
           ========================================================================== */

        &#{$block}-icon {
            &-only {
                padding: 0;
            }
        }

        #{$block}-icon {
            display: inline-block;
            font-size: $icon-font-size-xs;

            &-left {
                margin-right: $btn-small-icon-margin;
            }

            &-right {
                margin-left: $btn-small-icon-margin;
            }
        }
    }

    /*  Type
    ========================================================================== */

    &-inline {
        display: inline;
        min-height: auto;
        padding: 0;
        border: none;
        vertical-align: baseline;
    }

    &-block {
        display: block;
        width: 100%;

        /* vertically space out multiple block buttons */
        + #{$block} {
            &-block {
                margin-top: grid-unit();
            }
        }
    }

    /*  Status
    ========================================================================== */

    &-status-danger {
        &#{$block} {
            &-primary {
                @include property-states(
                    'color',
                    $color-white,
                    $disabled-value: color-disabled($color-white)
                );
                @include property-states(
                    'background-color',
                    $color-danger,
                    $color-red-600,
                    $color-red-800,
                    color-disabled($color-danger),
                    $color-red-800
                );

                @include focus-visible {
                    border-color: $color-danger;
                    box-shadow: focus($color: $color-white);
                }
            }

            &-secondary {
                @include property-states(
                    'color',
                    $color-danger,
                    $color-red-600,
                    $color-red-800,
                    color-disabled($color-danger),
                    $color-red-800
                );
                @include property-states(
                    'border-color',
                    $color-danger,
                    $color-red-600,
                    $color-red-800,
                    color-disabled($color-danger),
                    $color-red-800
                );

                background-color: transparent;

                @include focus-visible {
                    border-color: $color-red-200;
                }
            }

            &-tertiary,
            &-quaternary {
                @include property-states(
                    'color',
                    $color-danger,
                    $color-red-600,
                    $color-red-800,
                    color-disabled($color-danger),
                    $color-red-800
                );

                @include focus-visible {
                    box-shadow: focus(false, $color-focus-on-dark);
                }
            }

            &-menu {
                @include property-states(
                    'color',
                    $color-danger,
                    $color-danger-text,
                    $color-danger-text,
                    color-disabled($color-danger),
                    $color-danger-text
                );

                @include property-states(
                    'background-color',
                    transparent,
                    $color-danger-hover-background,
                    $color-danger-pressed-background,
                    transparent,
                    $color-danger-pressed-background
                );

                @include focus-visible {
                    box-shadow: focus(false, $color-focus-on-dark);
                }
            }
        }
    }

    /*  Loader state
        ========================================================================== */

    &.has-loader {
        color: transparent;

        #{$block}-loader {
            @extend .absolute-center;
        }

        > *:not(#{$block}-loader) {
            opacity: 0;
            visibility: hidden;
        }
    }

    /*  Content alignment
        ========================================================================== */

    &-align-left {
        justify-content: left;
    }

    &-align-right {
        justify-content: right;
    }

    /*  Legacy
        TODO: remove this when legacy styling is deprecated
        ========================================================================== */

    &-default {
        @extend #{$block}-secondary;
    }

    &-link {
        @extend #{$block}-quaternary;
    }

    &-quarternary {
        @extend #{$block}-quaternary;
    }
}
