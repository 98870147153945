/*  ==========================================================================
    Status circle
    ========================================================================== */

@mixin status-circle--state-change($state) {
    @keyframes status-circle--state-change-#{$state} {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    animation: status-circle--state-change-#{$state} $animate-duration cubic-bezier(0.3, 0.27, 0.07, 1.64);
}
