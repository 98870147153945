/*  ==========================================================================
    Gradients
    ========================================================================== */

/*  Horizontal gradient, from left to right */
@mixin gradient-horizontal(
    $start-color: $color-black-300,
    $end-color: $color-black-900,
    $start-percent: 0%,
    $end-percent: 100%
) {
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
    background-repeat: repeat-x;
}

/*  Vertical gradient, from top to bottom */
@mixin gradient-vertical(
    $start-color: $color-black-300,
    $end-color: $color-black-900,
    $start-percent: 0%,
    $end-percent: 100%
) {
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
    background-repeat: repeat-x;
}

/* Diagonal gradient */
@mixin gradient-directional($start-color: $color-black-300, $end-color: $color-black-900, $deg: 45deg) {
    background-image: linear-gradient($deg, $start-color, $end-color);
    background-repeat: repeat-x;
}

/* Left to right three color gradient */
@mixin gradient-horizontal-three-colors(
    $start-color: $color-black-900,
    $mid-color: $color-black-600,
    $color-stop: 50%,
    $end-color: $color-black-300
) {
    background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
}

/* Top to bottom three color gradient */
@mixin gradient-vertical-three-colors(
    $start-color: $color-black-900,
    $mid-color: $color-black-600,
    $color-stop: 50%,
    $end-color: $color-black-300
) {
    background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
}

/* Radial inside to outside gradient */
@mixin gradient-radial($inner-color: $color-black-300, $outer-color: $color-black-900) {
    background-image: radial-gradient(circle, $inner-color, $outer-color);
    background-repeat: no-repeat;
}

/* Diagonally striped gradient */
@mixin gradient-striped($color: rgba($color-white, $color-opacity-low), $angle: 45deg) {
    background-image: linear-gradient(
        $angle,
        $color 25%,
        transparent 25%,
        transparent 50%,
        $color 50%,
        $color 75%,
        transparent 75%,
        transparent
    );
}
