/*  ==========================================================================
    Search Field Legacy
    ========================================================================== */

/*  Custom form controls
    TODO: legacy styles to be removed in next major version
    ========================================================================== */

/* search */
.search-control {
    position: relative;

    .search-icon {
        position: absolute;
        top: $search-control-icon-offset-top;
        left: $search-control-icon-offset-left;
        font-size: $search-control-icon-font-size;
        pointer-events: none;
    }

    .search-field {
        @extend %form-control;

        margin-right: 0; // reset default form field styling with 100% margin
        padding-left: $search-control-field-padding;
    }
}
