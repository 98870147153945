/*  ==========================================================================
    Triggers

    Styling for interaction areas
    ========================================================================== */

%touch-target {
    min-width: $min-touch-area;
    min-height: $min-touch-area;
    cursor: pointer;

    &.disabled {
        cursor: default;
    }
}

%trigger-layout {
    @extend %touch-target;

    position: relative;
    display: flex;
    flex-shrink: 0;
    padding: (($min-touch-area - $line-height-computed) * 0.5) grid-unit(1);
    text-align: left;
    place-content: center center;
    place-items: center center;
}

/* todo: refactor usages of legacy trigger  */
%trigger-layout-fixed {
    height: $min-touch-area;
    margin: $min-touch-area-spacing;
}

%trigger-style {
    border-radius: $border-radius;
    color: $color-text-soft;
    fill: $color-text-soft;

    @include icon(extend) {
        font-size: $icon-font-size-lg;
    }

    .trigger-icon {
        height: $icon-font-size-lg;
        font-size: $icon-font-size-lg;
    }

    &:hover {
        background: $color-hover-background;
        color: $color-hover-text;
        fill: $color-hover-text;
    }

    &:active,
    &.is-active {
        background: $color-pressed-background;
        color: $color-pressed-text;
        fill: $color-pressed-text;
    }

    &.disabled, // legacy class
    &.is-disabled {
        color: color-disabled($color-text-soft);
        fill: color-disabled($color-text-soft);
    }
}

%trigger {
    @extend %trigger-layout;
    @extend %trigger-style;
}
