/*  ==========================================================================
    Progress components
    ========================================================================== */

/*  Bar
    ========================================================================== */

@mixin progress-bar--size($rail-height, $value-font-size, $unit-font-size: $font-size-sm) {
    .progress-bar {
        &__value {
            font-size: $value-font-size;
        }

        &__unit {
            font-size: $unit-font-size;
        }

        &__rail {
            height: $rail-height;
            border-radius: $rail-height;
        }

        &__indicator {
            border-radius: $rail-height;
        }

        &__target-indicator {
            bottom: -#{$progress-bar__target-indicator_size-v};
        }
    }
}

/*  Circle
    ========================================================================== */

@mixin progress-circle--size($circle-size, $rail-size) {
    width: $circle-size;
    height: $circle-size;

    .progress-circle {
        &__overlay {
            top: $rail-size;
            left: $rail-size;
            width: $circle-size - (2 * $rail-size);
            height: $circle-size - (2 * $rail-size);
        }

        &__mask,
        &__indicator {
            width: $circle-size;
            height: $circle-size;
        }

        &__mask {
            clip: rect(0, $circle-size, $circle-size, $circle-size * 0.5);
        }

        &__indicator {
            clip: rect(0, $circle-size * 0.5, $circle-size, 0);
        }
    }
}
