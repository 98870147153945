/*  ==========================================================================
    Forms
    ========================================================================== */

/* Base */
@import 'form/form.component';
@import 'group/group.component';
@import 'controls/controls.component';
@import 'controls/controls.legacy.component';
@import 'label/label.component';
@import 'help/help.component';
@import 'control/control.component';
@import 'control/control.legacy.component';
@import 'radio/radio.component';
@import 'checkbox/checkbox.component';
@import 'search/search.legacy.component';
@import 'static/static.component';

/* Alternative structure */
@import 'inline/inline.component';

/* Validation */
@import 'form-validation/form-validation.component';
@import 'group-validation/group-validation.component';
@import 'form-validation/form-validation.legacy.component';
@import 'group-validation/group-validation.legacy.component';

/* Extra */
@import 'input-group/input-group.component';
@import 'input-group/input-group.legacy';
