/*  ==========================================================================
    States
    ========================================================================== */

/*  Property states
    ========================================================================== */

@mixin property-states(
    $property,
    $default-value,
    $hover-value: $default-value,
    $pressed-value: $default-value,
    $disabled-value: false,
    $active-value: false
) {
    #{$property}: $default-value;

    &:hover,
    &:focus {
        #{$property}: $hover-value;
    }

    &:active {
        #{$property}: $pressed-value;
    }

    @if $disabled-value {
        &.disabled, // TODO: deprecate usage in favor of :disabled pseudo class. Will be a breaking change.
        &.is-disabled,
        &:disabled {
            #{$property}: $disabled-value;
        }
    }

    @if $active-value {
        &.is-active {
            #{$property}: $active-value;
        }
    }

    @content;
}
