/*  ==========================================================================
    Fontawesome icons
    ========================================================================== */

@import '../../../vendor/fontawesome/functions';
@import '../../../vendor/fontawesome/variables';
@import '../../../vendor/fontawesome/mixins';
@import '../../../vendor/fontawesome/core';
@import '../../../vendor/fontawesome/icons';
@import '../../../vendor/fontawesome/duotone-icons';
@import '../../../vendor/fontawesome/fixed-width';
@import '../../../vendor/fontawesome/light';
@import '../../../vendor/fontawesome/regular';
@import '../../../vendor/fontawesome/solid';
@import '../../../vendor/fontawesome/duotone';
@import '../../../vendor/fontawesome/brands';

/*  The following icon styles will not be imported to reduce
    the amount of font icon files loaded.

    All the source files are available as assets so in order to add an icon style:
    1. Un-comment the style import below to allow the icon font to be loaded
    1. Add the style in the `FaIconType` model to render it in storybook

    ========================================================================== */

//@import '../../../vendor/fontawesome/thin';
//@import '../../../vendor/fontawesome/sharp-solid';
