/*  ==========================================================================
    Toaster
    ========================================================================== */

$toaster-height: grid-unit(21);
$toaster_width: $breakpoint-xs;
$toaster-margin: grid-unit();
$toaster_padding-v: grid-type-unit(3);
$toaster_padding-h: grid-type-unit(3);
$toaster__body_offset-top: grid-unit(-1);
$toaster__body_offset-bottom: -2px;
$toaster__status_margin-h: grid-type-unit(1);
$toaster__progress-indicator_duration: 6s;
$toaster__progress-indicator_bg: $color-accent-dark;
$toaster__progress_margin: grid-type-unit();
$toaster__progress_height: grid-type-unit();
$toaster__progress-rail_bg: $color-black-100;
$toaster__header_padding-right: 0;
$toaster__footer_offset: -($toaster__progress_margin * 1.5);
