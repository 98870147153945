/*  ==========================================================================
    Animation
    ========================================================================== */

.animated {
    animation-duration: $animate-duration;
    animation-fill-mode: $animation-fill-mode;

    &.transition {
        transition:
            transform $animate-duration $animation-easing,
            margin-left $animate-duration $animation-easing,
            padding-left $animate-duration $animation-easing,
            padding-top $animate-duration $animation-easing,
            top $animate-duration $animation-easing,
            left $animate-duration $animation-easing,
            width $animate-duration $animation-easing,
            height $animate-duration $animation-easing;
    }

    &.infinite {
        animation-iteration-count: infinite;
    }
}

/* Keyframes
   ========================================================================== */

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in-down {
    0% {
        opacity: 0;
        transform: translate3d(0, -$animate-fade-distance, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-in-left {
    0% {
        opacity: 0;
        transform: translate3d(-$animate-fade-distance, 0, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-in-right {
    0% {
        opacity: 0;
        transform: translate3d($animate-fade-distance, 0, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translate3d(0, $animate-fade-distance, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-out-down {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate3d(0, $animate-fade-distance, 0);
    }
}

@keyframes fade-out-left {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate3d(-$animate-fade-distance, 0, 0);
    }
}

@keyframes fade-out-right {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate3d($animate-fade-distance, 0, 0);
    }
}

@keyframes fade-out-up {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -$animate-fade-distance, 0);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/*  Fades
    ========================================================================== */

.fade-in {
    animation-name: fade-in;
}

.fade-in-down {
    animation-name: fade-in-down;
}

.fade-in-left {
    animation-name: fade-in-left;
}

.fade-in-right {
    animation-name: fade-in-right;
}

.fade-in-up {
    animation-name: fade-in-up;
}

.fade-out {
    animation-name: fade-out;
}

.fade-out-down {
    animation-name: fade-out-down;
}

.fade-out-left {
    animation-name: fade-out-left;
}

.fade-out-right {
    animation-name: fade-out-right;
}

.fade-out-up {
    animation-name: fade-out-up;
}

/*  Spins
    ========================================================================== */

.spin {
    z-index: $z-index-loader;
    transform-origin: 50% 50%;
    animation: spin $animate-duration-spin infinite linear;
}

/*  Select/input change animations
    ========================================================================== */

[animate-on-change] {
    transition: all $animate-duration;

    &.changed {
        border-color: $color-warning;
        background: rgba($color-warning, 0.3);
        box-shadow: 0 0 2px 2px rgba($color-warning, 0.6);
        transition: all 0.4s;
    }

    &.changed-up {
        border-color: $color-success;
        background: rgba($color-success, 0.3);
        box-shadow: 0 0 2px 2px rgba($color-success, 0.6);
    }

    &.changed-down {
        border-color: $color-danger;
        background: rgba($color-danger, 0.3);
        box-shadow: 0 0 2 2 rgba($color-danger, 0.6);
    }
}
