/*  ==========================================================================
    Theme
    ========================================================================== */

@mixin theme($key, $isBem: true) {
    $value: map-get($themes, $key);

    @if ($value) {
        @if $isBem {
            &--theme-on-#{$value},
            .has-theme-#{$value} & {
                @content;
            }
        } @else {
            &-theme-on-#{$value},
            .has-theme-#{$value} & {
                @content;
            }
        }
    } @else {
        @warn '#{$key} is not a valid theme argument';
    }
}
