/*  ==========================================================================
    Flex utilities
    ========================================================================== */

/// Generate flexbox utility classes
/// @group configuration
$enable-flex-utilities: false !default;

@if $enable-flex-utilities {
    @each $breakpoint in map-keys($breakpoints-utility-classes) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $breakpoints);

            /*  Direction

                The CSS flex-direction property specifies how flex items are placed in the
                flex container defining the main axis and the direction (normal or reversed).

                Note that the values row and row-reverse are affected by the directionality
                of the flex container. If its direction attribute is ltr, row represents the
                horizontal axis oriented from the left to the right, and row-reverse from
                the right to the left; if the direction attribute is rtl, row represents the axis
                oriented from the right to the left, and row-reverse from the left to the right.
               ========================================================================== */

            .flex-row#{$infix} {
                flex-direction: row !important;
            }
            .flex-column#{$infix} {
                flex-direction: column !important;
            }
            .flex-row-reverse#{$infix} {
                flex-direction: row-reverse !important;
            }
            .flex-column-reverse#{$infix} {
                flex-direction: column-reverse !important;
            }

            /*  Wrap

                The CSS flex-wrap property specifies whether the children are forced into a
                single line or if the items can be flowed on multiple lines.
               ========================================================================== */

            .flex-wrap#{$infix} {
                flex-wrap: wrap !important;
            }
            .flex-nowrap#{$infix} {
                flex-wrap: nowrap !important;
            }
            .flex-wrap-reverse#{$infix} {
                flex-wrap: wrap-reverse !important;
            }

            /*  Item: flex shorthand

                The flex CSS property is a shorthand property specifying the ability of a
                flex item to alter its dimensions to fill available space. Flex items can
                be stretched to use available space proportional to their flex grow factor
                or their flex shrink factor to prevent overflow.
               ========================================================================== */

            .flex-fill#{$infix} {
                flex: 1 1 auto !important;
            }
            .flex-item#{$infix} {
                flex: 1 0 auto !important;
            }
            .flex-equal#{$infix} {
                flex: 1 0 0 !important;
            }
            .flex-none#{$infix} {
                flex: 0 0 auto !important;
            }

            /*  Grow

                The CSS flex-grow property specifies how much the flex item will grow relative
                to the rest of the flex items in the flex container when positive free space
                is distributed.
                ========================================================================== */

            .flex-grow-0#{$infix} {
                flex-grow: 0 !important;
            }
            .flex-grow-1#{$infix} {
                flex-grow: 1 !important;
            }

            /*  Shrink

                The CSS flex-shrink property specifies how much the flex item will shrink
                relative to the rest of the flex items in the flex container when negative
                free space is distributed.
                ========================================================================== */

            .flex-shrink-0#{$infix} {
                flex-shrink: 0 !important;
            }
            .flex-shrink-1#{$infix} {
                flex-shrink: 1 !important;
            }

            /*  Basis

                The CSS flex-basis property specifies the flex basis which is the initial
                main size of a flex item. The property determines the size of the content-box
                unless specified otherwise using box-sizing.
               ========================================================================== */

            .flex-basis-0#{$infix} {
                flex-basis: 0 !important;
            }
            .flex-basis-auto#{$infix} {
                flex-basis: auto !important;
            }
            .flex-basis-full#{$infix} {
                flex-basis: 100% !important;
            }

            /*  Justify-content

                The CSS justify-content property defines how a browser distributes available
                space between and around elements when aligning flex items in the main-axis
                of the current line.

                The alignment is done after the lengths and auto margins are applied, meaning
                that, if there is at least one flexible item, with flex-grow different
                than 0, it will have no effect as there won't be any available space.
               ========================================================================== */

            .justify-content-start#{$infix} {
                justify-content: flex-start !important;
            }
            .justify-content-end#{$infix} {
                justify-content: flex-end !important;
            }
            .justify-content-center#{$infix} {
                justify-content: center !important;
            }
            .justify-content-between#{$infix} {
                justify-content: space-between !important;
            }
            .justify-content-around#{$infix} {
                justify-content: space-around !important;
            }

            /*  Align items

                The CSS align-items property aligns flex items of the current flex line the
                same way as justify-content but in the perpendicular (cross) direction.
               ========================================================================== */

            .align-items-start#{$infix} {
                align-items: flex-start !important;
            }
            .align-items-end#{$infix} {
                align-items: flex-end !important;
            }
            .align-items-center#{$infix} {
                align-items: center !important;
            }
            .align-items-baseline#{$infix} {
                align-items: baseline !important;
            }
            .align-items-stretch#{$infix} {
                align-items: stretch !important;
            }

            /*  Align content

                The CSS align-content property aligns a flex container's lines within
                the flex container when there is extra space on the cross-axis.
                This property has no effect on single line flexible boxes.
               ========================================================================== */

            .align-content-start#{$infix} {
                align-content: flex-start !important;
            }
            .align-content-end#{$infix} {
                align-content: flex-end !important;
            }
            .align-content-center#{$infix} {
                align-content: center !important;
            }
            .align-content-between#{$infix} {
                align-content: space-between !important;
            }
            .align-content-around#{$infix} {
                align-content: space-around !important;
            }
            .align-content-stretch#{$infix} {
                align-content: stretch !important;
            }

            /*  Item: align self

                The align-self CSS property aligns flex items of the current flex line
                overriding the align-items value. If any of the flex item's cross-axis margin
                is set to auto, then align-self is ignored.
               ========================================================================== */

            .align-self-auto#{$infix} {
                align-self: auto !important;
            }
            .align-self-start#{$infix} {
                align-self: flex-start !important;
            }
            .align-self-end#{$infix} {
                align-self: flex-end !important;
            }
            .align-self-center#{$infix} {
                align-self: center !important;
            }
            .align-self-baseline#{$infix} {
                align-self: baseline !important;
            }
            .align-self-stretch#{$infix} {
                align-self: stretch !important;
            }
        }
    }
}

/* Size
   ========================================================================== */

.flex-height-full {
    height: 100% !important;
}

.flex-height-half {
    height: 50% !important;
}

.flex-width-full {
    width: 100% !important;
}

.flex-width-half {
    width: 50% !important;
}

/*  Spacer
    Auto shrinking and growing spacer to fill space between flex children
    ========================================================================== */

.spacer {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: flex-start;
}
