/*  ==========================================================================
    Sidebar navigation
    ========================================================================== */

/* heading */
$nav-secondary-header-add-margin: grid-unit();

/* main */
$nav-secondary-item-link-margin: $navigation_vertical-spacing;
$nav-secondary-item-link-padding-left: grid-unit(2);
$nav-secondary-item-link-padding: grid-unit() grid-type-unit(3) grid-unit()
    $nav-secondary-item-link-padding-left;
$nav-secondary-item-link-nested-padding-left: grid-unit(3);
$nav-secondary-item-sublink-padding-left: $nav-secondary-item-link-padding-left +
    $nav-secondary-item-link-nested-padding-left;

$nav-secondary-item-link-icon-margin: grid-unit();

$nav-secondary-item-link-hover-bg: $color-hover-background;
$nav-secondary-item-link-hover-color: $color-hover-text;
$nav-secondary-item-link-pressed-bg: $color-pressed-background;
$nav-secondary-item-link-pressed-color: $color-pressed-text;
$nav-secondary-item-link-active-bg: transparent;
$nav-secondary-item-link-active-font-weight: $font-weight-bold;
$nav-secondary-item-link-active-icon-color: $color-selected;
$nav-secondary-item-link-disabled-bg: transparent;
$nav-secondary-item-link-disabled-color: color-disabled();

/* overlay */
$nav-secondary-overlay-item-link-margin: 0 grid-unit(2) 0 0;
