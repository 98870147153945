/*  ==========================================================================
    Table row
    ========================================================================== */

.table {
    $block: &;

    &__row {
        position: relative;
        height: 100%;
        border-radius: $table__cell_border-radius;

        > #{$block}__cell--sticky-header {
            display: none;
        }

        &-sticky-header--visible {
            > #{$block}__cell {
                display: none;
            }

            > #{$block}__cell--sticky-header {
                display: table-cell;
            }
        }

        &.is-highlighted {
            @include table__row--highlight($block, $color-green-blue-50, $color-green-blue-100);
        }
    }

    &--has-highlighted-selected-rows {
        #{$block}__row {
            &.is-selected {
                @include table__row--highlight($block);
            }
        }
    }
}
