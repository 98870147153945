/*  ==========================================================================
    Tag
    ========================================================================== */

@mixin tag-color-category($category) {
    &.tag {
        &--variant-solid {
            @include tag-variant-solid($category);
        }

        &--variant-flat {
            @include tag-variant-flat($category);
        }

        &--variant-outline {
            @include tag-variant-outline($category);
        }
    }
}

@mixin tag-variant-outline($category) {
    border-color: get-category-color($category, 'main');
    background-color: get-category-color($category, 'light');
    color: get-category-color($category, 'dark');

    .tag__trigger {
        color: get-category-color($category, 'dark');

        &:hover {
            color: get-category-color($category, 'main');
        }

        &:active {
            color: get-category-color($category, 'dark');
        }
    }
}

@mixin tag-variant-solid($category) {
    border-color: transparent;
    background-color: get-category-color($category, 'main');
    color: $color-white;

    .tag__trigger {
        color: $color-white;

        &:hover {
            color: get-category-color($category, 'accent');
        }

        &:active {
            color: get-category-color($category, 'light');
        }
    }
}

@mixin tag-variant-flat($category) {
    border-color: transparent;
    background-color: get-category-color($category, 'accent');
    color: get-category-color($category, 'dark');

    .tag__trigger {
        color: get-category-color($category, 'dark');

        &:hover {
            color: get-category-color($category, 'main');
        }

        &:active {
            color: get-category-color($category, 'dark');
        }
    }
}

@mixin tag-size($size, $isDefault: false) {
    @if $isDefault {
        &,
        &--size-#{$size} {
            min-width: map-deep-get($tag-sizes, $size, size);
            min-height: map-deep-get($tag-sizes, $size, size);
            padding: map-deep-get($tag-sizes, $size, padding);

            @content;
        }
    } @else {
        &--size-#{$size} {
            min-width: map-deep-get($tag-sizes, $size, size);
            min-height: map-deep-get($tag-sizes, $size, size);
            padding: map-deep-get($tag-sizes, $size, padding);

            @content;
        }
    }
}

@mixin tag-label-size($size) {
    .tag__label {
        min-width: #{map-deep-get($tag-sizes, $size, innersize) -
            (map-deep-get($tag-sizes, $size, padding-h) * 2)};
        padding: map-deep-get($tag-sizes, $size, label, padding);
    }
}

@mixin tag-trigger-size($size) {
    .tag__trigger {
        width: map-deep-get($tag-sizes, $size, trigger, size);
        height: map-deep-get($tag-sizes, $size, trigger, size);
        margin: map-deep-get($tag-sizes, $size, trigger, margin);
    }
}
