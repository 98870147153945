/*  ==========================================================================
    Tile
    ========================================================================== */

$tile-bg: $color-white;
$tile-padding: grid-unit(3);
$tile-padding-v-xs: grid-unit(1);
$tile-padding-h-xs: grid-type-unit(3);
$tile-padding-small: grid-unit(2);
$tile-padding-large: grid-unit(4);
$tile-border-radius: $border-radius;

/* Unstructured */
$tile-unstructured-padding: grid-unit(4);
$tile-unstructured-padding-small: grid-unit(3);

$tile-action-margin: grid-unit();
