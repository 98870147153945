/*  ==========================================================================
    Status circle
    ========================================================================== */

$status-circle-size: grid-type-unit(2);
$status-circle-size-md: grid-type-unit(3);
$status-circle-size-lg: grid-type-unit(5);
$status-circle-border-width: 2px;
$status-circle-border-width-md: 3px;
$status-circle-border-width-lg: 4px;
