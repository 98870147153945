/*  Presentation
    ========================================================================== */

/* Perfect circle */
.circle {
    border-radius: $border-radius-circle !important;
}

/* Rounded corners */
.rounded {
    border-radius: $border-radius !important;
}
