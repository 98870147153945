%absolute {
    &-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &-top-half {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-right {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    &-right-half {
        position: absolute;
        top: 50%;
        right: 25%;
        transform: translate(50%, -50%);
    }

    &-bottom {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &-bottom-half {
        position: absolute;
        bottom: 25%;
        left: 50%;
        transform: translate(-50%, 50%);
    }

    &-left {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &-left-half {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
    }

    &-top-right {
        position: absolute;
        top: 0;
        right: 0;
    }

    &-top-left {
        position: absolute;
        top: 0;
        left: 0;
    }

    &-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &-bottom-left {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
