/*  ==========================================================================
    Single side border radius
    ========================================================================== */

@mixin border-top-radius($radius: $border-radius) {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-right-radius($radius: $border-radius) {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin border-bottom-radius($radius: $border-radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius: $border-radius) {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
}
