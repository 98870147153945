/*  ==========================================================================
    Grid
    ========================================================================== */

@mixin grid-base() {
    .grid-item {
        min-height: $grid-item-min-h;
    }

    @supports (display: grid) {
        display: grid;
        grid-gap: $grid-item-gap;

        @include media-breakpoint-up(lg) {
            grid-gap: $grid-item-gap-lg;
        }
    }

    /*  Legacy flex implementation
        ========================================================================== */

    @supports not (display: grid) {
        @supports (display: -ms-grid) {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + #{$grid-item-gap});
            margin: -$grid-item-gap * 0.5;

            @include media-breakpoint-up(lg) {
                width: calc(100% + #{$grid-item-gap-lg});
                margin: -$grid-item-gap-lg * 0.5;
            }

            .grid-item {
                margin: ($grid-item-gap * 0.5);

                @include media-breakpoint-up(lg) {
                    margin: ($grid-item-gap-lg * 0.5);
                }
            }
        }
    }
}

@mixin grid($grid-item-size) {
    @supports (display: grid) {
        grid-template-columns: repeat(auto-fill, minmax($grid-item-size, 1fr));
    }

    @supports not (display: grid) {
        @supports (display: -ms-grid) {
            .grid-item {
                width: $grid-item-size;
            }
        }
    }
}
