/*  ==========================================================================
    Progress components
    ========================================================================== */

/* rail */
$progress-rail-bg: $color-black-100;
$progress-rail--theme-on-light-gray_bg: $color-black-200;
$progress-rail--theme-on-light-blue_bg: $color-blue-200;
$progress-rail--theme-on-dark-blue_bg: $color-blue-700;

/* Indicator */
$progress-indicator-bg: $color-accent-dark;
$progress-indicator--theme-on-dark-blue_bg: $color-accent-light;

/*  Bar
    ========================================================================== */
$progress-bar-text-offset: grid-unit();

/* Value */
$progress-bar__value-font-weight: $font-weight-bold;
$progress-bar__value--target_flex-basis: calc(
    100% - ((var(--progress-bar__value--target) * 1%) - #{$progress-bar-text-offset} * 1)
);

/* Target */
$progress-bar__target-indicator_size-h: grid-type-unit();
$progress-bar__target-indicator_size-v: 6px;

/* Description */
$progress-bar__description-text-spacing: grid-unit(2);
$progress-bar__description-color: $color-text-soft;
$progress-bar__description--has-target_margin-top: $progress-bar-text-offset +
    $progress-bar__target-indicator_size-v - 2px; // 2px visual adjustment

/* Sizes */
$progress-bar--sm-height: grid-type-unit(0.5);
$progress-bar--sm-font-size: $font-size-sm;
$progress-bar--sm_unit-font-size: $font-size-xs;
$progress-bar--md-height: grid-type-unit();
$progress-bar--md-font-size: $font-size-md;
$progress-bar--md_unit-font-size: $font-size-sm;
$progress-bar--lg-height: grid-type-unit();
$progress-bar--lg-font-size: $font-size-lg;
$progress-bar--lg_unit_font-size: $font-size-md;

/*  Circle
    ========================================================================== */

$progress-circle-increment: 180deg * 0.01;

/* Overlay */
$progress-circle__overlay-background: $color-white;
$progress-circle--theme-on-light-gray__overlay-background: $color-black-50;
$progress-circle--theme-on-light-blue__overlay-background: $color-blue-100;
$progress-circle--theme-on-dark-blue__overlay-background: $color-blue-800;

/* Indicator */
$progress-circle__indicator-background: $color-accent-dark;

/* Sizes */
$progress-circle-sm-size: $icon-font-size-md;
$progress-circle-sm-rail-size: $icon-size-modifier-sm;
$progress-circle-md-size: $icon-font-size-xl;
$progress-circle-md-rail-size: $icon-size-modifier-md;
$progress-circle-lg-size: $icon-font-size-4xl;
$progress-circle-lg-rail-size: $icon-size-modifier-lg;
