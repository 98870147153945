/*  ==========================================================================
    Overlay
    ========================================================================== */

@mixin overlay($element-on-top-of-overlay, $trigger-class: is-expanded) {
    pointer-events: none;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba($color-overlay, $color-opacity-low);
        opacity: 0;
        pointer-events: none;
        transition: opacity $animate-duration ease-in;
    }

    .#{$element-on-top-of-overlay} {
        pointer-events: auto;
    }

    &.#{$trigger-class} {
        pointer-events: auto;

        &::after {
            opacity: 1;
            pointer-events: auto;
            transition: opacity $animate-duration-medium ease-out;
        }
    }

    /* Add custom styling to mixin */
    @content;
}
