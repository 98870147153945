/*  ==========================================================================
    Placeholder legacy
    ========================================================================== */

.placeholder {
    @extend .drop-area;

    &.on-blue {
        @extend .drop-area--theme-on-light-blue;
    }
}
