/*  ==========================================================================
    List group
    ========================================================================== */

$list-bg: $color-white;
$list-border: $color-divider;
$list-border-width: 1px;
$list-border-radius: $border-radius;

/* item */
$list-item-title-font-weight: $font-weight-semibold;
$list-item-text-color: $color-text-soft;
$list-item--selected-bg: $color-hover-background;

/* icon */
$list-icon-font-size: $icon-font-size-lg;

/* action indicator */
$list-action-indicator-size: grid-unit(3);
$list-action-indicator-font-size: $icon-font-size-xxs;

/* Draggable */
$list-item-draggable_margin: grid-unit(1);
$list-item-draggable_padding-h: grid-unit(1);
$list-item-draggable_bg: $color-blue-100;
$list-item-draggable-hover_bg: $color-blue-200;
$list-item-draggable-pressed_bg: $list-item-draggable-hover_bg;

/* area */
$list-area-margin: grid-unit(2);

/* states */
$list-hover-color: $color-hover-text;
$list-pressed-color: $color-pressed-text;
$list-disabled-color: $color-disabled;

/* size */

$list-size-md-item-padding-v: ($touch-area - $line-height-computed) * 0.5;
$list-size-sm-item-padding-v: grid-unit();
$list-size-xs-item-padding-v: grid-type-unit();

$list-size-md-horizontal-padding: grid-type-unit(3);
$list-size-sm-horizontal-padding: grid-type-unit(2);
$list-size-xs-horizontal-padding: grid-type-unit(1);

$list-size-md-item-draggable_padding-v: grid-unit(1);
$list-size-sm-item-draggable_padding-v: grid-type-unit(1);
$list-size-xs-item-draggable_padding-v: 2px;
