/*  ==========================================================================
    Well
    ========================================================================== */

.well {
    padding: $well-padding;
    border-radius: $border-radius;
    background-color: $well-bg;
}

/*  Size
    ========================================================================== */

.well-sm {
    padding: $well-padding-sm;
}

.well-xs {
    padding: $well-padding-xs;
}
