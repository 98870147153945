/*  ==========================================================================
    Actions
    ========================================================================== */

/*  Content sidebar version
    ========================================================================== */

$content-actions-sidebar-width: grid-unit(18);
$content-actions-sidebar-margin: 0 0 grid-unit(2) 0;
$content-actions-sidebar-offset-top: grid-unit(2);
$content-actions-sidebar-visible-header-offset-top: $header-h + $content-actions-sidebar-offset-top;

$content-actions-sidebar-item-spacing: grid-unit();

$content-actions-sidebar-item-default-hover-bg: $color-hover-background;
$content-actions-sidebar-item-default-hover-color: $color-hover-text;

$content-actions-sidebar-item-default-active-bg: $color-pressed-background;
$content-actions-sidebar-item-default-active-color: $color-pressed-text;

/*  Content floating version
    ========================================================================== */

$content-actions-floating-height: $min-touch-area;
$content-actions-floating-offset-vertical: grid-unit(2);
$content-actions-floating-offset-horizontal: grid-unit(2);
$content-actions-floating-offset-horizontal-sm: grid-unit(3);
$content-actions-floating-margin: 0 $content-actions-floating-offset-horizontal
    $content-actions-floating-offset-vertical $content-actions-floating-offset-horizontal;
$content-actions-floating-menu-offset: grid-unit();
$content-actions-floating-menu-toggle-offset: grid-unit();
