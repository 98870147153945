/*  ==========================================================================
    Content header
    ========================================================================== */

$content-header-padding-vertical: grid-type-unit(3);
$content-header-padding-sm-vertical: grid-unit(2);
$content-header-padding-md-vertical: grid-unit(3);
$content-header-padding: $content-header-padding-vertical $content-padding-horizontal;
$content-header-padding-sm: $content-header-padding-sm-vertical $content-padding-horizontal-sm;
$content-header-padding-md: $content-header-padding-md-vertical $content-padding-horizontal-md;

$content-header-margin: 0;

/*  Sections
    ========================================================================== */

$content-header-start-spacing-vertical-between: grid-unit(2);

$content-header-mid-spacing-top: grid-unit(2);
$content-header-mid-spacing-top-lg: grid-unit(3);

$content-header-end-spacing-top: grid-unit(3);
$content-header-end-spacing-top-lg: grid-unit(4);
$content-header-end-spacing-bottom: grid-unit(-2);
$content-header-end-spacing-bottom-lg: grid-unit(-1);

/*  Elements
    ========================================================================== */

/* toolbar */
$content-header-toolbar-action-spacing: grid-unit();

/*  Hero header
    ========================================================================== */

$content-header--hero_background-color: $color-bg-dark;
$content-header--hero_color: $color-white;
$content-header--hero_margin-bottom: grid-unit(4);

/* elements */
$content-header--hero__trigger_margin: grid-unit(1);

/* offset */
$content-header--hero-offset_margin-bottom: grid-unit(-4);
$content-header--hero-offset_padding-bottom: grid-unit(6);

/* base hero style */
%hero-header-base-styling {
    background-color: $content-header--hero_background-color;
}
