/*  ==========================================================================
    Sidebar local right
    ========================================================================== */

$sidebar-local-right-width: grid-unit(48);

/* Header */
$sidebar-local-right-header-height: 84px; // Set natural height of header to use for animation
$sidebar-local-right-header-padding: grid-unit(2) grid-type-unit(1) grid-unit(2) grid-unit(2);
$sidebar-local-right-header-level-up-margin: 0 grid-unit() 0 grid-unit(-1);
$sidebar-local-right-header-subtitle-offset: grid-type-unit();
$sidebar-local-right-header-subtitle-icon-offset: grid-type-unit(3);
$sidebar-local-right-header-subtitle-icon-navigation-offset: grid-type-unit();
$sidebar-local-right-close-offset-top: grid-type-unit();
$sidebar-local-right-close-font-size: $icon-font-size-base;
$sidebar-local-right-toggle-hint-offset-top: grid-type-unit();
$sidebar-local-right-toggle-hint-font-size: $icon-font-size-lg;
$sidebar-local-right-toggle-hint-color: $color-text-muted;

/* Main */
$sidebar-local-right-main-padding: grid-unit() grid-unit(2);

/* Footer */
$sidebar-local-right-footer-padding: grid-unit(2);
