/*  ==========================================================================
    Overrides of external !default variables
    ========================================================================== */

/*  Angular CDK overlay
    ========================================================================== */

/* Z-index */
$cdk-z-index-overlay-container: $z-index-dialog;
$cdk-z-index-overlay: $cdk-z-index-overlay-container;
$cdk-z-index-overlay-backdrop: $cdk-z-index-overlay-container;

/* Background color for all of the backdrops */
$cdk-overlay-dark-backdrop-background: rgba($color-overlay, $color-opacity-low);

/* Background animation */
$backdrop-animation-duration: $animate-duration-medium;
$backdrop-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
