/*  ==========================================================================
    Custom property
    ========================================================================== */

@mixin custom-property($property, $value, $fallback-value) {
    @if ($fallback-value) {
        #{$property}: var($value, $fallback-value);
    } @else {
        #{$property}: var($value);
    }
}
