/*  ==========================================================================
    Elements
    ========================================================================== */

/* links */
$link-color: $color-text;
$link-hover-color: $color-hover-text;
$link-pressed-color: $color-pressed-text;
$link-disabled-color: color-disabled($link-color);
$link-text-decoration: underline;

/* Vertical spacing */
$plain-text-margin-h1: grid-type-unit(18);
$plain-text-margin-h2: grid-type-unit(12);
$plain-text-margin-h3: grid-type-unit(8);
$plain-text-margin-h4: grid-type-unit(6);
$plain-text-margin-h5: grid-type-unit(6);
$plain-text-margin-h6: grid-type-unit(6);
$plain-text-margin-bottom-heading: grid-type-unit(4);
$plain-text-margin-paragraph: grid-type-unit(4);
$plain-text-margin-list: grid-type-unit(4);
$plain-text-margin-divider: grid-type-unit(6);

$plain-text-small-margin-h1: grid-type-unit(11);
$plain-text-small-margin-h2: grid-type-unit(7);
$plain-text-small-margin-h3: grid-type-unit(5);
$plain-text-small-margin-h4: grid-type-unit(4);
$plain-text-small-margin-h5: grid-type-unit(4);
$plain-text-small-margin-h6: grid-type-unit(4);
$plain-text-small-margin-bottom-heading: grid-type-unit(2);
$plain-text-small-margin-paragraph: grid-type-unit(2);
$plain-text-small-margin-list: grid-type-unit(2);
$plain-text-small-margin-divider: grid-type-unit(4);

/*  Emphasis
    ========================================================================== */

%emphasis-xs {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    line-height: $line-height-xs;
}

%emphasis-sm {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: $line-height-sm;
}

%emphasis-md {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    line-height: $line-height-md;
}

%emphasis-lg {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: $line-height-lg;
}

%emphasis-xl {
    font-family: $text-large-font-family;
    font-size: $font-size-xl;
    font-weight: $text-large-font-weight;
    line-height: $line-height-xl;
}

%emphasis-2xl {
    font-family: $text-large-font-family;
    font-size: $font-size-2xl;
    font-weight: $text-large-font-weight;
    line-height: $line-height-2xl;
}

%emphasis-3xl {
    font-family: $text-large-font-family;
    font-size: $font-size-3xl;
    font-weight: $text-large-font-weight;
    line-height: $line-height-3xl;
}

%emphasis-4xl {
    font-family: $text-large-font-family;
    font-size: $font-size-4xl;
    font-weight: $text-large-font-weight;
    line-height: $line-height-4xl;
}

/*  Headings
    ========================================================================== */

%heading-1 {
    @extend %emphasis-2xl;
}

%heading-2 {
    @extend %emphasis-xl;
}

%heading-3 {
    @extend %emphasis-lg;
}

%heading-4,
%heading-5,
%heading-6 {
    @extend %emphasis-md;
}

/*  Links
    ========================================================================== */

%text-link {
    @include property-states('color', $link-color, $link-hover-color, $link-pressed-color);

    text-decoration: $link-text-decoration;

    @include focus-visible {
        box-shadow: focus(false);
    }
}

/*  Lists
    ========================================================================== */

%list {
    padding-left: $plain-text-margin-list;
    line-height: $line-height-md-extra;
}

%list-nested {
    margin-top: 0;
}
