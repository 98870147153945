/*  ==========================================================================
    Content view filter
    ========================================================================== */

#content-view-filter {
    position: sticky;
    z-index: $z-index-filter-bar;
    top: $header-h;
    display: flex;
    overflow: hidden;
    margin: $content-view-filter-margin;
    padding: $content-view-padding;
    border-bottom: $content-view-border-w solid $content-view-border-color;
    background: $content-view-bg;
    transition: top $layout-animation-vertical-speed-out ease-in;

    .view-group {
        @include media-breakpoint-down(sm) {
            &:nth-child(n + 2) {
                display: none;
            }
        }
    }

    /* Individual filters */
    .view-filter {
        @extend %form-control;
        @extend %form-control-on-blue;

        width: auto;
    }

    .search-field {
        @extend %form-control-on-blue;
    }

    /* trigger mobile view overflow menu */
    .view-filter-overflow {
        margin-left: auto;
    }

    .view-filter-overflow-trigger {
        @extend .btn;
        @extend .btn-secondary;

        margin-left: grid-unit(2);

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    /*  Affected elements
        ========================================================================== */

    .header-hidden & {
        @include media-breakpoint-down(sm) {
            top: 0;
            transition: top $layout-animation-vertical-speed-in ease-out;
        }
    }

    + #content-wrapper {
        margin-top: $content-view-bottom-offset;

        @include media-breakpoint-up(sm) {
            margin-top: $content-view-bottom-offset-lg;
        }
    }

    /* adjust content actions position */
    ~ #content-wrapper #content-sidebar-actions {
        top: $header-h + $content-view-h + $content-view-bottom-offset;

        .header-hidden & {
            @include media-breakpoint-down(sm) {
                top: $content-view-h + $content-view-bottom-offset;
            }
        }
    }
}
