/*  ==========================================================================
    Header
    ========================================================================== */

@mixin header--size($breakpoint, $max-side-height, $block: &) {
    &--size-#{$breakpoint} {
        #{$block} {
            &__main-start,
            &__main-end {
                max-height: $max-side-height;
            }
        }
    }
}

@mixin header-button-offset($breakpoint, $line-height) {
    $offset: ($line-height - $component-size-base) * 0.5;

    @if $offset != 0 {
        .header--size-#{$breakpoint} & {
            margin-top: $offset;
            margin-bottom: $offset;
        }
    }
}
