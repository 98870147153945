/*  ==========================================================================
    Wizard Stepper
    ========================================================================== */

/*  Header
    ========================================================================== */

$wizard-stepper__header_margin-bottom: grid-type-unit(1);

/*  Step
    ========================================================================== */

$step_size: $component-size-base;

/* colors */
$step_color: $color-blue-700;
$step-disabled_color: $color-black-100;

/* line */
$step__line_width: grid-type-unit();

/* dot */
$step__dot_border-width: grid-type-unit();
$step--static__dot_size: grid-unit(1);
$step--interactive__dot_size: grid-type-unit(3);

/* label */
$step__label_padding-h: grid-unit(3);
$step__label_margin-v: grid-type-unit();
$step__label_color: $color-text-soft;
$step__label-disabled_color: $color-text-muted;

/* icon */
$step__icon_font-size: $icon-font-size-lg;
$step__icon_width: 1.25;
$step--interactive__icon-offset: ($step--interactive__dot_size * 0.5) -
    (($step__icon_font-size * $step__icon_width) * 0.5);

/*  Step Count
    ========================================================================== */

$step-count_margin-h: grid-type-unit();
$step-count-bullet_color: $color-black-300;
$step-count-bullet_margin-h: grid-unit();
