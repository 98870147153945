/*  ==========================================================================
    Dialog stepped
    ========================================================================== */

/*  Header
    ========================================================================== */

@use 'sass:math';

$dialog-stepper-margin: grid-unit() 0 0 0;

/*  Indicator
    ========================================================================== */

$dialog-stepper-indicator-size: grid-type-unit(3);
$dialog-stepper-indicator-margin: (grid-type-unit(2) - $dialog-stepper-indicator-size)
    (-$dialog-content-spacing - $dialog-stepper-indicator-size)
    (grid-type-unit(1) - $dialog-stepper-indicator-size)
    (-$dialog-content-spacing - $dialog-stepper-indicator-size);
$dialog-stepper-indicator-padding: 0 $dialog-content-spacing;
$dialog-stepper-indicator-color: $color-selected;

/* states */
$dialog-stepper-indicator-completed-trigger-bg-color: $color-blue-100;
$dialog-stepper-indicator-active-trigger-bg-color: $dialog-stepper-indicator-color;

/* line */
$dialog-stepper-indicator-line-color: $dialog-stepper-indicator-color;
$dialog-stepper-indicator-line-width: $border-width-regular;
$dialog-stepper-indicator-line-style: solid;
$dialog-stepper-indicator-disabled-line-style: dashed;

/* trigger */
$dialog-stepper-indicator-trigger-bg-color: $color-white;
$dialog-stepper-indicator-trigger-border-color: $dialog-stepper-indicator-color;
$dialog-stepper-indicator-trigger-border-width: $border-width-regular;

/* trigger states */
$dialog-stepper-indicator-trigger-hover-border-width: math.div($dialog-stepper-indicator-size, 3);
$dialog-stepper-indicator-trigger-active-border-width: $dialog-stepper-indicator-size * 0.5;
