/*  ==========================================================================
    Well
    ========================================================================== */

.divider {
    @include divider-color();
    @include divider-size($divider-md-margin 0);

    overflow: hidden;
    height: $divider-height;

    &-responsive {
        @include divider-size(
            $divider-md-margin 0,
            (
                lg: $divider-lg-margin 0,
            )
        );
    }

    /*  Size
    ========================================================================== */

    &-sm {
        @include divider-size($divider-sm-margin 0);

        &.divider-responsive {
            @include divider-size(
                $divider-sm-margin 0,
                (
                    lg: $divider-md-margin 0,
                )
            );
        }
    }

    &-lg {
        @include divider-size($divider-lg-margin 0);

        &.divider-responsive {
            @include divider-size(
                $divider-lg-margin 0,
                (
                    lg: $divider-xl-margin 0,
                )
            );
        }
    }

    /*  Theme
        ========================================================================== */

    @include theme('light-blue') {
        @include divider-color($divider-on-blue-color);
    }
}
