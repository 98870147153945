/*  ==========================================================================
    Colors
    ========================================================================== */

/*  Color modifiers
    ========================================================================== */

/* Opacity */
$color-opacity-max: 0.96;
$color-opacity-max-inverted: 1 - $color-opacity-max;
$color-opacity-high: 0.87;
$color-opacity-medium: 0.6;
$color-opacity-low: 0.33;

/*  Color names
        > Assign a color to a variable
    ========================================================================== */

$color-black: $color-black-1000;
$color-blue: $color-blue-800;
$color-green-blue: $color-green-blue-400;
$color-teal: $color-teal-500;
$color-purple: $color-purple-900;
$color-green: $color-green-900;
$color-orange: $color-orange-900;
$color-red: $color-red-900;

/*  Color implementation
        > Add semantic implementation variables of existing colors
    ========================================================================== */

/* Branding */
$color-main: $color-blue-800;
$color-accent-dark: $color-green-blue-600;
$color-accent-light: $color-green-blue-400;

/* Typography */
$color-text: $color-black-1000;
$color-text-soft: $color-black-600;
$color-text-muted: $color-black-400;
$color-heading: $color-blue-800;
$color-source-code: $color-red-700;

/* Distinction */
$color-border: $color-black-200;
$color-divider: $color-border; // TODO maybe legacy and deprecate?
$color-overlay: $color-black-1000;
$color-shadow: $color-black-1000;

/* States */
$color-selected: $color-blue-800;
$color-focus: $color-purple-700;
$color-disabled: color-disabled();

$color-text-selection-bg: $color-blue-900;
$color-text-selection-text: $color-white;

$color-hover-text: $color-blue-700;
$color-hover-background: $color-blue-50;
$color-pressed-text: $color-blue-800;
$color-pressed-background: $color-blue-100;
$color-active-background: $color-pressed-background;

/* Status */
$color-info: $color-blue-500;
$color-info-background: $color-blue-50;
$color-info-border: $color-info;
$color-info-text: $color-blue-700;
$color-info-hover-background: $color-hover-background;
$color-info-pressed-background: $color-pressed-background;

$color-danger: $color-red-600;
$color-danger-background: $color-red-50;
$color-danger-border: $color-danger;
$color-danger-text: $color-red-700;
$color-danger-hover-background: $color-red-100;
$color-danger-pressed-background: $color-red-200;

$color-warning: $color-orange-400;
$color-warning-background: $color-orange-50;
$color-warning-border: $color-warning;
$color-warning-text: $color-orange-600;
$color-warning-hover-background: $color-orange-100;
$color-warning-pressed-background: $color-orange-200;

$color-success: $color-green-500;
$color-success-background: $color-green-50;
$color-success-border: $color-success;
$color-success-text: $color-green-700;
$color-success-hover-background: $color-green-100;
$color-success-pressed-background: $color-green-200;

/* Call to action */
$color-cta-primary: $color-focus;
$color-cta-secondary: $color-accent-dark;

/*  Themes
    ========================================================================== */

$color-bg-light: $color-black-50;
$color-bg-dark: $color-blue-800;

/*  Dark theme
    ========================================================================== */

/* Typography */
$color-heading-on-dark: $color-white;
$color-text-on-dark: rgba($color-white, $color-opacity-high);
$color-text-soft-on-dark: rgba($color-white, $color-opacity-medium);
$color-text-muted-on-dark: rgba($color-white, $color-opacity-low);

/* States */
$color-selected-on-dark: $color-white;
$color-focus-on-dark: $color-white;
$color-disabled-on-dark: color-disabled($color-text-on-dark);

$color-text-selection-bg-on-dark: $color-blue-700;
$color-text-selection-text-on-dark: $color-white;

/*  Dark blue theme
    ========================================================================== */

$color-dark-blue: $color-blue-800;
$color-dark-blue--emphasis: $color-blue-900;
$color-hover-text-on-dark-blue: $color-blue-200;
$color-hover-background-on-dark-blue: $color-hover-text-on-dark-blue;
$color-pressed-text-on-dark-blue: $color-blue-400;
$color-pressed-background-on-dark-blue: $color-pressed-text-on-dark-blue;
