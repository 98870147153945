/*  ==========================================================================
    Table full width first column
    ========================================================================== */

.table {
    $block: &;

    &--has-full-width-first-column #{$block}__row--full-width {
        display: none;
    }

    &--is-full-width-first-column #{$block}__row--full-width {
        display: revert;

        &--first #{$block}__cell {
            border-top-color: $table_border-color;
        }

        #{$block}__cell {
            &-item {
                position: sticky;
                z-index: $table--sticky-column__cell_z-index;
                left: $table--editable__cell_padding-horizontal + $table__border_width;
                width: 100%;
                max-width: calc(
                    var(--table--visible_width) - #{2 *
                        ($table--editable__cell_padding-horizontal + $table__border_width)}
                );
                font-weight: $font-weight-semibold;
                transform: translateY($table--is-full-width-first-column_visual-offset);
            }

            &--editable {
                #{$block}__cell-item {
                    left: 0;
                    max-width: var(--table--visible_width);
                }
            }
        }

        &#{$block}--density-sm #{$block}__row--full-width #{$block}__cell {
            &-item {
                left: $table--editable--density-sm__cell_padding-horizontal + $table__border_width;
                max-width: calc(
                    var(--table--visible_width) - #{2 *
                        ($table--editable--density-sm__cell_padding-horizontal + $table__border_width)}
                );
            }

            &--editable #{$block}__cell {
                &-item {
                    left: 0;
                    max-width: var(--table--visible_width);
                }

                &--editable:hover {
                    border-color: $color-selected;
                }

                &--editable:hover,
                &--editable:focus-within,
                &.has-focus {
                    z-index: $z-index-focus;
                    border-color: $color-focus;
                }
            }

            &--collapsed #{$block}__row--full-width #{$block}__cell:first-child #{$block}__cell-item {
                left: 0;
            }
        }
    }
}
