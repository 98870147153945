/*  ==========================================================================
    Text
    ========================================================================== */

/*  Reset
    ========================================================================== */

/// Reset all text properties
/// @group text
/// @output
/// @example
///     @include text-overflow;
@mixin text-reset() {
    font-family: $font-family-base;
    // We deliberately do NOT reset font-size.
    font-style: normal;
    font-weight: normal;
    line-height: $line-height-base;
    text-align: left; // Fallback for where `start` is not supported
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
}

/*  Word wrap
    ========================================================================== */
@mixin word-wrap() {
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/*  Overflow
    ========================================================================== */

/// Requires inline-block or block for proper styling
/// @group text
/// @output
/// @example
///     @include text-overflow;
@mixin text-overflow($is-important: false) {
    @if ($is-important) {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    } @else {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
