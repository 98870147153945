/*  ==========================================================================
    Image
    ========================================================================== */

/* Responsive images (ensure images don't scale beyond their parents) */
.img-responsive {
    @include img-responsive;
}

/**
 * Image thumbnails
 */

.img-thumbnail {
    @include img-responsive(inline-block);

    padding: $img-thumbnail-padding;
    border: 1px solid $img-thumbnail-border;
    border-radius: $img-thumbnail-border-radius;
    line-height: $line-height-base;
    background-color: $img-thumbnail-bg;
    transition: all $animate-duration-short ease-in-out;
}
