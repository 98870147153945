/*  ==========================================================================
    Content
    ========================================================================== */

/*  Main wrapper
    ========================================================================== */

#main-content-wrapper {
    display: flex;
    min-height: calc(100vh - #{$header-h});
    min-height: calc(100dvh - #{$header-h});

    .has-notification-bar & {
        min-height: calc(100vh - #{$header-h + $notification-bar-height});
        min-height: calc(100dvh - #{$header-h + $notification-bar-height});
    }

    &::after {
        content: '';
        position: fixed;
        top: 0;
        background: rgba($color-overlay, $color-opacity-low);
        opacity: 0;
        transition: opacity $animate-duration ease-in;

        @supports (pointer-events: none) {
            width: 100%;
            height: 100vh;
            height: 100dvh;
            pointer-events: none;
        }
    }

    &.constrained {
        #content {
            max-width: $main-content-wrapper-constrained-max-width;
        }
    }
}

/*  Main content
    ========================================================================== */

#main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: $main-content-w-min;
    max-width: $main-content-w-max;

    // TODO: remove when replace with Tertiary button
    .page-overview-trigger {
        @extend .btn;
        @extend .btn-tertiary;

        font-weight: $font-weight-regular;
        text-align: left;

        .page-overview-trigger-icon {
            @extend .btn-icon;
            @extend .btn-icon-left;
        }
    }
}

/*  Main header
    ========================================================================== */

#main-content-header {
    display: flex;
    margin-bottom: $main-content-header-margin-bottom;
    padding: $main-content-header-padding-top $content-padding-horizontal 0 $content-padding-horizontal;
    background-color: $main-content-header-bg;

    /* Make current structure work with new header styling */
    > *:not(#main-content-header__inner) {
        margin-bottom: $main-content-header-children-spacing;
    }

    &__inner {
        display: flex;
        flex: 1 1 auto;

        @include media-breakpoint-down(xs) {
            padding-right: $content-padding-horizontal;
            padding-left: $content-padding-horizontal;
        }
    }

    @include media-breakpoint-up(sm) {
        padding-right: $content-padding-horizontal-sm;
        padding-left: $content-padding-horizontal-sm;
    }

    @include media-breakpoint-up(md) {
        padding-right: $content-padding-horizontal-md;
        padding-left: $content-padding-horizontal-md;

        .sidebar-toggle-wrapper {
            display: none;
        }
    }

    /*  Main header
        ========================================================================== */

    &.is-hero {
        @extend %hero-header-base-styling;

        // TODO: remove when replace with Tertiary button
        .page-overview-trigger {
            @extend .btn-theme-on-dark-blue;

            margin-bottom: $content-header--hero__trigger_margin;
        }

        .page-title {
            color: $content-header--hero_color;
        }
    }

    /*  Legacy
        TODO: legacy styling to be deprecated in next major version
        ========================================================================== */

    .page-title {
        flex: 1 1 auto;
        padding: $main-content-header-title-padding;
        font-size: $main-content-header-title-font-size;
        font-weight: $main-content-header-title-font-weight;
        line-height: $main-content-header-title-line-height;
        color: $main-content-header-title-color;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .sidebar-toggle-wrapper {
        + .page-title {
            margin-left: $main-content-header-sidebar-toggle-title-offset;
        }
    }

    // TODO: when componentized page-overview-trigger can be replaced with .btn
    .page-overview-trigger + .page-title {
        margin-left: $main-content-header-overview-trigger-title-offset;
    }
}

/*  Main inner
    ========================================================================== */

#main-content-inner {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: $main-content-inner-padding;

    @include media-breakpoint-down(sm) {
        padding-bottom: calc(#{$main-content-inner-extra-spacing-bottom + $content-actions-floating-height});

        /* make space for the bottom fixed navigation and floating action buttons */
        .has-primary-nav & {
            padding-bottom: calc(
                #{$main-content-inner-extra-spacing-bottom + $content-actions-floating-height +
                    $navigation-primary-fixed-height}
            );
        }
    }
}

/*  Content wrapper
    ========================================================================== */

#content-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: $content-padding;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        padding: $content-padding-sm;
    }

    @include media-breakpoint-up(md) {
        padding: $content-padding-md;
    }
}

/*  Content
    ========================================================================== */

#content {
    @include word-wrap;

    position: relative;
    flex: 1 1 100%;
    min-width: 0; // By default, flex items won’t shrink below their minimum content size (see https://www.w3.org/TR/css-flexbox/#flex-common and https://www.w3.org/TR/css-flexbox/#min-size-auto)
    max-width: 100%;
}
