/*  ==========================================================================
    Notification bar
    ========================================================================== */

// todo: cleanup (icon color + border)

$notification-bar-height: $component-size-base;
$notification-bar-font-size: $font-size-sm;

$notification-bar-main-padding-v: (($notification-bar-height - $line-height-computed) * 0.5) -
    $notification-border-width;
$notification-bar-main-line-height: $line-height-sm-computed;
$notification-bar-main-show-more-line-height: $btn-line-height;

// overflow cuts off the 12 px line-height
$notification-bar-toggle-label-line-height: 14px;

$notification-bar-overflow-max-width: $breakpoint-xs-mid;
$notification-bar-overflow-bg: transparent;
$notification-bar-overflow-padding: grid-unit();
$notification-bar-overflow-padding-xs: grid-type-unit();
$notification-bar-overflow-max-height-fallback: calc(
    100vh - #{$notification-bar-height + $header-border-top}
);
$notification-bar-overflow-max-height: calc(100dvh - #{$notification-bar-height + $header-border-top});

$notification-bar-overflow-item-padding-h: grid-type-unit(3);
$notification-bar-overflow-item-padding-v: grid-unit();
$notification-bar-overflow-item-padding: $notification-bar-overflow-item-padding-v
    $notification-bar-overflow-item-padding-h;
$notification-bar-overflow-item-border-width: $border-width-light;
$notification-bar-overflow-item-line-height: $line-height-computed;
$notification-bar-overflow-item-icon-position-top: (
        $notification-bar-overflow-item-padding-v - $notification-bar-overflow-item-border-width
    ) - $btn-padding-vertical;

$notification-bar-backdrop-bg-color: rgba($color-overlay, $color-opacity-low);
$notification-bar-overflow-max-height-md-fallback: calc(
    100vh - #{$notification-bar-height + $header-border-top} - (2 * #{$notification-bar-overflow-item-padding-v})
);
$notification-bar-overflow-max-height-md: calc(
    100dvh - #{$notification-bar-height + $header-border-top} - (2 * #{$notification-bar-overflow-item-padding-v})
);

/*  States
    ========================================================================== */

$notification-bar-danger-text: $color-white;
$notification-bar-danger-bg: $color-danger;
$notification-bar-danger-border: $notification-bar-danger-bg;
$notification-bar-danger-icon-color: $notification-bar-danger-text;
$notification-bar-danger-close-color: $notification-bar-danger-text;
$notification-bar-danger-close-hover-color: $color-red-100;
$notification-bar-danger-close-pressed-color: $color-red-200;

/*  Legacy
    ========================================================================== */

$notification-bar-main-padding-h: $notification-padding-horizontal;
$notification-bar-main-padding: $notification-bar-main-padding-v $notification-bar-main-padding-h;
$notification-bar-main-button-position-right: $notification-bar-main-padding-h -
    ((($component-size-base - $btn-icon-size) * 0.5) - $notification-border-width);
$notification-bar-main-show-more-icon-margin: grid-type-unit();
$notification-bar-main-show-more-padding: 0 grid-unit(2);
$notification-bar-color: $color-white;
$notification-bar-main-show-more-margin: #{-$notification-bar-main-padding-v} 0 #{-$notification-bar-main-padding-v}
    (-$notification-bar-main-show-more-padding);
$notification-bar-main-show-more-line-height: $touch-area;
$notification-bar-main-show-more-text-decoration: none;
$notification-bar-main-close-color: $color-info-text;
$notification-bar-overflow-border-width: $border-width-regular;
$notification-bar-overflow-item-border-color: transparent;
