/*  ==========================================================================
    Checkbox
    ========================================================================== */

.checkbox {
    @include radio-checkbox-custom;

    input {
        + .input-box {
            @extend .fas;
            @extend .fa-check;

            &::before {
                @extend .absolute-center;

                font-size: 0;
            }
        }

        &:checked,
        &:indeterminate {
            + .input-box {
                &::before {
                    transition: font-size $animate-duration-short $animate-duration-short linear;
                }
            }
        }

        &:indeterminate {
            + .input-box {
                @extend .fa-minus;
            }
        }
    }

    /*  Size
    ========================================================================== */

    //TODO: to be refactored to '&-size-md' when all apps use angular form components
    &,
    &--size-md {
        @include radio-checkbox-custom--size-md;

        input {
            &:checked,
            &:indeterminate {
                + .input-box {
                    &::before {
                        font-size: $checkbox-checked-icon-size-size-md;
                    }
                }
            }
        }
    }

    &--size-sm {
        @include radio-checkbox-custom--size-sm;

        input {
            &:checked,
            &:indeterminate {
                + .input-box {
                    &::before {
                        font-size: $checkbox-checked-icon-size-size-sm;
                    }
                }
            }
        }
    }

    /* Theme
    ========================================================================== */

    &,
    &--theme-on-white,
    .has-theme-white & {
        @include radio-checkbox-custom--theme-on-white;

        input {
            + .input-box {
                &::before {
                    color: $checkbox-checked-icon-color;
                }
            }

            &:checked,
            &:indeterminate {
                + .input-box {
                    border-color: transparent;
                    background: $checkbox-checked-bg-color;
                }
            }

            &:checked {
                @include focus-visible {
                    + .input-box {
                        background: $color-focus;
                    }
                }
            }
        }

        &.is-disabled,
        &.disabled {
            input {
                + .input-box {
                    border-color: $form-control-disabled-border-color;
                }

                &:checked + .input-box {
                    background-color: $checkbox-disabled-checked-bg-color;
                }

                &:indeterminate + .input-box {
                    background-color: $checkbox-disabled-checked-bg-color;
                }
            }
        }
    }

    @include theme(light-blue) {
        @include radio-checkbox-custom--theme-on-light-blue;
    }

    @include theme(dark-blue) {
        @include radio-checkbox-custom--theme-on-dark-blue;

        input {
            &:checked,
            &:indeterminate {
                + .input-box {
                    border-color: transparent;
                    background-color: $radio-checkbox--theme-dark-blue_checked-bg-color;

                    &::before {
                        color: $color-selected;
                    }
                }

                @include focus-visible {
                    + .input-box {
                        border-color: $radio-checkbox--theme-dark-blue_checked-bg-color;
                        background-color: $radio-checkbox--theme-dark-blue_checked-bg-color;
                        box-shadow: inset 0 0 0 1px $radio-checkbox--theme-dark-blue_bg-color;
                    }
                }
            }

            @include focus-visible {
                + .input-box {
                    border-color: $radio-checkbox--theme-dark-blue_hover-border-color;
                }
            }
        }

        &.is-disabled,
        &.disabled {
            input {
                &:checked,
                &:indeterminate {
                    + .input-box {
                        border-color: $radio-checkbox--theme-dark-blue_disabled-border-color;
                        background-color: $radio-checkbox--theme-dark-blue_disabled-checked-bg-color;
                    }
                }
            }
        }
    }

    /* Custom theme
    ========================================================================== */

    &--theme-custom {
        input {
            + .input-box {
                border-color: var(--checkbox-color);
            }

            &:checked,
            &:indeterminate {
                + .input-box {
                    border-color: transparent;
                    background-color: var(--checkbox-color);
                }
            }

            &:checked {
                @include focus-visible {
                    + .input-box {
                        border-color: $color-focus;
                        background-color: var(--checkbox-color);
                        box-shadow: focus($color: $color-white);
                    }
                }
            }
        }

        &:not(.is-disabled, .disabled) {
            .form-control-label {
                &:hover {
                    .input-box {
                        border-color: color-mix(in srgb, var(--checkbox-color) 60%, transparent);
                    }
                }
            }
        }

        &.is-disabled,
        &.disabled {
            .form-control-label {
                input {
                    + .input-box {
                        border-color: color-mix(in srgb, var(--checkbox-color) 33%, transparent);
                    }

                    &:checked,
                    &:indeterminate {
                        + .input-box {
                            border-color: transparent;
                            background-color: color-mix(in srgb, var(--checkbox-color) 33%, transparent);
                        }
                    }
                }
            }
        }
    }
}

.form-control-label,
.form-control-help {
    /* first checkbox/radio is not the first html element in a form-group if a control-label is used */
    + .checkbox {
        @include radio-checkbox-custom__form-group--first;
    }
}
