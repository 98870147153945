/*  ==========================================================================
    Datepicker
    ========================================================================== */
//set input container colour to white on a light background
//add cursor to input container
//ensure that readonly inputs (disableKeypress===true) are not greyed out
//ensure date picker looks selectable when disableKeypress is true
.dp-input-container input {
    border: 1px solid $form-control-readonly-border-color;
    background-color: $color-white;
    opacity: 1;
    cursor: pointer;

    &::placeholder {
        text-decoration: none;
    }
}

.datepicker [readonly].dp-picker-input {
    background-color: $color-white;
    opacity: 1;

    &::placeholder {
        color: $color-black-1000;
    }
}
