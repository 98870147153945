/*  ==========================================================================
    Layout base
    ========================================================================== */

html {
    height: 100%;
}

body {
    height: 100%;
    background: $app-bg;

    &.no-scroll {
        @include no-scrollbar;
    }

    @include media-breakpoint-down(sm) {
        &.no-scroll-sm-max {
            @include no-scrollbar {
                #navigation-primary {
                    margin-right: var(--scrollbar-width);
                }
            }
        }
    }
}

#root {
    position: relative;
    top: 0;
    min-width: $root-min-w;
    height: 100%;
    transition: all $layout-animation-vertical-speed-out ease-in;

    .sb-main-centered & {
        min-width: auto;
        height: auto;
    }

    /*  Modifier classes for fixed elements
            > affected styling by conditional root classes
        ========================================================================== */

    /* Visible notification bar */
    &.has-notification-bar {
        top: $notification-bar-height;
        height: calc(100% - #{$notification-bar-height});
        transition: all $layout-animation-vertical-speed-in ease-out;

        .layout-fixed {
            top: $notification-bar-height;
            transition:
                transform $layout-animation-vertical-speed-in ease-out,
                top $layout-animation-vertical-speed-in ease-out,
                box-shadow $layout-animation-vertical-speed-in ease-out;
        }

        .sidebar-global {
            top: $notification-bar-height;
            height: calc(100% - #{$notification-bar-height});
        }

        .sidebar-global-wrapper {
            &.is-expanded {
                .sidebar-global {
                    &.active {
                        transition: all $layout-animation-vertical-speed-in ease-out;
                    }
                }
            }
        }

        #notification-toasters {
            top: $notification-bar-height + $header-h;
            transition:
                top $layout-animation-vertical-speed-in ease-out,
                margin-top $layout-animation-vertical-speed-in ease-out;
        }

        /**
         * Remove sticky position
         *  > degraded experience because of blocking notification
         *    in order to remove complexity of inter-dependant fixed elements
         */

        #content-view-filter,
        #content-sidebar-actions {
            position: relative;
            top: 0 !important;
        }

        @include media-breakpoint-down(sm) {
            #sidebar-local-left {
                height: calc(100vh - #{$header-h + $notification-bar-height});
                height: calc(100dvh - #{$header-h + $notification-bar-height});
                transform: translateY($header-h + $notification-bar-height);
                transition:
                    height $layout-animation-vertical-speed-in ease-out,
                    transform $layout-animation-vertical-speed-in ease-out;
            }
        }

        @include media-breakpoint-down(sm) {
            #sidebar-local-right {
                &.is-fullscreen {
                    height: calc(100vh - #{$notification-bar-height});
                    height: calc(100dvh - #{$notification-bar-height});
                    transition:
                        height $layout-animation-vertical-speed-in ease-out,
                        transform $layout-animation-vertical-speed-in ease-out;
                }
            }
        }

        @include media-breakpoint-between(md, lg) {
            #sidebar-local-right {
                height: calc(100vh - #{$header-h + $notification-bar-height});
                height: calc(100dvh - #{$header-h + $notification-bar-height});
                transform: translateY($header-h + $notification-bar-height);
                transition:
                    height $layout-animation-vertical-speed-in ease-out,
                    transform $layout-animation-vertical-speed-in ease-out;
            }
        }

        @include media-breakpoint-up(md) {
            #sidebar-local-left {
                .sidebar-local {
                    top: $notification-bar-height + $header-h;
                    height: calc(100vh - #{$notification-bar-height + $header-h});
                    height: calc(100dvh - #{$notification-bar-height + $header-h});
                }
            }
        }

        @include media-breakpoint-up(xl) {
            #sidebar-local-right {
                .sidebar-local {
                    top: $notification-bar-height + $header-h;
                    height: calc(100vh - #{$notification-bar-height + $header-h});
                    height: calc(100dvh - #{$notification-bar-height + $header-h});
                }
            }
        }
    }

    /* Mobile fixed bottom primary navigation */
    &.has-primary-nav {
        @include media-breakpoint-down(sm) {
            height: calc(100% - #{$navigation-primary-fixed-height});

            #sidebar-local-left {
                height: calc(100vh - #{$header-h + $navigation-primary-fixed-height});
                height: calc(100dvh - #{$header-h + $navigation-primary-fixed-height});
            }

            &.has-notification-bar {
                height: calc(100vh - #{$navigation-primary-fixed-height + $notification-bar-height});
                height: calc(100dvh - #{$navigation-primary-fixed-height + $notification-bar-height});

                #sidebar-local-left {
                    height: calc(
                        100vh - #{$header-h + $navigation-primary-fixed-height + $notification-bar-height}
                    );
                    height: calc(
                        100dvh - #{$header-h + $navigation-primary-fixed-height + $notification-bar-height}
                    );
                    transform: translateY($header-h + $notification-bar-height);
                }
            }
        }

        @include media-breakpoint-only(sm) {
            #sidebar-local-right {
                height: calc(100vh - #{$header-h + $navigation-primary-fixed-height});
                height: calc(100dvh - #{$header-h + $navigation-primary-fixed-height});
            }

            &.has-notification-bar {
                #sidebar-local-right {
                    height: calc(
                        100vh - #{$header-h + $navigation-primary-fixed-height + $notification-bar-height}
                    );
                    height: calc(
                        100dvh - #{$header-h + $navigation-primary-fixed-height + $notification-bar-height}
                    );
                    transform: translateY($header-h + $notification-bar-height);
                }
            }
        }
    }

    &.header-hidden {
        @include media-breakpoint-down(sm) {
            #notification-toasters {
                top: 0;
                margin-top: 0;

                /* The correct toaster animation when header is transitioning offscreen */
                transition:
                    top $layout-animation-vertical-speed-in ease-out,
                    margin-top $layout-animation-vertical-speed-in ease-out;
            }

            /* TODO: resolve notification bar leaving vs no notification-bar conflict when header is hidden
                    > combination of visible notification bar and toasters is less common than only toasters
                    > possible solution > add notification-bar-hiding class while animating offscreen to overwrite default transition there */

            &.notification-bar-hiding {
                #notification-toasters {
                    /* The correct toaster animation when visible notification-bar is transitioning offscreen */
                    transition:
                        top $layout-animation-vertical-speed-out ease-in,
                        margin-top $layout-animation-vertical-speed-out ease-in;
                }
            }

            &.has-notification-bar {
                #notification-toasters {
                    top: $notification-bar-height;
                    margin-top: grid-unit(2);
                    transition:
                        top $layout-animation-vertical-speed-in ease-out,
                        margin-top $layout-animation-vertical-speed-in ease-out;
                }

                #sidebar-local-left,
                #sidebar-local-right {
                    height: calc(100vh - #{$notification-bar-height});
                    height: calc(100dvh - #{$notification-bar-height});
                    transform: translateY($notification-bar-height);
                }
            }

            #sidebar-local-left {
                height: 100vh;
                height: 100dvh;
                transform: translateY(0);
                transition: transform $layout-animation-vertical-speed-in ease-out;
            }
        }

        @include media-breakpoint-between(sm, md) {
            #sidebar-local-right {
                height: 100vh;
                height: 100dvh;
                transform: translateY(0);
                transition: transform $layout-animation-vertical-speed-in ease-out;
            }
        }
    }

    &.scrolled-top,
    &.scrolled-init {
        #notification-toasters {
            margin-top: grid-unit();
            transition:
                top $layout-animation-vertical-speed-out ease-in,
                margin-top $layout-animation-vertical-speed-out ease-in;
        }

        &.has-notification-bar {
            #notification-toasters {
                transition:
                    top $layout-animation-vertical-speed-in ease-out,
                    margin-top $layout-animation-vertical-speed-in ease-out;
            }
        }
    }
}

#layout-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.layout-fixed-wrapper {
    height: $header-h;
}

.layout-fixed {
    position: fixed;
    z-index: $z-index-navigation-primary;
    top: 0;
    width: 100%;
    height: 0;
    transition:
        transform $layout-animation-vertical-speed-out ease-in,
        top $layout-animation-vertical-speed-out ease-in,
        box-shadow $layout-animation-vertical-speed-out ease-in;

    @include media-breakpoint-up(md) {
        height: inherit;
    }
}
