/*  ==========================================================================
    Legacy colors
    ========================================================================== */

/*  App colors
    ========================================================================== */

$color-app-blue: #00a0ba;
$color-app-red: #e2395d;
$color-app-ocher: #ffaa05;
$color-app-green: #1e988a;
$color-app-turqoise: #31cdd5;
$color-app-lila: #9da9f4;
$color-app-yellow: #e8d308;
$color-app-orange: #f07525;
$color-app-pink: #e94ba4;
$color-app-gray: #7fa0a9;

/*  Chart colors
    ========================================================================== */

$color-chart-amber: #ffc000;
$color-chart-anakiwa: #a0f5ff;
$color-chart-apple: #3d993d;
$color-chart-astral: #397b9e;
$color-chart-butter-rum: #a58911;
$color-chart-chardonnay: #ffcf8a;
$color-chart-cherrywood: #601418;
$color-chart-contessa: #bd6768;
$color-chart-corn: #d2d000;
$color-chart-cornflower: #98d3ea;
$color-chart-deep-sapphire: #0f317a;
$color-chart-feijoa: #9ad690;
$color-chart-fountain-blue: #57b8c2;
$color-chart-golden-grass: #dc9e36;
$color-chart-guardsman-red: #c00000;
$color-chart-indigo: #4472c4;
$color-chart-jade: #00b050;
$color-chart-jaffa: #ed7d31;
$color-chart-lavender: #be5ad4;
$color-chart-lochinvar: #277a78;
$color-chart-mauve: #e6a4ff;
$color-chart-oriental-pink: #cc9697;
$color-chart-paarl: #9e5027;
$color-chart-parsley: #175113;
$color-chart-potica: #f7ec5b;
$color-chart-royal-purple: #7030a0;
$color-chart-seance: #761986;
$color-chart-silver: #bfbfbf;
$color-chart-slate-gray: #708090;
$color-chart-tundora: #4d4d4d;
$color-chart-cornflower-blue: #3cc;
