/*  ==========================================================================
    Tooltip
    ========================================================================== */

@mixin popover-status($border-color, $background-color, $color, $block: &) {
    border-color: $border-color;
    background-color: $background-color;
    color: $color;

    #{$block} {
        &__arrow {
            &::before {
                border-color: $border-color;
                background-color: $background-color;
            }
        }
    }
}
