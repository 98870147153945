/*  ==========================================================================
    Table collapsed
    ========================================================================== */

.table {
    $block: &;

    &--collapsed {
        border-spacing: 0;

        #{$block}__cell {
            border-bottom: $table__border_width solid $table_border-color;

            &--header {
                border-bottom-color: transparent;
            }
        }

        #{$block}__body #{$block}__row:last-child {
            #{$block}__cell {
                border-bottom-color: transparent;
            }
        }

        &#{$block}--has-clickable-rows #{$block}__body #{$block}__row {
            @include focus-visible {
                #{$block}__cell {
                    border-bottom-color: $color-focus;
                }
            }
        }
    }
}
