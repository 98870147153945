/*  ==========================================================================
    Color Category
    ========================================================================== */

$color-category-green-blue-dark: $color-green-blue-900;
$color-category-green-blue-main: $color-green-blue-600;
$color-category-green-blue-accent: $color-green-blue-100;
$color-category-green-blue-light: $color-green-blue-50;

$color-category-blue-dark: $color-blue-900;
$color-category-blue-main: $color-blue-600;
$color-category-blue-accent: $color-blue-100;
$color-category-blue-light: $color-blue-50;

$color-category-orange-dark: $color-orange-900;
$color-category-orange-main: $color-orange-600;
$color-category-orange-accent: $color-orange-100;
$color-category-orange-light: $color-orange-50;

$color-category-purple-dark: $color-purple-900;
$color-category-purple-main: $color-purple-600;
$color-category-purple-accent: $color-purple-100;
$color-category-purple-light: $color-purple-50;

$color-category-green-dark: $color-green-900;
$color-category-green-main: $color-green-600;
$color-category-green-accent: $color-green-100;
$color-category-green-light: $color-green-50;

$color-category-red-dark: $color-red-900;
$color-category-red-main: $color-red-600;
$color-category-red-accent: $color-red-100;
$color-category-red-light: $color-red-50;

$color-category-black-dark: $color-black-900;
$color-category-black-main: $color-black-600;
$color-category-black-accent: $color-black-100;
$color-category-black-light: $color-black-50;

/* Legacy variables */
$color-category-green-blue-text: $color-category-green-blue-dark;
$color-category-blue-text: $color-category-blue-dark;
$color-category-orange-text: $color-category-orange-dark;
$color-category-purple-text: $color-category-purple-dark;
$color-category-green-text: $color-category-green-dark;
$color-category-red-text: $color-category-red-dark;
$color-category-black-text: $color-category-black-dark;

/*  Color Categories mapping
        > Assign sets of colors
    ========================================================================== */

$color-categories: (
    'green-blue': (
        'dark': $color-category-green-blue-dark,
        'main': $color-category-green-blue-main,
        'accent': $color-category-green-blue-accent,
        'light': $color-category-green-blue-light,
    ),
    'blue': (
        'dark': $color-category-blue-dark,
        'main': $color-category-blue-main,
        'accent': $color-category-blue-accent,
        'light': $color-category-blue-light,
    ),
    'orange': (
        'dark': $color-category-orange-dark,
        'main': $color-category-orange-main,
        'accent': $color-category-orange-accent,
        'light': $color-category-orange-light,
    ),
    'purple': (
        'dark': $color-category-purple-dark,
        'main': $color-category-purple-main,
        'accent': $color-category-purple-accent,
        'light': $color-category-purple-light,
    ),
    'green': (
        'dark': $color-category-green-dark,
        'main': $color-category-green-main,
        'accent': $color-category-green-accent,
        'light': $color-category-green-light,
    ),
    'red': (
        'dark': $color-category-red-dark,
        'main': $color-category-red-main,
        'accent': $color-category-red-accent,
        'light': $color-category-red-light,
    ),
    'black': (
        'dark': $color-category-black-dark,
        'main': $color-category-black-main,
        'accent': $color-category-black-accent,
        'light': $color-category-black-light,
    ),
);
