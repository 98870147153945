/*  ==========================================================================
    Button group
    ========================================================================== */

$btn-group-inner-margin-h: grid-unit(1);
$btn-group-inner-margin-v: grid-unit(1);
$btn-group-margin-v: 3 * $btn-group-inner-margin-v;
$btn-group-margin-h: 3 * $btn-group-inner-margin-h;

$btn-group-inner-margin-h-size-lg: grid-unit(3);
$btn-group-inner-margin-v-size-lg: grid-unit(2);
$btn-group-margin-v-size-lg: 2 * $btn-group-inner-margin-v-size-lg;
$btn-group-margin-h-size-lg: 2 * $btn-group-inner-margin-h-size-lg;
