/*  ==========================================================================
    Form
    ========================================================================== */

/*  Form title
    ========================================================================== */
$form-title-margin: grid-unit();

/*  Form section
    ========================================================================== */
$form-section-margin: grid-unit(5);
$form-section-title-margin: grid-unit(2);

/*  Form group
    ========================================================================== */
$form-group-margin: grid-unit(3);

/*  Form Label
    ========================================================================== */
$form-control-label-font-weight: $font-weight-bold;
$form-control-label-padding: grid-type-unit();

/*  Form control help
    ========================================================================== */
$form-control-help-margin-top: -$form-control-label-padding;
$form-control-help-margin-bottom: grid-unit();
$form-control-help-color: $color-text-soft;

/*  Form controls (input/textarea/select)
    ========================================================================== */

/* layout */
$form-control-line-height--size-md: $line-height;
$form-control-font-size--size-sm: $font-size-sm;
$form-control-height--size-sm: $component-size-sm;
$form-control-height--size-md: $component-size-base;

/* border */
$form-control-border-w: 1px;
$form-control-border-color: $color-black-300;

/* padding */
$form-control-padding-v--size-sm: (
        $form-control-height--size-sm -
            (($line-height * $form-control-font-size--size-sm) + $form-control-border-w * 2)
    ) * 0.5;
$form-control-padding-h--size-sm: grid-unit();
$form-control-padding-v--size-md: grid-unit();
$form-control-padding-h--size-md: grid-type-unit(3);

$form-control-padding-top--size-sm: $form-control-padding-v--size-sm;
$form-control-padding-bottom--size-sm: $form-control-padding-v--size-sm;
$form-control-padding-top--size-md: $form-control-padding-v--size-md;
$form-control-padding-bottom--size-md: $form-control-padding-v--size-md;

/* States */
$form-control-bg: $color-white;
$form-control-placeholder-color: $color-text-muted;
$form-control-hover-border-color: $color-black-400;
$form-control-disabled-bg: color-disabled($form-control-bg);
$form-control-disabled-color: color-disabled();
$form-control-disabled-border-color: color-disabled($form-control-border-color);
$form-control-disabled-placeholder-color: transparent;
$form-control-readonly-color: $color-text;
$form-control-readonly-border-color: rgba($form-control-border-color, $color-opacity-medium);
$form-control-readonly-bg-color: rgba($color-black-50, $color-opacity-medium);
$form-control-readonly-placeholder-color: rgba($color-black-400, $color-opacity-medium);

/* On blue */
$form-control-on-blue-bg: $color-white;
$form-control-on-blue-color: $color-text;
$form-control-on-blue-border-color: $color-blue-100;
$form-control-on-blue-placeholder-color: rgba($form-control-on-blue-color, $color-opacity-low);
$form-control-on-blue-hover-bg: $color-white;
$form-control-on-blue-hover-border-color: $color-blue-300;
$form-control-on-blue-disabled-bg: color-disabled($form-control-on-blue-bg);
$form-control-on-blue-disabled-color: color-disabled($form-control-on-blue-color);
$form-control-on-blue-readonly-color: $form-control-on-blue-disabled-color;

/* Theme Dark Blue */
$form-control--theme-dark-blue_bg: $color-blue-700;
$form-control--theme-dark-blue_color: $color-text-on-dark;
$form-control--theme-dark-blue_border-color: $color-blue-700;
$form-control--theme-dark-blue_placeholder-color: rgba(
    $form-control--theme-dark-blue_color,
    $color-opacity-low
);
$form-control--theme-dark-blue_hover-border-color: $color-text-muted-on-dark;
$form-control--theme-dark-blue_disabled-bg: color-disabled($form-control--theme-dark-blue_bg);
$form-control--theme-dark-blue_disabled-color: color-disabled($form-control--theme-dark-blue_color);
$form-control--theme-dark-blue_readonly-color: rgba($color-text-on-dark, $color-opacity-medium);
$form-control--theme-dark-blue_readonly-placeholder-color: $color-text-muted-on-dark;
$form-control--theme-dark-blue_readonly-bg-color: rgba($color-blue-700, $color-opacity-medium);

/*  Textarea
    ========================================================================== */

$form-control-textarea-padding-v-size-sm: grid-type-unit();
$form-control-textarea-padding-v-size-md: grid-unit();

/*  Checkbox & radio
    ========================================================================== */

$radio-checkbox-size-size-sm: grid-type-unit(4);
$radio-checkbox-size-size-md: grid-type-unit(5);
$radio-checkbox-spacing-size-sm: grid-type-unit(1);
$radio-checkbox-spacing-size-md: 6px;
$radio-checkbox-label-spacing-size-sm: grid-type-unit(1);
$radio-checkbox-label-spacing-size-md: grid-unit(1);
$radio-checkbox-inline-spacing-size-sm: grid-unit(5);
$radio-checkbox-inline-spacing-size-md: grid-unit(5);
$radio-checkbox-font-size-size-sm: $icon-font-size-md;
$radio-checkbox-font-size-size-md: $icon-font-size-lg;
$radio-checkbox-hover-border-color: $color-selected;

$radio-checkbox--theme-dark-blue_border-color: $color-black-200;
$radio-checkbox--theme-dark-blue_disabled-border-color: color-disabled($color-black-200);
$radio-checkbox--theme-dark-blue_bg-color: $color-blue-800;
$radio-checkbox--theme-dark-blue_disabled-bg-color: color-disabled($color-blue-800);
$radio-checkbox--theme-dark-blue_hover-border-color: $color-selected-on-dark;
$radio-checkbox--theme-dark-blue_checked-bg-color: $color-white;
$radio-checkbox--theme-dark-blue_disabled-checked-bg-color: color-disabled($color-white);
$radio-checkbox--theme-dark-blue_checked-color: $color-selected;
$radio-checkbox--theme-dark-blue_disabled-checked-color: color-disabled(
    $radio-checkbox--theme-dark-blue_checked-color
);

/* Checkbox */
$checkbox-checked-icon-size-size-sm: grid-unit();
$checkbox-checked-icon-size-size-md: $font-size-xs;
$checkbox-checked-bg-color: $color-selected;
$checkbox-checked-icon-color: $color-white;
$checkbox-disabled-checked-bg-color: color-disabled($checkbox-checked-bg-color);

/* Radio */
$radio-checked-border-width-size-sm: 4px;
$radio-checked-border-width-size-md: 6px;
$radio-checked-border-color: $color-selected;
$radio-disabled-checked-border-color: color-disabled($radio-checked-border-color);

$radio--theme-dark-blue_checked-border-color: $color-selected-on-dark;
$radio--theme-dark-blue_disabled-checked-border-color: color-disabled(
    $radio--theme-dark-blue_checked-border-color
);

/*  Select
    ========================================================================== */

$select-padding-right-size-md: grid-unit(4);
$select-icon-size-size-md: $icon-font-size-xxs;
$select-icon-offset-right-size-md: grid-type-unit(3);

$select-padding-right-size-sm: grid-type-unit(5);
$select-icon-size-size-sm: $icon-font-size-xxs;
$select-icon-offset-right-size-sm: grid-type-unit(2);

/*  Custom controls
    ========================================================================== */

/* search */
$search-control-icon-offset-top-size-sm: 7px;
$search-control-icon-offset-left-size-sm: grid-type-unit(3);
$search-control-icon-font-size-size-sm: $icon-font-size-sm;
$search-control-field-padding-size-sm: grid-type-unit(7);

$search-control-icon-offset-top-size-md: 10px;
$search-control-icon-offset-left-size-md: grid-unit(2);
$search-control-icon-font-size-size-md: $icon-font-size-base;
$search-control-field-padding-size-md: grid-unit(5);

/*  Inline form
    ========================================================================== */

$form-inline-group-margin--size-md: 0 ($form-group-margin * 0.5) 0 0;
$form-inline-control-label-padding-right--size-md: $form-control-label-padding * 0.5;
$form-inline-control-radio-checkbox-label-spacing--size-md: grid-unit();

/*  Form validation
    ========================================================================== */

$validation-form-margin-v: grid-unit();
$validation-form-padding: grid-unit();
$validation-form-border-width: $border-width-light;
$validation-form-icon-margin-h: grid-type-unit();

/*  Form Group validation
    ========================================================================== */

$validation-group-margin-top: grid-unit();
$validation-group-margin-bottom: grid-unit(2);
$validation-group-item-margin-v: grid-unit();
$validation-group-item-icon-margin-h: grid-type-unit();

/*  Form Controls
    ========================================================================== */

$form-controls-spacing: grid-unit();
$form-controls-form-control-inline-spacing: $form-controls-spacing;
$form-controls-options-inline-spacing: $radio-checkbox-inline-spacing-size-md;
$form-controls-form-control-wrapped-spacing: $form-controls-spacing;
$form-controls-options-stacked-spacing: $radio-checkbox-spacing-size-md;

/*  Legacy
    ========================================================================== */
$form-control-width: $breakpoint-xs;
$form-control-height: $form-control-height--size-md;
$form-control-padding-v: $form-control-padding-v--size-md;
$form-control-padding-h: $form-control-padding-h--size-md;
$form-control-padding-top: $form-control-padding-top--size-md;
$form-control-padding-bottom: $form-control-padding-bottom--size-md;
$form-control-textarea-padding-v: $form-control-textarea-padding-v-size-md;
$select-padding-right: $select-padding-right-size-md;
$select-icon-size: $select-icon-size-size-md;
$select-icon-offset-right: grid-type-unit(3);

$radio-checkbox-size: $radio-checkbox-size-size-md;
$radio-checkbox-spacing: $radio-checkbox-spacing-size-md;
$radio-checkbox-label-spacing: $radio-checkbox-label-spacing-size-md;
$radio-checkbox-inline-spacing: $radio-checkbox-inline-spacing-size-md;

/* Checkbox */
$checkbox-checked-icon-size: $checkbox-checked-icon-size-size-md;

/* Radio */
$radio-checked-border-width: $radio-checked-border-width-size-md;

$search-control-icon-offset-top: $search-control-icon-offset-top-size-md;
$search-control-icon-offset-left: $search-control-icon-offset-left-size-md;
$search-control-icon-font-size: $search-control-icon-font-size-size-md;
$search-control-field-padding: $search-control-field-padding-size-md;

$form-inline-group-margin: $form-inline-group-margin--size-md;
$form-inline-control-label-padding-right: $form-inline-control-label-padding-right--size-md;
$form-inline-control-radio-checkbox-label-spacing: $form-inline-control-radio-checkbox-label-spacing--size-md;

/*  Placeholders
    ========================================================================== */

%form-control-size-md {
    height: $form-control-height--size-md;
    padding: $form-control-padding-top--size-md $form-control-padding-h--size-md
        $form-control-padding-bottom--size-md $form-control-padding-h--size-md;
}

%form-control-size-sm {
    height: $form-control-height--size-sm;
    padding: $form-control-padding-top--size-sm $form-control-padding-h--size-sm
        $form-control-padding-bottom--size-sm $form-control-padding-h--size-sm;
    font-size: $form-control-font-size--size-sm;
}

%form-control {
    display: inline-block;
    width: 100%;
    max-width: 100%; //TODO: remove when 320px in .form context has been refactored
    border: $form-control-border-w solid $form-control-border-color;
    border-radius: $border-radius;
    line-height: $form-control-line-height--size-md;
    background-color: $form-control-bg;

    &::placeholder {
        color: $form-control-placeholder-color;
    }

    &:hover {
        border-color: $form-control-hover-border-color;
    }

    &:focus,
    &:active {
        border-color: $color-focus;
    }

    @include focus-visible {
        border-color: $color-focus;
        box-shadow: none;
    }

    &.disabled,
    &[disabled] {
        border-color: $form-control-disabled-border-color;
        background-color: $form-control-disabled-bg;
        color: $form-control-disabled-color;

        &::placeholder {
            color: $form-control-disabled-placeholder-color;
        }
    }

    &.read-only,
    &[readonly] {
        border-color: $form-control-readonly-border-color;
        background-color: $form-control-readonly-bg-color;
        color: $form-control-readonly-color;
        box-shadow: none;

        &::placeholder {
            color: $form-control-readonly-placeholder-color;
        }
    }

    &::-webkit-search-cancel-button {
        width: $icon-font-size-xs;
        height: $icon-font-size-xs;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{encodecolor($color-text-soft)}' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        appearance: none;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{encodecolor($color-text)}' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
        }
    }
}

%form-control-on-blue, //legacy
%form-control--theme-on-light-blue {
    border-color: $form-control-on-blue-border-color;
    background-color: $form-control-on-blue-bg;
    color: $form-control-on-blue-color;

    &::placeholder {
        color: $form-control-on-blue-placeholder-color;
    }

    &:hover {
        border-color: $form-control-on-blue-hover-border-color;
        background-color: $form-control-on-blue-hover-bg;
    }

    &.is-disabled,
    &.disabled,
    &[disabled] {
        background-color: $form-control-on-blue-disabled-bg;
        color: $form-control-on-blue-disabled-color;
    }

    &.read-only,
    &[readonly] {
        color: $form-control-on-blue-readonly-color;
        box-shadow: none;
    }
}

%form-control--theme-on-dark-blue {
    border-color: $form-control--theme-dark-blue_border-color;
    background-color: $form-control--theme-dark-blue_bg;
    color: $form-control--theme-dark-blue_color;
    -webkit-text-fill-color: $form-control--theme-dark-blue_color; // override IOS native form input color

    &::placeholder {
        color: $form-control--theme-dark-blue_placeholder-color;
    }

    &:hover {
        border-color: $form-control--theme-dark-blue_hover-border-color;
    }

    &:focus,
    &:active {
        border-color: $color-focus-on-dark;
    }

    &.is-disabled,
    &.disabled,
    &[disabled] {
        background-color: $form-control--theme-dark-blue_disabled-bg;
        color: $form-control--theme-dark-blue_disabled-color;

        &:hover {
            border-color: $form-control--theme-dark-blue_border-color;
        }
    }

    &.read-only,
    &[readonly] {
        background-color: $form-control--theme-dark-blue_readonly-bg-color;
        color: $form-control--theme-dark-blue_readonly-color;
        box-shadow: none;
    }

    &::-webkit-search-cancel-button {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{encodecolor($color-blue-200)}' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{encodecolor($color-white)}' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
        }
    }

    @include focus-visible {
        border-color: $color-focus-on-dark;
        box-shadow: none;
    }
}

%form-control-select {
    line-height: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{encodecolor($color-text-soft)}' d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    cursor: pointer;
    appearance: none;

    &.hide-toggle-icon {
        text-align: center;
        background-image: none;
    }

    &.is-disabled,
    &.disabled,
    [disabled] {
        color: $color-disabled;
        cursor: default;
    }

    option {
        background-color: $color-white;
        color: $color-text;
    }

    &::-ms-expand {
        display: none;
    }

    &:-moz-focusring {
        text-shadow: 0 0 0 $color-black;
        color: transparent;
    }
}

%form-control-select--theme-on-dark-blue {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{encodecolor($color-text-soft-on-dark)}' d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'/%3E%3C/svg%3E");
}

%form-control-select--size-md {
    &:not(.hide-toggle-icon) {
        padding-right: $select-padding-right-size-md;
        background-position: calc(100% - #{$select-icon-offset-right-size-md});
        background-size: $select-icon-size-size-md;
    }

    &.hide-toggle-icon {
        min-width: $form-control-height--size-md;
        padding-right: grid-unit(1);
        padding-left: grid-unit(1);
    }
}

%form-control-select--size-sm {
    &:not(.hide-toggle-icon) {
        padding-right: $select-padding-right-size-sm;
        background-position: calc(100% - #{$select-icon-offset-right-size-sm});
        background-size: $select-icon-size-size-sm;
    }

    &.hide-toggle-icon {
        min-width: $form-control-height--size-sm;
        padding-right: grid-type-unit(1);
        padding-left: grid-type-unit(1);
    }
}
