/*  ==========================================================================
    Title
    ========================================================================== */

/*  Size
    ========================================================================== */

@mixin title-size(
    $block,
    $size,
    $icon-offset-v,
    $icon-size,
    $icon-font-size,
    $title-font-size,
    $title-font-weight,
    $title-line-height,
    $icon-margin-right: false,
    $title-font: false
) {
    &-#{$size} {
        #{$block}__icon {
            font-size: $icon-font-size;

            &--circle {
                width: $icon-size;
                height: $icon-size;
            }

            &--offset#{$block}__icon--circle {
                margin-top: -$icon-offset-v;
                margin-bottom: -$icon-offset-v;
            }

            @if ($icon-margin-right) {
                margin-right: $icon-margin-right;
            }
        }

        #{$block}__title {
            font-size: $title-font-size;
            font-weight: $title-font-weight;
            line-height: $title-line-height;

            @if ($title-font) {
                font-family: $title-font;
            }
        }

        @content;
    }
}

/*  Status
    ========================================================================== */

@mixin title-status($block, $status, $icon-bg, $icon-color) {
    &-#{$status} {
        #{$block} {
            &__icon {
                color: $icon-color;

                &--circle {
                    background-color: $icon-bg;
                }
            }
        }

        @content;
    }
}

/*  Category
    ========================================================================== */

@mixin title-category($color, $block) {
    &-#{$color} {
        #{$block} {
            &__icon {
                color: get-category-color($color, dark);

                &--circle {
                    background-color: get-category-color($color, light);
                }
            }
        }

        @content;
    }
}
