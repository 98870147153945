/*  ==========================================================================
    Custom form elements
    ========================================================================== */

/*  Radio/checkbox shared styles
    ========================================================================== */

@mixin radio-checkbox-custom {
    position: relative;
    display: flex;

    .form-control-label {
        display: inline-flex;
        align-content: center;
        margin-bottom: 0;
        font-weight: normal;
    }

    input {
        width: 0;

        /* visually hide */
        opacity: 0;
        appearance: none;

        @include focus-visible {
            + .input-box {
                border-color: $color-focus;
                box-shadow: none;
            }

            &:checked {
                + .input-box {
                    border-color: $color-focus;
                }
            }
        }
    }

    .input-box {
        position: relative;
        display: inline-block;
        flex: 0 0 auto;
        border: $form-control-border-w solid $form-control-border-color;
        border-radius: $border-radius;
        background: $form-control-bg;
        cursor: pointer;
        transition: all $animate-duration-short ease;

        &[disabled] {
            color: $form-control-disabled-color;
            cursor: default;
        }
    }

    .option-label {
        margin-left: $radio-checkbox-label-spacing-size-md;
        user-select: none;
    }

    /*  Legacy disabled, new disabled styles is done through the input
        TODO: remove when all checkboxes and radio are componentized.
    ========================================================================== */

    &.is-disabled,
    &.disabled {
        .form-control-label,
        .input-box {
            cursor: default;
        }
    }

    /*  Legacy inline, new inline is down with the priva-form-group
        TODO: remove when all checkboxes and radio are componentized.
    ========================================================================== */

    &.is-inline {
        display: inline-block;

        + .is-inline {
            margin-top: 0;
        }
    }
}

@mixin radio-checkbox-custom--size-md {
    .input-box {
        width: $radio-checkbox-size-size-md;
        height: $radio-checkbox-size-size-md;
        font-size: $radio-checkbox-font-size-size-md;

        :not(priva-checkbox, priva-radio) > & {
            margin-right: $radio-checkbox-label-spacing-size-md;
        }
    }

    .option-label {
        margin-left: $radio-checkbox-label-spacing-size-md;
    }

    .form-control-label {
        padding: $radio-checkbox-spacing-size-md 0;
    }

    /*  Legacy inline, new inline is down with the priva-form-group
        TODO: remove when all checkboxes and radio are componentized.
    ========================================================================== */

    &.is-inline {
        margin-right: $radio-checkbox-inline-spacing-size-md;
    }
}

@mixin radio-checkbox-custom--size-sm {
    font-size: $font-size-sm;
    line-height: 1.2;

    .input-box {
        width: $radio-checkbox-size-size-sm;
        height: $radio-checkbox-size-size-sm;
        font-size: $radio-checkbox-font-size-size-sm;

        :not(priva-checkbox, priva-radio) > & {
            margin-right: $radio-checkbox-label-spacing-size-sm;
        }
    }

    .option-label {
        margin-left: $radio-checkbox-label-spacing-size-sm;
    }

    .form-control-label {
        padding: $radio-checkbox-spacing-size-sm 0;
    }

    /*  Legacy inline, new inline is down with the priva-form-group
        TODO: remove when all checkboxes and radio are componentized.
    ========================================================================== */

    &.is-inline {
        margin-right: $radio-checkbox-inline-spacing-size-sm;
    }
}

@mixin radio-checkbox-custom__form-group--first {
    margin-top: -$radio-checkbox-spacing;
}

@mixin radio-checkbox-custom--theme-on-white {
    &:not(.is-disabled, .disabled) {
        .form-control-label {
            &:hover {
                .input-box {
                    border-color: $radio-checkbox-hover-border-color;
                }
            }
        }
    }

    &.is-disabled,
    &.disabled {
        color: $form-control-disabled-color;

        .input-box {
            border-color: $form-control-disabled-border-color;
            background-color: $form-control-disabled-bg;
        }
    }
}

@mixin radio-checkbox-custom--theme-on-light-blue {
    .input-box {
        border-color: $form-control-on-blue-border-color;
    }
}

@mixin radio-checkbox-custom--theme-on-dark-blue {
    color: $form-control--theme-dark-blue_color;

    .input-box {
        border-color: $radio-checkbox--theme-dark-blue_border-color;
        background-color: $radio-checkbox--theme-dark-blue_bg-color;
    }

    &:not(.is-disabled, .disabled) {
        .form-control-label {
            &:hover {
                .input-box {
                    border-color: $radio-checkbox--theme-dark-blue_hover-border-color;
                }
            }
        }
    }

    &.is-disabled,
    &.disabled {
        color: $form-control--theme-dark-blue_disabled-color;

        .input-box {
            border-color: $radio-checkbox--theme-dark-blue_disabled-border-color;
            background-color: $radio-checkbox--theme-dark-blue_disabled-bg-color;
        }
    }
}
