/*  ==========================================================================
    Description List
    ========================================================================== */

/* Mix, match and concatenate modifier classes, 
context allows the current selector or something else, 
append adds the current context to the selector, 
selector-list accepts a list with modifiers */
@mixin description-list-set-modifiers(
    $modifiers: (),
    $context: &,
    $append: true,
    $selector-list: $description-list-modifiers
) {
    $chain: '';
    $prefix-append: if($append == true, &, null);

    @each $key in $modifiers {
        $chain: #{$chain}#{$context}-#{map-get($selector-list, $key)};
    }

    @at-root {
        #{$prefix-append}#{$chain} {
            @content;
        }
    }
}

/* Set label spacing */
@mixin description-list-set-label-spacing($margin-right, $margin-left, $context: &) {
    @include description-list-set-modifiers((map-get($description-list-modifiers, inline)), $context) {
        #{$context} {
            &-item {
                &-label {
                    margin-right: $margin-right;
                }

                &-value {
                    margin-left: $margin-left;
                }
            }
        }
    }
}

/* Set item distances in all direction and layout combinations */
@mixin description-list-set-item-spacing(
    $vertical-stacked-value,
    $vertical-inline-value,
    $horizontal-stacked-value,
    $horizontal-inline-value,
    $context: &
) {
    /* vertical combinations */
    $item-list-v: (
        vertical-stacked: (
            modifiers: (
                map-get($description-list-modifiers, vertical),
                map-get($description-list-modifiers, stacked),
            ),
            value: $vertical-stacked-value,
        ),
        vertical-inline: (
            modifiers: (
                map-get($description-list-modifiers, vertical),
                map-get($description-list-modifiers, inline),
            ),
            value: $vertical-inline-value,
        ),
    );

    /* horizontal combinations */
    $item-list-h: (
        horizontal-stacked: (
            modifiers: (
                map-get($description-list-modifiers, horizontal),
                map-get($description-list-modifiers, stacked),
            ),
            value: $horizontal-stacked-value,
        ),
        horizontal-inline-value: (
            modifiers: (
                map-get($description-list-modifiers, horizontal),
                map-get($description-list-modifiers, inline),
            ),
            value: $horizontal-inline-value,
        ),
    );

    /* generate modifier classes based on combinations */
    @each $key, $value in $item-list-v {
        @include description-list-set-modifiers(map-get($value, modifiers), $context) {
            #{$context}-item + #{$context}-item, // Legacy selector: remove when html usage is deprecated
            priva-description-list-item + priva-description-list-item {
                @include custom-property(
                    margin-top,
                    --description-list__item_spacing,
                    map-get($value, value)
                );
            }
        }
    }

    @each $key, $value in $item-list-h {
        @include description-list-set-modifiers(map-get($value, modifiers), $context) {
            #{$context}-item + #{$context}-item, // Legacy selector: remove when html usage is deprecated
            priva-description-list-item + priva-description-list-item {
                @include custom-property(
                    margin-left,
                    --description-list__item_spacing,
                    map-get($value, value)
                );
            }
        }
    }
}

/* Set grid spacings in all direction and layout combinations */
@mixin description-list-set-grid-spacing(
    $stacked-row-spacing,
    $stacked-column-spacing,
    $inline-row-spacing,
    $inline-column-spacing,
    $context: &
) {
    // grid combinations
    $grid-list: (
        grid-stacked: (
            modifiers: (
                map-get($description-list-modifiers, grid),
                map-get($description-list-modifiers, stacked),
            ),
            value: (
                vertical-space: $stacked-row-spacing,
                horizontal-space: $stacked-column-spacing,
            ),
        ),
        grid-inline: (
            modifiers: (
                map-get($description-list-modifiers, grid),
                map-get($description-list-modifiers, inline),
            ),
            value: (
                vertical-space: $inline-row-spacing,
                horizontal-space: $inline-column-spacing,
            ),
        ),
    );

    /* generate modifier classes based grid combinations */
    @each $key, $value in $grid-list {
        @include description-list-set-modifiers(map-get($value, modifiers), $context) {
            grid-gap: var(--description-list__item_spacing, map-deep-get($value, value, vertical-space))
                var(--description-list__item_spacing, map-deep-get($value, value, horizontal-space));
        }
    }
}
