/*  ==========================================================================
    Table button
    ========================================================================== */

.table__button {
    text-align: inherit;

    &--sort {
        .btn-icon {
            color: $color-text;
            opacity: 0;
            transform: none;
            transition: all $animate-duration-short ease;

            &--asc {
                opacity: 1;
            }

            &--desc {
                opacity: 1;
                transform: rotate(-180deg);
                transform-origin: center;
            }
        }

        &:hover .btn-icon {
            color: $color-text-muted;
            opacity: 1;
        }

        &:active .btn-icon {
            color: $color-text-soft;
        }

        .table__cell {
            &--sorted-asc & {
                @extend .btn-icon--asc;
            }

            &--sorted-desc & {
                @extend .btn-icon--asc;
            }
        }
    }
}
