/*  ==========================================================================
    Status message
    ========================================================================== */

.status-message {
    $block: &;

    display: flex;
    flex-direction: column;

    /*  Header
        ========================================================================== */

    &-title {
        display: inline-block;
        margin-bottom: grid-unit(2);
    }

    &-image {
        margin-bottom: $status-message-image-margin;

        @include media-breakpoint-down(xs, height) {
            display: none;
        }
    }

    &-icon {
        margin-right: $status-message-icon-offset;
    }

    /*  Main
        ========================================================================== */

    &-main {
        color: $color-text-soft;
    }

    /*  Footer
        ========================================================================== */

    &-footer {
        display: flex;
        flex-direction: column;
    }

    &-cta {
        margin-bottom: $status-message-cta-margin;
    }

    /*  Local
        ========================================================================== */

    &-local {
        align-items: flex-start;

        #{$block} {
            &-main {
                margin-top: $status-message-local-main-margin;
            }

            &-footer {
                align-items: flex-start;
                margin-top: $status-message-local-footer-margin;
            }
        }
    }

    /*  Global
    ========================================================================== */

    &-global {
        align-items: center;
        max-width: $status-message-max-width;
        margin: 0 auto;
        padding: $status-message-global-padding;
        text-align: center;

        .expander-trigger {
            justify-content: center;
        }

        #{$block} {
            &-header {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &-main {
                margin-top: $status-message-global-main-margin;
            }

            &-expander {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            &-footer {
                align-items: center;
                margin-top: $status-message-global-footer-margin;
            }
        }

        @include media-breakpoint-up(xs-mid) {
            #{$block} {
                &-footer {
                    flex-direction: row-reverse;
                }

                &-cta {
                    + #{$block}-cta {
                        margin-right: $status-message-cta-margin;
                    }
                }
            }
        }

        @include media-breakpoint-up(xs-mid, height) {
            margin-top: $status-message-global-margin-xs-mid;
        }

        @include media-breakpoint-up(sm-mid, height) {
            margin-top: $status-message-global-margin-sm-mid;
        }

        @include media-breakpoint-up(md, height) {
            margin-top: $status-message-global-margin-md;
        }
    }

    /*  Status
        ========================================================================== */

    &-info {
        @include status-message-status-style($status-message-color-info);
    }

    &-success {
        @include status-message-status-style($status-message-color-success);
    }

    &-warning {
        @include status-message-status-style($status-message-color-warning);
    }

    &-danger {
        @include status-message-status-style($status-message-color-danger);
    }

    /*  Legacy
        ========================================================================== */

    &-info.expander {
        @extend #{$block}-expander;
    }
}
