/*  ==========================================================================
    Ordered list
    ========================================================================== */

$ordered-list__item_margin-vertical: grid-type-unit(3);

/* counter */
$ordered-list__counter_width: grid-type-unit(5);
$ordered-list__counter_height: $ordered-list__counter_width;
$ordered-list__counter_padding-horizontal: grid-type-unit(1);
$ordered-list__counter_margin: grid-unit(1);
$ordered-list__counter_border-radius: $border-radius-circle;

$ordered-list__counter_font-size: $font-size-xs;
$ordered-list__counter_font-weight: $font-weight-bold;

$ordered-list__counter_color: $color-text-soft;
$ordered-list__counter_bg-color: rgba($color-black, 1 - $color-opacity-max);
