/*  ==========================================================================
    Paths
    ========================================================================== */

/* Default assumption for $asset-path > same structure used in @priva/styles lib folder */
/// Path to assets folder
/// @group configuration
$asset-path: '../' !default;

/* Default assumption for specific asset paths > folder directly under $asset-path */
/// Path to font assets folder
/// @group configuration
$font-path: '#{$asset-path}/fonts' !default;

/// Path to image assets folder
/// @group configuration
$img-path: '#{$asset-path}/images' !default;
