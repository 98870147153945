/*  ==========================================================================
    Form validation legacy
    ========================================================================== */

.validation-form {
    @extend .form-validation;

    .status-icon {
        @extend .form-validation__icon;
    }

    .validation-message {
        @extend .form-validation__message;
    }
}
