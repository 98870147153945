/*  ==========================================================================
    Color classes
    ========================================================================== */

@include chart-color('black', $color-black);
@include chart-color('success', $color-success);
@include chart-color('danger', $color-danger);
@include chart-color('amber', $color-chart-amber);
@include chart-color('anakiwa', $color-chart-anakiwa);
@include chart-color('apple', $color-chart-apple);
@include chart-color('astral', $color-chart-astral);
@include chart-color('butter-rum', $color-chart-butter-rum);
@include chart-color('chardonnay', $color-chart-chardonnay);
@include chart-color('cherrywood', $color-chart-cherrywood);
@include chart-color('contessa', $color-chart-contessa);
@include chart-color('corn', $color-chart-corn);
@include chart-color('cornflower', $color-chart-cornflower);
@include chart-color('deep-sapphire', $color-chart-deep-sapphire);
@include chart-color('feijoa', $color-chart-feijoa);
@include chart-color('fountain-blue', $color-chart-fountain-blue);
@include chart-color('golden-grass', $color-chart-golden-grass);
@include chart-color('guardsman-red', $color-chart-guardsman-red);
@include chart-color('indigo', $color-chart-indigo);
@include chart-color('jade', $color-chart-jade);
@include chart-color('jaffa', $color-chart-jaffa);
@include chart-color('lavender', $color-chart-lavender);
@include chart-color('lochinvar', $color-chart-lochinvar);
@include chart-color('mauve', $color-chart-mauve);
@include chart-color('oriental-pink', $color-chart-oriental-pink);
@include chart-color('paarl', $color-chart-paarl);
@include chart-color('parsley', $color-chart-parsley);
@include chart-color('potica', $color-chart-potica);
@include chart-color('royal-purple', $color-chart-royal-purple);
@include chart-color('seance', $color-chart-seance);
@include chart-color('silver', $color-chart-silver);
@include chart-color('slate-gray', $color-chart-slate-gray);
@include chart-color('tundora', $color-chart-tundora);
@include chart-color('cornflower-blue', $color-chart-cornflower-blue);
