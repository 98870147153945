/*  ==========================================================================
    Priva styles
    ========================================================================== */

/*  Configuration
        > sass tools and helpers used across the project
    ========================================================================== */
@import 'configuration/mixins';
@import 'configuration/variables';
@import 'configuration/overrides';

/*  Core
        > general styling
    ========================================================================== */

/* html */
@import 'core/html/normalize';
@import 'core/html/elements';
@import 'core/html/form';

/* typography */
@import 'core/typography/font';
@import 'core/icons/priva/priva-icons';
@import 'core/icons/fontawesome/fontawesome';
@import 'core/icons/domain/domain-icons';

/* illustration */
@import 'core/illustration/illustration';

/* Helper classes */
@import 'core/helper/animate';
@import 'core/helper/bg-splashscreen';
@import 'core/helper/code';
@import 'core/helper/colors';
@import 'core/helper/custom-properties';
@import 'core/helper/grid';
@import 'core/helper/image';
@import 'core/helper/layout';
@import 'core/helper/media';
@import 'core/helper/overlay';
@import 'core/helper/scrollbar-measure';
@import 'core/helper/text';
@import 'core/helper/trigger';
@import 'core/helper/plain-text';

/* Utility classes > immutable single declaration */
@import 'core/utilities/box-shadow';
@import 'core/utilities/color';
@import 'core/utilities/flex';
@import 'core/utilities/interactive';
@import 'core/utilities/layout';
@import 'core/utilities/presentation';
@import 'core/utilities/text';
@import 'core/utilities/sizes';
@import 'core/utilities/visibility';

/*  Vendor overrides
        > third party plugin specific styling
    ========================================================================== */

@import 'vendor/d3';

/*  Components
        > reusable modular UI components
    ========================================================================== */

@import 'components/action-menu/action-menu.component';
@import 'components/badge/badge.component';
@import 'components/breadcrumbs/breadcrumbs.component';
@import 'components/button/button.component';
@import 'components/button/button-group/button-group.component';
@import 'components/card/card.component';
@import 'components/combobox/combobox.component';
@import 'components/datepicker/datepicker.component';
@import 'components/description-list/description-list.component';
@import 'components/dialog/dialog.component';
@import 'components/dialog/dialog-stepped/dialog-stepped.component';
@import 'components/divider/divider.component';
@import 'components/drop-area/drop-area.component';
@import 'components/drop-area/placeholder.legacy.component';
@import 'components/expander/expander.component';
@import 'components/file/file.component';
@import 'components/form/form.component';
@import 'components/loader/loader.component';
@import 'components/link/link.component';
@import 'components/list/list.component';
@import 'components/muscle-number/muscle-number.component';
@import 'components/notification/notification.component';
@import 'components/ordered-list/ordered-list.component';
@import 'components/overlay/overlay.component';
@import 'components/pagination/pagination.component';
@import 'components/progress/progress-bar/progress-bar.component';
@import 'components/progress/progress-circle/progress-circle.component';
@import 'components/rich-link/rich-link.component';
@import 'components/selection-group/selection-group.component';
@import 'components/slider/slider.component';
@import 'components/spinner/spinner.component';
@import 'components/status-circle/status-circle.component';
@import 'components/status-message/status-message.component';
@import 'components/switch/switch.component';
@import 'components/table/table.component';
@import 'components/table/table-legacy.component';
@import 'components/tab-list/tab-list.component';
@import 'components/tab-list/tabs.legacy.component';
@import 'components/tag/tag.component';
@import 'components/tile/tile.component';
@import 'components/tooltip/tooltip.component';
@import 'components/tooltip/tooltip-container.component';
@import 'components/well/well.component';

/*  Global templates
    ========================================================================== */
@import 'templates/global/error/error';
@import 'templates/global/layout/layout';
@import 'templates/global/login/login.component';
@import 'templates/global/preloader/preloader.component';

/*  Content templates
    ========================================================================== */
@import 'templates/content/grid';
