// Do not edit directly.
// This file is generated using Style Dictionary.

$breakpoint-xs: 320px;
$breakpoint-xs-mid: 460px;
$breakpoint-sm: 600px;
$breakpoint-sm-mid: 750px;
$breakpoint-md: 900px;
$breakpoint-md-mid: 1050px;
$breakpoint-lg: 1200px;
$breakpoint-lg-mid: 1500px;
$breakpoint-xl: 1800px;
$breakpoint-xl-mid: 2100px;
$breakpoint-xxl: 2400px;
