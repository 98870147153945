/*  ==========================================================================
    Target functions/features for specific use cases
    ========================================================================== */

/*  Browsers
    ========================================================================== */

/// Target iOS
/// @description Works for iOS devices with support for @support and at least until iOS 13 (-webkit-touch-callout: none support might be dropped in the future)
/// @group media-queries
/// @output
/// @content
/// @example
///     @include target-ios {
///         display: none;
///     }
@mixin target-ios() {
    @supports (-webkit-touch-callout: none) {
        @content;
    }
}
