/*  ==========================================================================
    Variables
    ========================================================================== */

/*  Base
    > order dependant base system variables
    ========================================================================== */

/* design tokens (generated with style dictionary) */
@import 'variables/tokens/colors';
@import 'variables/tokens/breakpoints';

/* responsive */
@import 'variables/base/breakpoint';
@import 'variables/base/grid';
@import 'variables/base/media';

/* layout */
@import 'variables/base/z-index';
@import 'variables/base/devices';
@import 'variables/base/spacing';

/* utility */
@import 'variables/base/path';

/* theming */
@import 'variables/base/color';
@import 'variables/base/color.legacy';
@import 'variables/base/color.category';
@import 'variables/base/themes';
@import 'variables/base/typography';

/*  Helper
    ========================================================================== */
@import 'variables/helper/layout';

/*  Utilities
    > semantically grouped utility variables
    ========================================================================== */
@import 'variables/utilities/animate';
@import 'variables/utilities/border';
@import 'variables/utilities/code';
@import 'variables/utilities/component';
@import 'variables/utilities/elements';
@import 'variables/utilities/icons';
@import 'variables/utilities/icons-priva';
@import 'variables/utilities/image';
@import 'variables/utilities/layout';
@import 'variables/utilities/panel';
@import 'variables/utilities/properties';
@import 'variables/utilities/spacing';
@import 'variables/utilities/triggers';

/*  Vendor
    ========================================================================== */
@import 'variables/vendor/fontawesome';

/*  Components
    ========================================================================== */
@import 'variables/components/action-menu';
@import 'variables/components/rich-link';
@import 'variables/components/badge';
@import 'variables/components/breadcrumbs';
@import 'variables/components/button';
@import 'variables/components/button-group';
@import 'variables/components/card';
@import 'variables/components/description-list';
@import 'variables/components/datepicker';
@import 'variables/components/draggable-list';
@import 'variables/components/dialog';
@import 'variables/components/dialog-stepped';
@import 'variables/components/divider';
@import 'variables/components/expander';
@import 'variables/components/file';
@import 'variables/components/form';
@import 'variables/components/form.combobox';
@import 'variables/components/form-input-group';
@import 'variables/components/loader';
@import 'variables/components/header';
@import 'variables/components/list';
@import 'variables/components/muscle-number';
@import 'variables/components/notification';
@import 'variables/components/ordered-list';
@import 'variables/components/overlay';
@import 'variables/components/pagination';
@import 'variables/components/drop-area';
@import 'variables/components/popover';
@import 'variables/components/progress';
@import 'variables/components/selection-group';
@import 'variables/components/slider';
@import 'variables/components/spinner';
@import 'variables/components/status-circle';
@import 'variables/components/status-message';
@import 'variables/components/switch';
@import 'variables/components/table';
@import 'variables/components/table-legacy';
@import 'variables/components/tab-list';
@import 'variables/components/tag';
@import 'variables/components/tile';
@import 'variables/components/title';
@import 'variables/components/tooltip';
@import 'variables/components/toaster';
@import 'variables/components/well';
@import 'variables/components/wizard-stepper';

/*  Global templates
    ========================================================================== */
@import 'variables/templates/global/layout/layout';
@import 'variables/templates/global/login/login';
@import 'variables/templates/global/preloader/preloader';

/*  Content templates
    ========================================================================== */
@import 'variables/templates/content/grid';
