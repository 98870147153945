/*  ==========================================================================
    Panel
    ========================================================================== */

$panel_border-width: $border-width-light;
$panel_border-color: $color-border; // TODO: check whether shadow is enough > if yes remove
$panel_border-radius: $border-radius;
$panel_background-color: $color-white;

$panel--on-dark-blue_background-color: $color-blue;
$panel--on-dark-blue_border-color: $color-white;
$panel--on-dark-blue_color: $color-white;
