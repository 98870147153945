/*  ==========================================================================
    Selection Group
    ========================================================================== */

.selection-group {
    $block: &;
    $selection-group__button-next-to-input: + #{$block}__button;

    display: flex;
    flex-wrap: wrap;

    &__item {
        cursor: pointer;

        /*  States
            ========================================================================== */

        &:hover {
            #{$block}__input:not(:disabled) {
                &[type='checkbox'] {
                    #{$selection-group__button-next-to-input} {
                        border-color: $color-selected;
                        color: $color-selected;
                    }

                    &:checked {
                        #{$selection-group__button-next-to-input} {
                            border-color: $color-blue-700;
                            background-color: $color-blue-700;
                            color: $color-white;
                        }
                    }
                }

                &[type='radio']:not(:checked) {
                    #{$selection-group__button-next-to-input} {
                        background-color: $color-blue-100;
                        color: $color-selected;
                    }
                }
            }
        }

        &:active {
            #{$block}__input:not(:disabled) {
                &[type='checkbox'] {
                    #{$selection-group__button-next-to-input} {
                        border-color: $color-selected;
                        background-color: $color-blue-100;
                    }

                    &:checked {
                        #{$selection-group__button-next-to-input} {
                            border-color: $color-selected;
                            background-color: $color-blue-100;
                            color: $color-selected;
                        }
                    }
                }

                &[type='radio']:not(:checked) {
                    #{$selection-group__button-next-to-input} {
                        background-color: $color-blue-100;
                    }
                }
            }
        }
    }

    &__input {
        @extend .sr-only;

        @include focus-visible {
            #{$selection-group__button-next-to-input} {
                border-color: $color-focus;
                box-shadow: none;
            }
        }

        /*  States
            ========================================================================== */

        &[type='checkbox'] {
            #{$selection-group__button-next-to-input} {
                border-color: $form-control-border-color;
                background-color: $color-white;
            }

            &:checked {
                #{$selection-group__button-next-to-input} {
                    background-color: $selection-group__button--is-active_bg-color;
                    color: $selection-group__button--is-active_color;
                }
            }
        }

        &[type='radio'] {
            #{$selection-group__button-next-to-input} {
                background-color: transparent;
            }

            &:checked {
                #{$selection-group__button-next-to-input} {
                    border-color: $selection-group__button--is-active_border-color;
                    background-color: $selection-group__button--is-active_bg-color;
                    color: $selection-group__button--is-active_color;
                }
            }
        }

        &[type='radio'],
        &[type='checkbox'] {
            &:disabled {
                #{$selection-group__button-next-to-input} {
                    border-color: $selection-group__button-disabled_border-color;
                    background-color: $selection-group__button-disabled_bg-color;
                    color: $selection-group__button-disabled_color;
                }
            }
        }

        &:checked {
            @include focus-visible {
                #{$selection-group__button-next-to-input} {
                    border-color: $selection-group__button--is-active_border-color;
                    box-shadow: inset 0 0 0 1px $color-white;
                }
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        min-width: $selection-group__button_min-width;
        min-height: $selection-group__button_min-height;
        padding: $selection-group__button_padding;
        border: $border-width-light solid $selection-group__button_border-color;
        border-radius: $selection-group__button_border-radius;
        line-height: $selection-group__button_line-height;
        color: $selection-group__button_color;
        transition: all $animate-duration-short $animation-easing;
    }

    &__prefix {
        display: inline-flex;
        margin-right: $selection-group__prefix_margin;
    }

    &__label {
        display: inline-block;
        max-width: 100%;
        font-weight: $font-weight-semibold;
    }

    /*  Type
        ========================================================================== */

    &--checkbox {
        gap: $selection-group--checkbox_spacing;

        &#{$block}--size-sm {
            gap: $selection-group--checkbox_spacing;
        }
    }

    &--radio {
        gap: $selection-group--radio-size-md_spacing;
        padding: $selection-group--radio-size-md_spacing;
        border: $selection-group--radio_border-size solid $form-control-border-color;
        border-radius: $selection-group__button_border-radius;
        background-color: $color-white;

        #{$block} {
            &__button {
                min-width: $selection-group--radio__button_min-width;
                min-height: $selection-group--radio__button_min-height;
                padding: $selection-group--radio__button_padding;
            }
        }

        &#{$block}--size-sm {
            gap: $selection-group--radio-size-sm_spacing;
            padding: $selection-group--radio-size-sm_spacing;

            #{$block} {
                &__button {
                    min-width: $selection-group--radio-size-sm__button_min-size;
                    min-height: $selection-group--radio-size-sm__button_min-size;
                    padding: $selection-group--radio-size-sm__button_padding;
                }
            }
        }
    }

    /*  Size
        ========================================================================== */

    &--size-sm {
        #{$block} {
            &__button {
                min-width: $selection-group--sm__button_min-width;
                min-height: $selection-group--sm__button_min-height;
                padding: $selection-group--sm__button_padding;
                font-size: $selection-group--sm__button_font-size;
                line-height: $selection-group--sm__button_line-height;
            }

            &__prefix {
                margin-right: $selection-group--sm__prefix_margin;
            }
        }
    }

    /*  Theme
       ========================================================================== */

    @include theme(light-blue) {
        &#{$block} {
            border-color: $color-blue-100;

            #{$block} {
                &__item {
                    &:hover {
                        #{$block}__input {
                            &[type='radio']:not(:checked) {
                                #{$selection-group__button-next-to-input} {
                                    background-color: $color-blue-100;
                                }
                            }
                        }
                    }

                    &:active {
                        #{$block}__input {
                            &[type='radio']:not(:checked) {
                                #{$selection-group__button-next-to-input} {
                                    background-color: $color-blue-200;
                                }
                            }
                        }
                    }
                }

                &__input {
                    &[type='checkbox'] {
                        #{$selection-group__button-next-to-input} {
                            border-color: $color-blue-100;
                        }
                    }

                    &:disabled {
                        #{$selection-group__button-next-to-input} {
                            border-color: $selection-group--theme-on-light-blue__button-disabled_border-color;
                        }
                    }
                }
            }
        }
    }

    @include theme(dark-blue) {
        &#{$block} {
            border-color: $selection-group--theme-on-dark-blue_border-color;

            #{$block} {
                &__item {
                    &:hover {
                        #{$block}__input {
                            &[type='checkbox'] {
                                #{$selection-group__button-next-to-input} {
                                    border-color: $selection-group--theme-on-dark-blue__button--checkbox-hover_border-color;
                                    background-color: transparent;
                                    color: $color-text-on-dark;
                                }

                                &:checked {
                                    #{$selection-group__button-next-to-input} {
                                        background-color: $selection-group--theme-on-dark-blue__button_bg-color-highlighted;
                                    }
                                }
                            }

                            &[type='radio']:not(:checked) {
                                #{$selection-group__button-next-to-input} {
                                    background-color: $selection-group--theme-on-dark-blue__button_bg-color-highlighted;
                                    color: $color-text-on-dark;
                                }
                            }
                        }
                    }

                    &:active {
                        #{$block}__input {
                            &[type='checkbox'] {
                                #{$selection-group__button-next-to-input} {
                                    background-color: $selection-group--theme-on-dark-blue__button_bg-color-highlighted;
                                    color: $color-text-on-dark;
                                }

                                &:checked {
                                    #{$selection-group__button-next-to-input} {
                                        border-color: $selection-group--theme-on-dark-blue__button--checkbox-checked-pressed_border-color;
                                        background-color: $color-dark-blue;
                                    }
                                }
                            }

                            &[type='radio']:not(:checked) {
                                #{$selection-group__button-next-to-input} {
                                    background-color: $selection-group--theme-on-dark-blue__button--radio-pressed_bg-color;
                                }
                            }
                        }
                    }
                }

                &__input {
                    &[type='checkbox'] {
                        #{$selection-group__button-next-to-input} {
                            border-color: $selection-group--theme-on-dark-blue_border-color;
                            background-color: transparent;
                            color: $color-text-on-dark;
                        }
                    }

                    &[type='radio'] {
                        #{$selection-group__button-next-to-input} {
                            color: $color-text-on-dark;
                        }
                    }

                    &:checked {
                        #{$selection-group__button-next-to-input} {
                            border-color: $color-blue-700;
                            background-color: $color-blue-700;
                        }
                    }

                    &:disabled {
                        #{$selection-group__button-next-to-input} {
                            border-color: $selection-group--theme-on-dark-blue__button-disabled_border-color;
                            background-color: $selection-group--theme-on-dark-blue__button-disabled_bg-color;
                            color: $selection-group--theme-on-dark-blue__button-disabled_color;
                        }
                    }

                    @include focus-visible {
                        #{$selection-group__button-next-to-input} {
                            border-color: $selection-group--theme-on-dark-blue_border-color;
                            box-shadow: inset 0 0 0 1px $color-focus-on-dark;
                        }
                    }
                }
            }

            /*  Type
                ========================================================================== */

            &#{$block}--radio {
                border-color: $selection-group--theme-on-dark-blue_border-color;
                background-color: $color-dark-blue;
            }
        }
    }

    /*  Justified
    ========================================================================== */

    &--justified,
    &--copy {
        flex-wrap: nowrap;

        #{$block} {
            &__item {
                flex: 1 1 100%;
                white-space: nowrap;
            }

            &__button {
                justify-content: center;
            }
        }
    }

    &--copy {
        position: fixed;
        top: -100%;
        right: -100%;
        visibility: hidden;

        * {
            transition: none;
        }
    }

    /*  Apply styles to input element
        ========================================================================== */

    input {
        @extend #{$block}__input;
    }
}
