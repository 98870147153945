/*  ==========================================================================
    Table density
    ========================================================================== */

.table {
    $block: &;

    &--density-xs {
        #{$block}__cell {
            padding: $table--density-xs__cell_padding-vertical $table--density-xs__cell_padding-horizontal;

            &--interactive {
                padding: $table--density-xs__cell--interactive_padding;

                &-sm {
                    padding: $table--density-xs__cell--interactive-sm_padding;
                }
            }
        }

        &#{$block}--editable #{$block}__cell {
            padding-right: $table--editable--density-xs__cell_padding-horizontal;
            padding-left: $table--editable--density-xs__cell_padding-horizontal;

            &--editable {
                @include table__cell--editable__form-control-density(
                    $table--editable--density-xs__cell_padding-horizontal,
                    $table--density-xs__cell_padding-vertical
                );
                @include table__cell--editable__datepicker-density(
                    $table--editable--density-xs__cell_padding-horizontal,
                    $table--density-xs__cell_padding-vertical,
                    $table--density-xs__cell--min-height
                );
                @include table__cell--editable__radio-checkbox-density(
                    $table--editable--density-xs__cell_padding-horizontal
                );

                padding: 0;

                > * {
                    min-height: $table--density-xs__cell--min-height;
                }

                &#{$block}__cell--header {
                    padding-right: 0;
                    padding-left: 0;
                }

                .form-input-group {
                    @include table__cell--editable__form-input-group__form-control-density(
                        $table--editable--density-xs__cell_padding-horizontal,
                        $table--density-xs__cell_padding-vertical,
                        $form-input-group__form-control_padding-density-xs-h
                    );
                }
            }
        }
    }

    &--density-sm {
        #{$block}__cell {
            padding: $table--density-sm__cell_padding-vertical $table--density-sm__cell_padding-horizontal;

            &--interactive {
                padding: $table--density-sm__cell--interactive_padding;

                &-sm {
                    padding: $table--density-sm__cell--interactive-sm_padding;
                }
            }
        }

        &#{$block}--editable #{$block}__cell {
            padding-right: $table--editable--density-sm__cell_padding-horizontal;
            padding-left: $table--editable--density-sm__cell_padding-horizontal;

            &--editable {
                @include table__cell--editable__form-control-density(
                    $table--editable--density-sm__cell_padding-horizontal,
                    $table--density-sm__cell_padding-vertical
                );
                @include table__cell--editable__datepicker-density(
                    $table--editable--density-sm__cell_padding-horizontal,
                    $table--density-sm__cell_padding-vertical,
                    $table--density-sm__cell--min-height
                );
                @include table__cell--editable__radio-checkbox-density(
                    $table--editable--density-sm__cell_padding-horizontal
                );

                padding: 0;

                > * {
                    min-height: $table--density-sm__cell--min-height;
                }

                &#{$block}__cell--header {
                    padding-right: 0;
                    padding-left: 0;
                }

                .form-input-group {
                    @include table__cell--editable__form-input-group__form-control-density(
                        $table--editable--density-sm__cell_padding-horizontal,
                        $table--density-sm__cell_padding-vertical,
                        $form-input-group__form-control_padding-density-sm-h
                    );
                }
            }
        }
    }
}
