/*  ==========================================================================
    List group
    ========================================================================== */

.list {
    $block: &;

    &-item-wrapper {
        & + & {
            border-top: $list-border-width solid $list-border;

            .list-item-draggable {
                margin-top: $list-item-draggable_margin;
            }
        }

        &:first-child {
            .list-item {
                &:not(.list-action, .list-item-draggable) {
                    padding-top: 0;
                }
            }
        }

        &:last-child {
            .list-item {
                &:not(.list-action, .list-item-draggable) {
                    padding-bottom: 0;
                }
            }

            .list-action {
                margin-bottom: 0;
            }
        }
    }

    /*  Base item styling
        ========================================================================== */

    &-item {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 0;
        padding-left: 0;
        text-decoration: none;
    }

    /*  Interactive list items
        ========================================================================== */

    &-action {
        /* Button specific fixes */
        width: 100%;

        /* prepare for focus style */
        margin-bottom: -$list-border-width;
        border-bottom: $list-border-width solid transparent;
        text-align: left;
        cursor: pointer;

        @include focus-visible {
            border-bottom-color: $color-focus;
            box-shadow: none;
        }

        &:hover {
            &,
            #{$block}-item-text {
                color: $list-hover-color;
            }
        }

        &:active {
            &,
            #{$block}-item-text {
                color: $list-pressed-color;
            }
        }

        &.is-active {
            &,
            #{$block}-item-text {
                color: $list-pressed-color;
            }
        }

        &--selected {
            border-bottom-color: $list-border;
            background-color: $list-item--selected-bg;

            &,
            #{$block}-item-text {
                color: $list-pressed-color;
            }
        }

        /* disabled item */
        &.is-disabled,
        &[disabled] {
            &,
            #{$block}-item-text {
                color: $list-disabled-color;
                cursor: default;
            }
        }
    }

    /*  Draggable item
        ========================================================================== */

    &-item-draggable {
        padding-right: $list-item-draggable_padding-h;
        padding-left: $list-item-draggable_padding-h;
        border-radius: $border-radius;
        background-color: $list-item-draggable_bg;
        cursor: grab;
        transition: background-color $animate-duration-medium $animation-easing;

        &:hover {
            @include box-shadow(2);

            background-color: $list-item-draggable-hover_bg;
        }

        &:active,
        &.is-dragging {
            @include box-shadow(3);

            background-color: $list-item-draggable-pressed_bg;
            cursor: grabbing;
        }

        &.is-placeholder {
            opacity: $color-opacity-low;
        }

        /* Animate sorting */
        &.is-draggable {
            transition: transform $animate-duration-medium cubic-bezier(0, 0, 0.2, 1);
        }

        /* Animate dropping to new position */
        &.is-dropping {
            transition: transform $animate-duration-medium cubic-bezier(0, 0, 0.2, 1);
        }

        & + & {
            margin-top: grid-unit(1);
        }
    }

    /*  Start area
        ========================================================================== */

    &-item-area-start {
        margin-right: $list-area-margin;
    }

    &-icon {
        font-size: $list-icon-font-size;
    }

    /*  Main area
        ========================================================================== */

    &-item-area-main {
        flex: 1 1 auto;
        min-width: 0;
        margin-right: auto;
    }

    &-item-title {
        font-weight: $list-item-title-font-weight;
    }

    &-item-text {
        color: $list-item-text-color;
        transition: color $animate-duration;
    }

    /*  End area
        ========================================================================== */

    &-item-area-end {
        display: flex;
        justify-content: center;
        margin-left: $list-area-margin;
    }

    &-item-action-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $list-action-indicator-size;
        height: $list-action-indicator-size;
        font-size: $list-action-indicator-font-size;
    }

    /*  Density/Size list
    ========================================================================== */

    &, // fallback selector support for HTML implementation
    &--size-md {
        .list-item {
            padding-top: $list-size-md-item-padding-v;
            padding-bottom: $list-size-md-item-padding-v;
        }

        .list-item-draggable {
            padding-top: $list-size-md-item-draggable_padding-v;
            padding-bottom: $list-size-md-item-draggable_padding-v;
        }

        &#{$block}--horizontal-padding {
            .list-item {
                padding-right: $list-size-md-horizontal-padding;
                padding-left: $list-size-md-horizontal-padding;
            }
        }
    }

    &-dense, // fallback selector support for HTML implementation
    &--size-sm {
        .list-item {
            padding-top: $list-size-sm-item-padding-v;
            padding-bottom: $list-size-sm-item-padding-v;
        }

        .list-item-draggable {
            padding-top: $list-size-sm-item-draggable_padding-v;
            padding-bottom: $list-size-sm-item-draggable_padding-v;
        }

        &#{$block}--horizontal-padding {
            .list-item {
                padding-right: $list-size-sm-horizontal-padding;
                padding-left: $list-size-sm-horizontal-padding;
            }
        }
    }

    &--size-xs {
        .list-item {
            padding-top: $list-size-xs-item-padding-v;
            padding-bottom: $list-size-xs-item-padding-v;
        }

        .list-item-draggable {
            padding-top: $list-size-xs-item-draggable_padding-v;
            padding-bottom: $list-size-xs-item-draggable_padding-v;
        }

        &#{$block}--horizontal-padding {
            .list-item {
                padding-right: $list-size-xs-horizontal-padding;
                padding-left: $list-size-xs-horizontal-padding;
            }
        }
    }

    /*  Borderless list
    ========================================================================== */

    &-borderless {
        .list-item-wrapper {
            border: none;
        }
    }

    /*  Legacy styles
            > avoid breaking change
            todo: remove and communicate on next breaking change
        ========================================================================== */

    &-item.disabled {
        @extend .is-disabled;
    }

    &-item.active {
        @extend .is-active;
    }

    &-item > .list-icon {
        margin-right: $list-area-margin;
    }

    &-item-content {
        @extend .list-item-area-main;
    }

    &-item-area-right {
        @extend .list-item-area-end;
    }
}
