/*  ==========================================================================
    Responsive visibility

    More easily include all the states for responsive-utilities
    ========================================================================== */

/* Show element with the right display property  */
@mixin responsive-visibility($parent) {
    #{$parent} {
        display: block !important;
    }

    table#{$parent} {
        display: table !important;
    }

    tr#{$parent} {
        display: table-row !important;
    }

    th#{$parent},
    td#{$parent} {
        display: table-cell !important;
    }
}

/* Hide element */
@mixin responsive-invisibility($parent) {
    #{$parent} {
        display: none !important;
    }
}
