/*  ==========================================================================
    Tag
    ========================================================================== */

.tag {
    $block: &;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    border: $tag_border-width solid transparent;
    border-radius: $tag_border-radius;

    /*  States
        ========================================================================== */

    &.is-inactive {
        border-style: dashed;
    }

    /*  Icon
        ========================================================================== */

    &__icon {
        font-size: $icon-font-size-xs;

        + #{$block}__label {
            margin-left: $tag__icon_offset;
        }
    }

    /*  Label
        ========================================================================== */

    &__label {
        flex: 1 1 auto;
        max-width: 100%;
        font-size: $tag__label_font-size;
        font-weight: $tag__label_font-weight;
        line-height: $tag__label_line-height;
    }

    /*  Trigger
        ========================================================================== */
    // TODO: refactor .tag__trigger for duplicate properties when only priva-tag component is used
    &__trigger {
        flex: 0 0 auto;
        border-radius: $border-radius-circle;
        font-size: $icon-font-size-xs;

        @include focus-visible {
            box-shadow: focus(false);
        }
        // TODO: when only priva-tag component is used, .tag__trigger--remove can be removed
        &--remove {
            @extend .far;
            @extend .fa-times;
        }
    }

    /*  Status Circle
    ========================================================================== */

    priva-status-circle {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(-50%, -50%);

        > * {
            display: block;
        }
    }

    /*  Contextual
        ========================================================================== */

    .btn-small {
        min-width: $tag__trigger_size--size-sm;
        min-height: $tag__trigger_size--size-sm;
    }

    /*  Categories
        ========================================================================== */

    &--category-blue {
        @include tag-color-category('blue');
    }

    &--category-green-blue {
        @include tag-color-category('green-blue');
    }

    &--category-orange {
        @include tag-color-category('orange');
    }

    &--category-purple {
        @include tag-color-category('purple');
    }

    &--category-green {
        @include tag-color-category('green');
    }

    &--category-red {
        @include tag-color-category('red');
    }

    &--category-black {
        @include tag-color-category('black');
    }

    /*  Size
        ========================================================================== */

    @include tag-size(sm, true) {
        @include tag-label-size(sm);
        @include tag-trigger-size(sm);
    }

    @include tag-size(xs) {
        @include tag-label-size(xs);
        @include tag-trigger-size(xs);
    }

    /*  Split
        ========================================================================== */

    &--split {
        &-start,
        &-end,
        &-both {
            height: 100%; // ensure split tags are equal height
        }

        &-start,
        &-both {
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }

        &-end,
        &-both {
            border-start-end-radius: 0;
            border-end-end-radius: 0;
        }
    }

    /*  Overflow
        ========================================================================== */

    &--overflow-truncate {
        #{$block}__label {
            @include text-overflow();
        }
    }

    /*  Density
        ========================================================================== */

    &--density {
        &-sm {
            &#{$block}--size-sm,
            & {
                padding: $tag_padding--density-sm-size-sm;

                #{$block} {
                    &__trigger {
                        margin: $tag__trigger_margin--density-sm-size-sm;
                    }
                }
            }

            &#{$block}--size-xs {
                padding: $tag_padding--density-sm-size-xs;

                #{$block} {
                    &__trigger {
                        margin: $tag__trigger_margin--density-sm-size-xs;
                    }
                }
            }
        }
    }

    /*  Icon only styling
        ========================================================================== */

    &--icon-only {
        padding: 0;
    }
}
