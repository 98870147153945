/*  ==========================================================================
    Progress bars

    TODO: do spike (user story 226988) to check whether $color-text-soft can be set to black-600 instead of black-700
        this would allow us to remove the $color-black-600 variable used as a workaround
    ========================================================================== */

.progress-bar {
    $block: &;

    &__values {
        display: flex;
    }

    /* Current progress value text */
    &__value {
        margin-bottom: $progress-bar-text-offset;
        line-height: 1;
        color: $progress-indicator-bg;

        &--current {
            flex: 1 0 auto;
            margin-right: $progress-bar-text-offset;
            font-weight: $progress-bar__value-font-weight;
        }

        &--target {
            flex: 0 1 $progress-bar__value--target_flex-basis;
            margin-left: auto;
            color: $color-black-600;
            transition: flex $animate-duration-short linear;

            &.has-center-alignment {
                transform: translateX(-#{$progress-bar__target-indicator_size-h});
            }
        }

        &--total {
            margin-left: auto;
            color: $color-black-600;
        }
    }

    &__target-indicator {
        position: absolute;
        left: calc(var(--progress-bar__value--target) * 1%);
        width: 0;
        height: 0;
        border-right: $progress-bar__target-indicator_size-h solid transparent;
        border-bottom: $progress-bar__target-indicator_size-v solid $color-green-blue-600;
        border-left: $progress-bar__target-indicator_size-h solid transparent;
        transform: translateX(-50%);
        transition: left $animate-duration-short linear;
    }

    /* Progress rail */
    &__rail {
        position: relative;
        background-color: $progress-rail-bg;
    }

    /* Progress indicator */
    &__indicator {
        width: 0;
        height: 100%;
        background: $progress-indicator-bg;
        transition: width $animate-duration-long ease;

        /* Generate styles for progress indicator steps */
        @for $i from 1 through 100 {
            &[aria-valuenow='#{$i}'] {
                width: percentage($i * 0.01);
            }
        }
    }

    /* Progress description wrapper */
    &__description {
        display: flex;
        justify-content: space-between;
        margin-top: $progress-bar-text-offset;
        line-height: 1;
        color: $progress-bar__description-color;

        &.has-target {
            margin-top: $progress-bar__description--has-target_margin-top;
        }
    }

    /* Current step description */
    &__step-description {
        flex: 0 1 auto;

        + .progress-bar__target-description {
            margin-left: $progress-bar__description-text-spacing;
        }
    }

    /* Description of progress target */
    &__target-description {
        flex: 0 0 auto;
    }

    /*  Sizes
    ========================================================================== */

    &--sm {
        @include progress-bar--size(
            $progress-bar--sm-height,
            $progress-bar--sm-font-size,
            $progress-bar--sm_unit-font-size
        );
    }

    &--md {
        @include progress-bar--size(
            $progress-bar--md-height,
            $progress-bar--md-font-size,
            $progress-bar--md_unit-font-size
        );
    }

    &--lg {
        @include progress-bar--size(
            $progress-bar--lg-height,
            $progress-bar--lg-font-size,
            $progress-bar--lg_unit_font-size
        );
    }
}
