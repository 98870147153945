/*  ==========================================================================
    Panel
    ========================================================================== */

@mixin panel($type: local, $theme: onwhite, $shadow-direction: bottom, $radius: $panel_border-radius) {
    $themes: (
        onwhite: (
            background: $panel_background-color,
            border: $panel_border-color,
        ),
        ondarkblue: (
            background: $panel--on-dark-blue_background-color,
            border: $panel--on-dark-blue_border-color,
        ),
    );

    background: map-deep-get($themes, $theme, background);

    /*  Theme
        ========================================================================== */

    @if ($theme == ondarkblue) {
        color: $panel--on-dark-blue_color;
    }

    /*  Type
        ========================================================================== */

    @if ($type == local) {
        @if ($theme == ondarkblue) {
            @if ($shadow-direction == top or right or bottom or left) {
                @include box-shadow(6, $shadow-direction, null, 1, $color-shadow);
            } @else {
                @error "Allowed values for '$shadow-direction' are 'top', 'right', 'bottom', 'left', you provided #{$shadow-direction}";
            }
        } @else {
            @if ($shadow-direction == top or right or bottom or left) {
                @include box-shadow(6, $shadow-direction);
            } @else {
                @error "Allowed values for '$shadow-direction' are 'top', 'right', 'bottom', 'left', you provided #{$shadow-direction}";
            }
        }

        border: $border-width-light solid map-deep-get($themes, $theme, border);
        border-radius: $radius;
    } @else if($type == global) {
        @if ($shadow-direction == top or right or bottom or left) {
            @include box-shadow(8, $shadow-direction, null, 2, $color-shadow);
        } @else {
            @error "Allowed values for '$shadow-direction' are 'top', 'right', 'bottom', 'left', you provided #{$shadow-direction}";
        }
    } @else {
        @error "Allowed values for '$type' are 'local' or 'global', you provided #{$type}";
    }
}

@mixin panel--size($size, $padding-v, $padding-h: $padding-v) {
    $block: &;

    /* Legacy support: use 'md' size without prefix */
    $sizePrefix: if($size == 'md', null, -#{$size});

    &#{$sizePrefix} {
        #{$block} {
            &-header {
                padding: $padding-v $padding-h;

                + #{$block} {
                    &-body {
                        margin-top: -($padding-v * 0.5);
                    }
                }
            }

            &-body {
                padding: 0 $padding-h ($padding-v * 0.5);

                &:first-child {
                    padding-top: $padding-v;
                }

                &:last-child {
                    padding-bottom: $padding-v;
                }
            }

            &-footer {
                padding: ($padding-v * 0.5) $padding-h;

                &.is-borderless {
                    padding-bottom: $padding-v;
                }
            }

            @content;
        }
    }
}
