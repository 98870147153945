/*  ==========================================================================
    Slider

    Override styling for noUiSlider

    NB make sure your app imports noUiSlider styles before this file (e.g. in angular.json)
    Use `node_modules/nouislider/dist/nouislider.css`

    TODO: refactor slider variations for vertical slider
    TODO: fix slider pips
    ========================================================================== */

.noUi-target {
    .noUi-base {
        display: inline-block;
        width: 100%;
        border-radius: $slider-base-border-radius;
        background: $color-blue-100;
        cursor: pointer;
    }

    .noUi-connect {
        border-radius: $slider-base-border-radius;
        background: $color-selected;
    }

    .noUi-handle {
        width: $slider-handle-size;
        height: $slider-handle-size;
        border: $slider-border-width solid $color-selected;
        border-radius: $border-radius-circle;
        background: $color-white;
        cursor: default;

        &::after,
        &::before {
            display: none;
        }
    }

    .noUi-tooltip {
        position: absolute;
        display: block;
        padding: 2px grid-unit();
        border: $slider-tooltip-border-width solid $color-selected;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        text-align: center;
        white-space: nowrap;
        background: $color-white;
        color: $color-text;
    }

    // DEFAULT HORIZONTAL STYLING
    // ------------------------------------------------------------
    &.noUi-horizontal {
        height: $slider-size;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;

        .noUi-base {
            height: $slider-base-size;
            margin: (($slider-size - $slider-base-size) * 0.5) 0;
        }

        .noUi-handle {
            top: -(($slider-handle-size - $slider-border-width * 2) * 0.5);
            right: -$slider-handle-size * 0.5;

            &:focus,
            &:hover,
            &.noUi-draggable {
                cursor: ew-resize;
            }

            &.noUi-active {
                transform: scale(1.1);
            }
        }
    }

    // DEFAULT VERTICAL STYLING
    // ------------------------------------------------------------
    &.noUi-vertical {
        // TODO: implement when needed
        .noUi-base {
            width: $slider-base-size;
            margin: 0 (($slider-size - $slider-base-size) * 0.5);
        }

        .noUi-handle {
            top: -$slider-handle-size * 0.5;
            right: -(($slider-handle-size - $slider-border-width) * 0.5) + $slider-border-width;

            &.noUi-active {
                transform: scale(1.1);
            }

            &:focus,
            &:hover,
            &.noUi-draggable {
                cursor: ns-resize;
            }
        }
    }

    // TOOLTIP
    // ------------------------------------------------------------
    //&.noUi-with-tooltip {
    &.noUi-horizontal:has(.noUi-tooltip) {
        padding-top: grid-unit(4); // TODO: try to refactor magic value > depends on tooltip layout

        .noUi-tooltip {
            $noUiTooltipOffset: 6px;

            @include pointer('bottom', 3px, $color-white, 2px, $color-selected);

            margin-bottom: $noUiTooltipOffset;
        }
    }
    //}

    // DISABLED STATE
    // ------------------------------------------------------------

    // handle disabled
    &[disabled] .noUi-handle,
    [disabled].noUi-handle {
        border-color: $color-black-300 !important;
        cursor: not-allowed !important;

        &::before,
        &::after {
            background: $color-black-300 !important;
        }
    }

    // slider disabled
    &[disabled] {
        .noUi-connect {
            background: $color-black-300;
        }

        .noUi-horizontal {
            .noUi-base {
                background: $color-black-100;
            }
        }
    }
}
