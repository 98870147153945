/*  ==========================================================================
    Form validation
    ========================================================================== */

.form-validation {
    display: inline-flex;
    align-items: baseline;
    margin: $validation-form-margin-v 0 0 0;
    padding: $validation-form-padding;
    border: $validation-form-border-width solid $color-danger-border;
    border-radius: $border-radius;
    background-color: $color-danger-background;
    color: $color-danger-text;

    &__icon {
        flex: 0 0 auto;
        margin-right: $validation-form-icon-margin-h;
        font-size: $icon-font-size-base;
    }

    &__message {
        flex: 1 1 auto;
    }
}
