.overlay {
    z-index: $z-index-above;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay-bg;

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &:not(.overlay--fixed) {
        position: absolute;
    }

    &--fixed {
        position: fixed;
        z-index: $z-index-overlay;
    }
}
