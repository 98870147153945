/*  ==========================================================================
    Table separate
    ========================================================================== */

.table {
    $block: &;

    &--separate {
        /* Compensate for focus shadow */
        padding-right: 1px;
        padding-left: 1px;
        border-spacing: $table_border-spacing;

        #{$block}__cell {
            &:first-child {
                @include border-left-radius($table__cell_border-radius);
            }

            &:last-child {
                @include border-right-radius($table__cell_border-radius);
            }

            &--header {
                background-color: transparent;

                &#{$block}__cell--highlight {
                    background-color: $color-blue-700;
                }
            }
        }

        #{$block}__body #{$block}__row {
            @include box-shadow(1);
        }

        &#{$block}--has-clickable-rows #{$block}__body #{$block}__row {
            &:hover {
                @include box-shadow(2);
            }

            @include focus-visible {
                @include box-shadow(2, null, true);
            }

            &:active {
                @include box-shadow(1);
            }

            &.is-active {
                @include box-shadow(1, $custom-border: true, $custom-border-color: $color-blue-700);

                &:hover {
                    @include box-shadow(1, $custom-border: true, $custom-border-color: $color-blue-700);
                }
            }
        }
    }
}
