/*  ==========================================================================
    Muscle Number
    ========================================================================== */

/* Category */
@mixin muscle-number-color-category($category) {
    .muscle-number__value {
        color: get-category-color($category, 'dark');
    }
}
