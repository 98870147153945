/*  ==========================================================================
    Icons
    ========================================================================== */

/*  Add quotes
    ========================================================================== */

@function unicode($str) {
    @return unquote('"') + $str + unquote('"');
}

/*  Stacked icons

    Usage:
    .icon-stacked{ @include stacked-icon(name1 name2,color1 color2)}
    ========================================================================== */

@mixin stacked-icon($stacked-iconnames, $colors) {
    .stacked-icon {
        @each $current-icon in $stacked-iconnames {
            $i: index($stacked-iconnames, $current-icon);

            .icon-#{$current-icon} {
                color: nth($colors, $i);
            }
        }
    }
}

/* target all icons */
@mixin icon($selector-type: false) {
    @if ($selector-type == extend) {
        &.icon,
        &.far,
        &.fas {
            @content;
        }
    } @else if($selector-type == extend-and-child) {
        .icon,
        &.icon,
        .far,
        &.far,
        .fas,
        &.fas {
            @content;
        }
    } @else if($selector-type == direct-child) {
        > .icon,
        > .far,
        > .fas {
            @content;
        }
    } @else {
        .icon,
        .far,
        .fas {
            @content;
        }
    }
}
