/*  ==========================================================================
    Table cell
    ========================================================================== */

.table__cell {
    padding: $table__cell_padding-vertical $table__cell_padding-horizontal;
    white-space: nowrap;
    vertical-align: top;
    background-color: $table__background;

    /*  Cell Modifiers
        ========================================================================== */

    /* Header cell */
    &--header {
        padding-top: $table__cell--header_padding-vertical;
        padding-bottom: $table__cell--header_padding-vertical;
        font-weight: $table__cell_font-weight;
        text-align: left;
        white-space: nowrap;

        &.table__cell {
            &--highlight {
                background-color: $color-blue-700;
                color: $color-white;
            }

            &--editable {
                @include table__cell--header-editable__checkbox;

                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    /* Wrap content */
    &--wrap {
        white-space: normal;
    }

    /* Compensate for interactive elements */
    &--interactive {
        width: 1%;
        padding: $table__cell--interactive_padding;

        &-sm {
            width: 1%;
            padding: $table__cell--interactive-sm_padding;
        }
    }

    /* Remove padding */
    &--no-padding {
        padding: 0;
    }

    /* Sortable column header cell */
    &--sortable {
        padding-top: 0;
        padding-bottom: 0;

        .table__button {
            width: 100%;
            padding-top: $table__cell--header_padding-vertical;
            padding-bottom: $table__cell--header_padding-vertical;
        }

        &-asc {
            .table__button {
                .btn-icon {
                    @extend .btn-icon--asc;
                }
            }
        }

        &-desc {
            .table__button {
                .btn-icon {
                    @extend .btn-icon--desc;
                }
            }
        }
    }

    /* Horizontal cell content alignment */
    &--align {
        &-center {
            @include table__cell--alignment(center);

            text-align: center;
        }

        &-end {
            @include table__cell--alignment(end);

            text-align: end;
        }
    }

    /* Vertical cell content alignment */
    &--justify {
        &-center {
            vertical-align: middle;
        }

        &-bottom {
            vertical-align: bottom;
        }
    }

    /* Cell containing a checkbox or radio input to select the row */
    &--select {
        width: $table__cell--select_width;

        + .table__cell {
            padding-left: 0;
        }
    }

    /* Highlight */
    &--highlight {
        background-color: $table__cell--highlight_color;
    }

    /* Editabe styles taking form elements into account */
    &--editable {
        /*  Contextual
                ========================================================================== */
        @include table__cell--editable__form-control;
        @include table__cell--editable__form-input-group;
        @include table__cell--editable__datepicker;
        @include table__cell--editable__select;
        @include table__cell--editable__combobox;
        @include table__cell--editable__radio;
        @include table__cell--editable__checkbox;

        position: relative;
        z-index: $z-index-base;
        padding: $table__cell--interactive_padding 0;
        transition: z-index $animate-duration-long $animation-easing;

        .form-input-group {
            @include table__cell--editable__form-input-group__form-control;
        }
    }
}
