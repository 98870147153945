/*  ==========================================================================
    Table legacy
    ========================================================================== */

.table-legacy {
    display: inline-block;
    padding: $table-legacy-padding;
    border-radius: $border-radius;
    background-color: $table-legacy-bg;

    table {
        max-width: $main-content-wrapper-constrained-max-width;
        border-collapse: separate;
        text-align: left;
    }

    caption {
        @extend %heading-2;

        padding: $table-legacy-caption-padding;
        text-align: left;
        color: $table-legacy-caption-color;
    }

    th,
    td {
        padding: $table-legacy-cell-padding;
        border-bottom: $table-legacy-border-width solid $table-legacy-border-color;
        vertical-align: top;
        background-color: $table-legacy-bg;
    }

    thead {
        th {
            position: sticky;
            top: 0;
            padding: $table-legacy-heading-cell-padding;
            vertical-align: bottom;
        }
    }

    tbody {
        tr {
            &:last-child {
                th,
                td {
                    border-bottom: none;
                }
            }
        }

        + tbody {
            tr:first-child {
                th,
                td {
                    border-top: $table-legacy-border-width solid $table-legacy-border-color;
                }
            }
        }
    }

    /*  Dense
        ========================================================================== */

    &.table-legacy-dense {
        padding: $table-legacy-dense-padding;

        caption {
            padding: $table-legacy-dense-caption-padding;
        }

        th,
        td {
            padding: $table-legacy-dense-cell-padding;
        }

        thead {
            th {
                padding: $table-legacy-dense-heading-cell-padding;
            }
        }
    }

    /*  Dense
        ========================================================================== */

    &.table-legacy-responsive {
        @each $breakpoint in map-keys($breakpoints-utility-classes) {
            $next: breakpoint-next($breakpoint, $breakpoints);
            $infix: breakpoint-infix($next, $breakpoints);

            &#{$infix} {
                @include media-breakpoint-down($breakpoint) {
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                    max-width: 100%;
                }
            }
        }
    }
}
