/*  ==========================================================================
    Divider
    ========================================================================== */

$divider-height: 1px;
$divider-color: $color-divider;
$divider-margin: grid-unit(2);

/* On blue */
$divider-on-blue-color: $color-blue-100;

/* Sizes */
$divider-sm-margin: grid-unit(1);
$divider-md-margin: grid-unit(2);
$divider-lg-margin: grid-unit(3);
$divider-xl-margin: grid-unit(4);
