/*  ==========================================================================
    Universal focus state
    ========================================================================== */

/* Focus shadow */
@function focus($use-inset: true, $color: $color-focus) {
    $inset: inset;

    @if ($use-inset == false) {
        $inset: null;
    }

    @return $inset 0 0 0 1px $color;
}

/* Enhanced focus styles */
@mixin focus {
    outline: none;
    box-shadow: focus();

    &:active,
    &.is-active,
    &[disabled],
    &.is-disabled {
        box-shadow: none;
    }
}

/* Polyfill for focus:visible */
@mixin focus-visible {
    .js-focus-visible & {
        &.focus-visible {
            @content;
        }
    }
}
