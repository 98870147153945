/*  ==========================================================================
    action-menu
    ========================================================================== */

/* Menu */
$action-menu-offset: grid-type-unit();
$action-menu-min-width: grid-unit(20);
$action-menu-max-width: grid-unit(30);
$action-menu-padding: grid-type-unit();

/* Divider */
$action-menu-divider-margin: grid-type-unit() grid-type-unit(1);

/*  Legacy variables
    ========================================================================== */

$action-menu-bg: $color-white;

/* Link */
$action-menu-link-padding: grid-unit() grid-unit(2);
$action-menu-link-hover-bg: $color-hover-background;
$action-menu-link-hover-color: $color-hover-text;
$action-menu-link-pressed-bg: $color-pressed-background;
$action-menu-link-pressed-color: $color-pressed-text;

/* Icon */
$action-menu-link-icon-size: $font-size-base;
$action-menu-link-icon-margin: grid-type-unit(3);
