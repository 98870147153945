/*  ==========================================================================
    Badge
    ========================================================================== */

.badge {
    display: inline-block;
    padding: $badge-padding;
    border: $border-width-light solid transparent;
    border-radius: $border-radius-lg;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: $badge-line-height;

    /*  Categories
    ========================================================================== */

    &,
    &-category-blue {
        @include badge-color-category('blue');
    }

    &-category-green-blue {
        @include badge-color-category('green-blue');
    }

    &-category-orange {
        @include badge-color-category('orange');
    }

    &-category-purple {
        @include badge-color-category('purple');
    }

    &-category-green {
        @include badge-color-category('green');
    }

    &-category-red {
        @include badge-color-category('red');
    }

    &-category-black {
        @include badge-color-category('black');
    }

    /*  Legacy code: Status
        TODO: wrap in deprecation mixin
    ========================================================================== */

    &-soft {
        @include badge-color-category('black');
    }

    &-info {
        @include badge-color-category('blue');
    }

    &-success {
        @include badge-color-category('green');
    }

    &-warning {
        @include badge-color-category('orange');
    }

    &-danger {
        @include badge-color-category('red');
    }
}
