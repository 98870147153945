/*  ==========================================================================
    Sidebar local
    ========================================================================== */

$sidebar-local-w-min: grid-unit(20);
$sidebar-local-w: $breakpoint-xs - $touch-area;
$sidebar-local-w-shadow-offset: 0;
$sidebar-local-offset-top: $header-h;
$sidebar-local-bg: $color-white;
$sidebar-local-spacing: grid-type-unit(3);

/* Header */
$sidebar-local-header-padding: $sidebar-local-spacing;
$sidebar-local-overlay-header-padding: $sidebar-local-spacing $sidebar-local-spacing 0 $sidebar-local-spacing;

/* Main */
$sidebar-local-main-margin: 0;
$sidebar-local-overlay-main-margin: 0;
$sidebar-local-overlay-main-padding: $sidebar-local-spacing;

/* Footer */
$sidebar-local-footer-padding: $sidebar-local-spacing;
$sidebar-local-overlay-footer-padding: 0 $sidebar-local-spacing $sidebar-local-spacing $sidebar-local-spacing;
