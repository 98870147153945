/*  ==========================================================================
    Spacing
    ========================================================================== */

/* Generate map with multiples of $grid-unit */
$grid-units: ();

/* more than 6 units is considered an edge case and should be implemented by developer in project scss */
$grid-units-amount: 6;

@for $i from 0 through $grid-units-amount {
    $grid-units: map-merge(
        $grid-units,
        (
            $i: grid-unit($i),
        )
    );
}

/* Generate map of layout types */
$layout-types: (
    auto: auto,
    initial: initial,
    inherit: inherit,
    unset: unset,
    perc-50: 50%,
    perc-100: 100%,
);

/* Sections */
%cluster {
    + %cluster {
        margin-top: grid-unit(4);
    }
}
