/*  ==========================================================================
    Table item
    ========================================================================== */

.table__item {
    &--nowrap {
        white-space: nowrap;
    }

    &--truncate {
        @include text-overflow();
    }
}
