/*  ==========================================================================
    Link
    ========================================================================== */

.link {
    @extend %text-link;

    /*  Themes
    ========================================================================== */

    @include theme(dark-blue) {
        @include property-states(
            'color',
            $color-text-on-dark,
            $color-hover-text-on-dark-blue,
            $color-pressed-text-on-dark-blue,
            '.link__span'
        );
    }
}
