/*  ==========================================================================
    Expander
    ========================================================================== */

.expander {
    $block: &;

    &-trigger {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-right: 0;
        padding-left: 0;

        &-icon {
            margin-right: $expander-trigger-icon-margin;
            transform: none;
            transform-origin: center;
            transition: transform $animate-duration-short linear;
        }

        &-content {
            flex: 1 1 auto;
            text-align: left;
        }

        &-soft {
            #{$block}-trigger {
                &-icon,
                &-label {
                    color: $color-text-soft;
                }
            }
        }
    }

    &-content {
        display: none;
        margin-top: $expander-content-margin;
    }

    &.is-expanded {
        #{$block} {
            &-content {
                display: block;
            }

            &-trigger-icon {
                transform: rotate(90deg);
            }
        }
    }

    /*  Theme
        ========================================================================== */

    @include theme(dark-blue, false) {
        color: $color-text-on-dark;

        #{$block} {
            &-trigger-soft {
                .btn-icon,
                #{$block}-trigger-label {
                    color: $color-text-soft-on-dark;
                }
            }
        }
    }
}
