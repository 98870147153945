/*  ==========================================================================
    Breakpoint
    ========================================================================== */

/*  The maximum value is calculated as the minimum of the next one less 0.02px
    to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
    See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
    Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari. */
$breakpoint-max-modifier: 0.02px;

// TODO: refactor breakpoint implementations (eg. media queries) to get rid of useless XXS breakpoint
/// All breakpoints
/// @group breakpoints
/// @prop {breakpoint} xs [$breakpoint-xs] - xs breakpoint
/// @prop {breakpoint} xs-mid [$breakpoint-xs-mid] - xs-mid breakpoint
/// @type Map
$breakpoints: (
    xxs: 0,
    xs: $breakpoint-xs,
    xs-mid: $breakpoint-xs-mid,
    sm: $breakpoint-sm,
    sm-mid: $breakpoint-sm-mid,
    md: $breakpoint-md,
    md-mid: $breakpoint-md-mid,
    lg: $breakpoint-lg,
    lg-mid: $breakpoint-lg-mid,
    xl: $breakpoint-xl,
    xl-mid: $breakpoint-xl-mid,
    xxl: $breakpoint-xxl,
);

/// Main breakpoints
/// @group breakpoints
/// @require $breakpoints
$breakpoints-main: map_remove($breakpoints, xs, xs-mid, sm-mid, md-mid, lg-mid, xl-mid);

/// Breakpoints used to generate utility classes
/// @group configuration
/// @require $breakpoints-main
$breakpoints-utility-classes: $breakpoints-main !default;

/* evaluate breakpoints map */
@include _assert-ascending($breakpoints, '$breakpoints');
@include _assert-starts-at-zero($breakpoints);
