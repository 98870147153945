/*  ==========================================================================
    Tabs legacy
    ========================================================================== */

.tabs {
    $block: &;

    @extend .tab-list;

    /*  Themes
        ========================================================================== */

    @include theme(light-blue) {
        .tab-link {
            @include property-states(
                'border-color',
                $tab-list--on-light-blue__link_border-color,
                $tab-list--on-light-blue__link-hover_border-color,
                $tab-list--on-light-blue__link-pressed_border-color,
                color-disabled($tab-list--on-light-blue__link_border-color),
                $tab-list--on-light-blue__link-selected_border-color
            );
        }
    }

    @include theme(dark-blue, false) {
        .tab-link {
            @include property-states(
                'color',
                $tab-list--on-dark-blue__link_color,
                $tab-list--on-dark-blue__link-hover_color,
                $tab-list--on-dark-blue__link-pressed_color,
                color-disabled($tab-list--on-dark-blue__link_color),
                $tab-list--on-dark-blue__link-selected_color
            );
            @include property-states(
                'border-color',
                $tab-list--on-dark-blue__link_border-color,
                $tab-list--on-dark-blue__link-hover_border-color,
                $tab-list--on-dark-blue__link-pressed_border-color,
                $tab-list--on-dark-blue__link_border-color,
                $tab-list--on-dark-blue__link-selected_border-color
            );
        }
    }
}

.tab {
    @extend .tab-list__item;

    &-link {
        @extend .tab-list__link;
    }

    /*  Justified
    ========================================================================== */

    &-justified {
        @extend .tab-list--justified;

        .tab {
            @extend .tab-list--justified;
        }
    }

    /*  Tab content

    Hide tab content panes to start, show them when `.selected`
    ========================================================================== */

    &-content {
        @extend .tab-list__content;
    }
}
