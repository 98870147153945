/*  ==========================================================================
    Clearfix

    Source: http://cssmojo.com/the-very-latest-clearfix-reloaded/
    ========================================================================== */

@mixin clearfix {
    &::after {
        content: ' ';
        display: block;
        clear: both;
    }
}
