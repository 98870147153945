/*  ==========================================================================
    Text helper classes
    ========================================================================== */

/* Alignment
   ========================================================================== */

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-overflow {
    @include text-overflow(true);
}

/* Transformation
   ========================================================================== */

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-transform-none {
    text-transform: none !important;
}

/* Colors
   ========================================================================== */

.text-color {
    color: $color-text !important;
}

.text-color-soft {
    color: $color-text-soft !important;
}

.text-color-muted {
    color: $color-text-muted !important;
}

/* Sizes
   ========================================================================== */

.font-size-xs {
    font-size: $font-size-xs !important;
}

.font-size-sm {
    font-size: $font-size-sm !important;
}

.font-size-md {
    font-size: $font-size-base !important;
}

.font-size-lg {
    font-size: $font-size-lg !important;
}

.font-size-xl {
    font-size: $font-size-xl !important;
}

.font-size-2xl {
    font-size: $font-size-2xl !important;
}

.font-size-3xl {
    font-size: $font-size-3xl !important;
}

.font-size-4xl {
    font-size: $font-size-4xl !important;
}

.icon-size-xs {
    font-size: $icon-font-size-xs !important;
}

.icon-size-sm {
    font-size: $icon-font-size-sm !important;
}

.icon-size-md {
    font-size: $icon-font-size-base !important;
}

.icon-size-lg {
    font-size: $icon-font-size-lg !important;
}

.icon-size-xl {
    font-size: $icon-font-size-xl !important;
}

.icon-size-2xl {
    font-size: $icon-font-size-2xl !important;
}

.icon-size-3xl {
    font-size: $icon-font-size-3xl !important;
}

.icon-size-4xl {
    font-size: $icon-font-size-4xl !important;
}

/* Line height
   ========================================================================== */

.line-height-xs {
    line-height: $line-height-xs-computed !important;
}

.line-height-sm {
    line-height: $line-height-sm-computed !important;
}

.line-height-md {
    line-height: $line-height-base-computed !important;
}

.line-height-md-extra {
    line-height: $line-height-md-extra-computed !important;
}

.line-height-lg {
    line-height: $line-height-lg-computed !important;
}

.line-height-lg-extra {
    line-height: $line-height-lg-extra-computed !important;
}

.line-height-xl {
    line-height: $line-height-xl-computed !important;
}

.line-height-xl-extra {
    line-height: $line-height-xl-extra-computed !important;
}

.line-height-2xl {
    line-height: $line-height-2xl-computed !important;
}

.line-height-2xl-extra {
    line-height: $line-height-2xl-extra-computed !important;
}

.line-height-3xl {
    line-height: $line-height-3xl-computed !important;
}

.line-height-3xl-extra {
    line-height: $line-height-3xl-extra-computed !important;
}

.line-height-4xl {
    line-height: $line-height-4xl-computed !important;
}

.line-height-4xl-extra {
    line-height: $line-height-4xl-extra-computed !important;
}

/* Size and line height combinations
   ========================================================================== */

.text-xs {
    font-size: $font-size-xs !important;
    line-height: $line-height-xs !important;
}

.text-sm {
    font-size: $font-size-sm !important;
    line-height: $line-height-sm !important;
}

.text-md {
    font-size: $font-size-base !important;
    line-height: $line-height-base !important;
}

.text-md-extra {
    font-size: $font-size-base !important;
    line-height: $line-height-md-extra !important;
}

.text-lg {
    font-size: $font-size-lg !important;
    line-height: $line-height-lg !important;
}

.text-lg-extra {
    font-size: $font-size-lg !important;
    line-height: $line-height-lg-extra !important;
}

.text-xl {
    font-size: $font-size-xl !important;
    line-height: $line-height-xl !important;
}

.text-xl-extra {
    font-size: $font-size-xl !important;
    line-height: $line-height-xl-extra !important;
}

.text-2xl {
    font-size: $font-size-2xl !important;
    line-height: $line-height-2xl !important;
}

.text-2xl-extra {
    font-size: $font-size-2xl !important;
    line-height: $line-height-2xl-extra !important;
}

/* Weight
   ========================================================================== */

.text-normal {
    font-weight: $font-weight-regular !important;
}

.text-semibold {
    font-weight: $font-weight-semibold !important;
}

.text-bold {
    font-weight: $font-weight-bold !important;
}

/* Style
   ========================================================================== */

.text-italic {
    font-style: italic !important;
}
